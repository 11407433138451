import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class HomeExtraContent extends Component {
    state = {};
    render() {
        return (
            <div>
                <div className="section-title">
                    <h1 className="section-head">
                        {t("homes_for_your_kind_of_trip")}   
                    </h1>
                    <h4 className="captalize section-subhead">
                        {t("homes_for_your_kind_of_trip_text")}
                    </h4>
                </div>

                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <Link to="#">
                            <div className="trip-img-sec">
                                <img
                                    srcSet="../../../assets/img/family.jpg,
                                                    ../../../assets/img/family.jpg 1.5x,
                                                    ../../../assets/img/family.jpg 2x"
                                    src="../../../assets/img/family.jpg"
                                    alt="Family"
                                    className="trip-img"
                                />
                            </div>
                            <div className="homes-text-sec">
                                <p className="red-text txt-overflow">
                                    2000+ {t("homes")}
                                </p>
                                <h4 className="homes-price1 captalize text-top">
                                    {t("find_link_home_that_families_love")}
                                </h4>
                            </div>
                        </Link>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <Link to="#">
                            <div className="trip-img-sec">
                                <img
                                    srcSet="../../../assets/img/work.jpg,
                                                    ../../../assets/img/work.jpg 1.5x,
                                                    ../../../assets/img/work.jpg 2x"
                                    src="../../../assets/img/work.jpg"
                                    alt="Plus Work"
                                    className="trip-img"
                                />
                            </div>
                            <div className="homes-text-sec">
                                <p className="red-text txt-overflow">
                                    2000+ {t("homes")}
                                </p>
                                <h4 className="homes-price1 captalize text-top">
                                    {t("book_link_top_rated_home")}
                                </h4>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate(HomeExtraContent);
