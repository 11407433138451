import React, { Component } from "react";

import FixedHeader from "./Headers/FixedHeaderOnlyLogo";
import HomeHeader from "./Headers/HomeHeader";

// This Layouts used in Home page Header,  static footer and content

class NinethLayout extends Component {
    render() {
        return (
            <div className="wrapper">
                <FixedHeader {...this.props.children.props} />
                {/* <HomeHeader {...this.props.children.props} /> */}
                <div>{React.cloneElement(this.props.children)}</div>
            </div>
        );
    }
}
export default NinethLayout;
