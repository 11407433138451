import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class BecomeHost extends Component {
    state = {};
    render() {
        return (
                <div className="custom_container">
            <div className="pt-5 pb-5">
                {/* <div className="green green_become_host_new_sec"  style={{
                                backgroundRepeat: 'no-repeat',
                                // borderRadius :'20px',
                                backgroundColor:'#1DD05D',
                            }}> */}
                     {/* <div className=" green_become_host_new_sec"  style={{
                                backgroundRepeat: 'no-repeat',

                                backgroundColor:'#1DD05D',
                            }}>   */}
                            
                               <div className=" green_become_host_new_sec"  style={{
                              
                            }}>           
                                <div className="become_landing_section">
                    <h1 className="text-white fw_bolder  pt-3 become_an_host_para ">Get started Today!</h1>
                    <div className="_become_host_para">
                        <p>Find out how much you could earn sharing your unused <br /> 
                             spaceTurn your extra space into extra income.</p>
                    </div>
                    <div className="">
                        <Link to={"/host"}>

                       <button className=" become_host_new_btn  px-3 py-2 mt-3 rounded border-0 become_an_host_button">{t("Become_an_Host")}</button> 
                        </Link>
                    </div>
                    </div>
                </div>

            </div>
            </div>
        );
    }
}

export default translate(BecomeHost);
