import React, { Component } from "react";

import { Link } from "react-router-dom";

import HostHelper from "../../Helper/hostHelper";

import configuration from "react-global-configuration";

import { translate, t } from "react-multi-lang";

class HostAuthHeader extends HostHelper {
  constructor(props) {
    super(props);
  }

  state = {
    categories: null,
    data: {},
    loadingCategories: true,
  };

  componentDidMount() {
    // Call api function
    // this.getCategories();
  }

  render() {
    const { loadingCategories } = this.state;
    let renderDetails;

    renderDetails = (
      <React.Fragment>
        <li className="nav-item ">
          <Link className="nav-link nav_links" to={"/host"}>
            Become a Host
          </Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link nav_links new_login_nav_btn" to={"/host/login"}>
            {t("login")}
          </Link>
        </li>

        {/* <li className="nav-item "> */}
        <li className="nav-item get_strt_b_btn">
          <Link className="nav-link nav_links" to={"/host/register"}>
          Get Started Free
          </Link>
        </li>
        
      </React.Fragment>
    );

    return (
      <div className="landing_nav">
        {/* <nav
          className="navbar navbar-expand-xl site-content  navbar-light ff"
          id="navbar"
        > */}
        <nav
          className="navbar navbar-expand-md landing_nav_sett navbar-light "
          id="navbar"
        >
          <Link className="navbar-brand" to={"/"}>
            <img
              src={window.location.origin + "/assets/img/logo.png"}

              alt={configuration.get("configData.site_name")}
            />
          </Link>
          {/* <form className="form-inline justify-content-start">
            <div className="input-group dropdown">
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon">
                  <i className="fas fa-search" />
                </span>
              </div>
              <input
                type="text"
                className="form-control form-control-lg dropdown-toggle"
                data-toggle="dropdown"
                placeholder={t("try_london")}
              />

              <div className="dropdown-menu nav-dropdown-menu">
                <h5 className="dropdown-title">{t("explore_site")}</h5>
                <Link to="#" className="grey-outline-btn">
                  {t("all")}
                </Link>
                {loadingCategories
                  ? ""
                  : this.renderCategory("grey-outline-btn")}
              </div>
            </div>
          </form> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <i className="fas fa-chevron-down" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="collapsibleNavbar"
          >
            {/* <ul className="navbar-nav">{renderDetails}</ul> */}
            <ul className="navbar-nav navbar_ul">{renderDetails}</ul>
          </div>
        </nav>
        

        <div className="header-height" />
      </div>
    );
  }
}

export default translate(HostAuthHeader);
