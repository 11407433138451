import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import axios from "axios";


class HowItWorkn extends Component {
  constructor() {
    super();
    this.state = {
      loginState: 0
    };
  }

  render() {
  
    let sessonState;
    let imgurl;
    let how1='button how_card how_c_btn';
    let how2;
    let how3;
    if (this.state.loginState == 0) {
      sessonState = "0";
      // imgurl = "https://picsum.photos/id/237/200/300";
       imgurl = "../assets/img/how.png";
       how1='button how_card how_c_btn';
       how2='button how_card ';
       how3='button how_card ';
    } 

    else if(this.state.loginState == 1) {
      sessonState = "01";
      // imgurl = "https://picsum.photos/id/237/200/300";
       imgurl = "../assets/img/right_2.png";
       how2='button how_card how_c_btn';
       how1='button how_card ';
       how3='button how_card ';
    } 
    else{
      sessonState = "02";
      // imgurl = "https://picsum.photos/id/237/200/300";
       imgurl = "../assets/img/right_3.png";
       how3='button how_card how_c_btn';
       how2='button how_card ';
       how1='button how_card ';
    } 
    

    return (
      <div>
       <div className="section-title">
        {/* <h1 className="Heading_section  how_work_heading text_success .find_right_spc">{t("how_it_work")}</h1> */}
        {/* <h1 className="how_work_heading find_right_spc">{t("how_it_work")}</h1> */}
        </div>
        <div className="container">
       
          <div className="row how_card_row">
            <div className="col-md-6">
              <div className="how_work_heading how_work_headings">
            <h1 className=" find_right_spc">{t("how_it_work")}</h1>
            <p>SpacEazy simplifies the process of finding storage space, whether you need a large warehouse 
            or something as simple as a small storage room to hold your belongings. Follow these simple 
            steps to get started:</p>
            </div>
              <div className="">
               
                <div className="CardBody">
                  <div className="how_btn1">
            
                  <button 
                    className={how1}
                    onClick={() => {
                      this.setState({
                        loginState: 0
                      });
                      console.log(`${imgurl}`);
                    }}
                  >
                 <div className="how_card_cont">
                <div className="how_img_cont">
                <img src="../../../assets/img/dollar.png" className="img-fluid" />
                </div>
              
                  <div className="how_content">
                     <h5 className=""> Start Your Search</h5>
                    <p className="">Easily search for storage space in and around your area. Check out all the different options 
                      available in various sizes and locations, offering the space you need to store your belongings.</p>
                        </div>
                  </div>
                  </button>
                 
                  </div>




                  <div className="how_btn_2">
                   <button 
                    className={how2}
                    onClick={() => {
                      this.setState({
                        loginState: 1
                      });
                      console.log(`${imgurl}`);
                    }}
                  >
                  <div className="how_card_cont">
                <div className="how_img_cont">
                <img src="../../../assets/img/how_img2.png" className="img-fluid" />
                </div>
              
                  <div className="how_content">
                     <h5 className="">Book Your Space</h5>
                    <p className="">Found the ideal space to store different items? Great! Now all you need to do is confirm your 
                booking to get started.
                </p>
                        </div>
                  </div>
                  </button>
                  </div>




                  <div className="how_btn_2">
                  <button 
                    className={how3}
                    onClick={() => {
                      this.setState({
                        loginState: 2
                      });
                      console.log(`${imgurl}`);
                    }}
                  >
                  <div className="how_card_cont">
                <div className="how_img_cont">
                <img src="../../../assets/img/h2.png" className="img-fluid" />
                </div>
              
                  <div className="how_content">
                     <h5 className="">Start Using the Rented Space</h5>
                    <p className="">Once you've confirmed your booking, you can begin using the storage space you’ve selected. 
                            Yes, it's really that simple!</p>
                        </div>
                  </div>

                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img_container">
              <img top width="" src={imgurl} alt="Card image cap" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(HowItWorkn);
