import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../../Environment";
import { Redirect } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class TripHistory extends Component {
  state = {
    histories: null,
    upcoming: null,
    loadingHistory: true,
    loadingUpcoming: true
  };

  componentDidMount() {
    // API call.

    api.postMethod("bookings_history").then(response => {
      if (response.data.success === true) {
        this.setState({ histories: response.data.data, loadingHistory: false });
      }
    });

    api.postMethod("bookings_upcoming").then(response => {
      if (response.data.success === true) {
        this.setState({ upcoming: response.data.data, loadingUpcoming: false });
      }
    });
  }

  handleClick = (event, data) => {
    event.preventDefault();

    // <Redirect to="/history-details/" />;
    this.props.history.push({
      pathname: "/history-details",
      state: { id: data.booking_id }
    });
    // this.props.history.push(/history-details:${data.booking_id});
  };

  render() {
    const { loadingHistory, loadingUpcoming, histories, upcoming } = this.state;

    return (
      <div className="main-sec-content">
      <div className="main">
        <div className="site-content">
          <div className="top-bottom-spacing">
            <div>
              <h1 className="section-head text-center trip_gall_heading">{t("your_trips")}</h1>
              <div className="trip_his_ul pt-md-5">
              <ul className="nav nav-pills trips-pills nav_pills trips_pills " role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link nav_link "
                    data-toggle="pill"
                    href="#history"
                  >
                    {t("space_history")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="pill" href="#upcoming">
                    {t("current_spaces")}
                  </a>
                </li>
              </ul>
              </div>
              <div className="tab-content hhh">
                <div id="history" className="tab-pane fade">
                  {loadingHistory ? (
                    t("loading")
                  ) : histories.length > 0 ? (
                    <div className="row mb-5">
                      {histories.map(history => (
                        <div className="col-sm-6 mb-3 col-md-4 col-lg-4 col-xl-4">
                          <div class="trip_card_container"> 
                          <div className="trip_his_card">
                          <Link to={`/history-details/${history.booking_id}`}>
                              <img 
                              style={{border:''}}
                                src={history.host_picture}
                                alt={history.host_name}
                                className="w-100"
                              />
                             
                          </Link>
                          <div className="shade"> </div>
                          <div className="trip_his_card_content">
                                <h4 className="mt-2 hostname" style={{fontWeight:'400',fontSize:'25px',color: '#fff'}}>{history.host_name}</h4>
                                <h5 className="m-0">{history.host_type}{" "}({history.status_text})</h5>
                              </div>
                          </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    t("no_data_found")
                  )}
                </div>
                <div id="upcoming" className="tab-pane active">
                  {loadingUpcoming ? (
                     t("loading")
                  ) : upcoming.length > 0 ? (
                    <div className="row ">
                      {upcoming.map(upcome => (
                        <div className="col-sm-6 mb-3 col-md-4 col-lg-4 col-xl-4">
                          {/* <Link to={`/history-details/${upcome.booking_id}`}>
                              <img
                                style={{border:'5px solid white',boxShadow: 'rgb(0 95 34 / 20%) 8px 10px 30px',borderradius:'20px'}}

                                src={upcome.host_picture}
                                alt={upcome.host_name}
                                className="w-100"
                              />
                              <div className="">
                                <h4 className="mt-2 hostname" style={{fontWeight:'400',fontSize:'25px',color: '#007B2C'}}>{upcome.host_name}</h4>
                              </div>
                          </Link>  */}
                         <div class="trip_card_container"> 

                          <div className="trip_his_card">
                           <Link to={`/history-details/${upcome.booking_id}`}>
                              <img
                              

                                src={upcome.host_picture}
                                alt={upcome.host_name}
                                className="w-100 "
                              />
                              
                          </Link>
                          <div className="shade"> </div>
                          <div className="trip_his_card_content">
                                <h4 className="mt-2 hostname" style={{fontWeight:'400',fontSize:'25px',color: '#fff'}}>{upcome.host_name}</h4>
                              </div>
                          </div>
                         </div>

                         
                        </div>
                      ))}
                    </div>
                  ) : (
                    t("no_data_found")
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default translate(TripHistory);
