import React, { Component } from "react";
import api from "../../../Environment";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import { translate, t } from "react-multi-lang";

class WishList extends Component {
  state = {
    data: null,
    loading: true,
  };

  componentDidMount() {
    // API call

    this.wishlistAPICall();
  }

  wishlistAPICall() {
    api.postMethod("wishlist").then((response) => {
      if (response.data.success) {
        this.setState({ data: response.data.data, loading: false });
      }
    });
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.wishlistAPICall();
  }

  deleteWishlist = (event, wishlist) => {
    event.preventDefault();
    api
      .postMethod("wishlist_operations", {
        host_id: wishlist.host_id,
        clear_all_status: 0,
      })
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };
  render() {
    return (
      <div className="main-sec-content">
        <div className="main">
          <div className="site-content">
            <div className="top-bottom-spacing">
              <div>
                <div className="row">
                  <h1 className="section-head list_section_head" style={{color:'#fff'}}>{t("lists")}</h1>
                </div>

                {this.state.loading ? (
                  ""
                ) : this.state.data.length > 0 ? (
                  <p className="top4 mb-0 captalize medium_cls">
                    {t("your_lists")} 
                    ({this.state.data.length})
                  </p>
                ) : (
                  ""
                )}
                {this.state.loading ? (
                  "Loading"
                ) : this.state.data.length > 0 ? (
                  <div className="row">
                    {this.state.data.map((wishlist) => (
                      <div
                        className="col-sm-6 col-md-4 col-lg-4 col-xl-4"
                        key={wishlist.wishlist_id}
                      >
                        <Link to={`/space/${wishlist.host_id}`}>
                          <div className="relative">
                            <div className="wishlist-img-sec">
                              <img
                                src={wishlist.host_picture}
                                alt={wishlist.host_name}
                                className="homes-img"
                              />
                              <div className="wishlist-text">
                                <h4 className="mt-0">{wishlist.host_name}</h4>
                              </div>
                            </div>
                            <div className="wishlist-icon-sec">
                              <div className="wishlist-icon">
                                <Link
                                  to={"#"}
                                  onClick={(event) =>
                                    this.deleteWishlist(event, wishlist)
                                  }
                                >
                                  <i className="fas fa-trash pink-clr" style={{color:"red",}} />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <p>
                        <b>{t("nothing_saved_yet")}</b>
                      </p>
                    </div>
                    <div className="row">
                      <p>{t("wishlist_description")}</p>
                    </div>
                    <div className="row ">
                     
                    <div className="wish_list_card">
                      <div className="wish_card_head">
                        <div className="wish_img">
                        <img className="" src="/assets/img/Empty.png" />
                        </div>
                        <div className="wishcard_content pt-5">
                      <h1>Wishlist</h1>
                      <h2>You have not created any list yet</h2>
                      <p>When you find something you like, click the heart icon to save it</p>
                      </div>
                    
                   <div className="wishbutton pt-4">
                   <Link className="green-btn btn-lg text-white mb-3 start_exploring start-exploring" to={`/`}>
                        {t("start_exploring")}
                      </Link>
                   </div>
                   </div>
              
                     
                    </div> 
                     </div>
                    
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(WishList));
