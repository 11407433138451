import React, { useState, useMemo, useEffect } from "react";
import "./NewListing.css";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const NewListingStep8 = (props) => {

    useEffect(() => {
    }, []);

    return (
        <>
            <div>
                <div className="new-listing-body-wrapper step-8-wrp">
                    <div className="step-8">
                        <form>
                            <div className="add-listing-success-img-sec">
                                {/* <img
                                    src={window.location.origin + "/assets/img/new-listing/amenities/host-added-successfully.svg"}
                                    className="add-listing-success-img"
                                /> */}
                                <img
                                    src={window.location.origin + "/assets/img/new-listing/amenities/host-added.png"}
                                    className="add-listing-success-img"
                                />
                                <h4>You have added the host successfully!</h4>

                                <Link to={"/host/listing"} className="green-btn mt-3">View</Link>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="new-listing-footer-finished-btn-sec">

                </div>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({

});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingStep8));
