import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import "./NewListing.css";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchCategoriesStart,draftHostDetails } from "../../store/actions/HostAction";

const NewListingStep1 = (props) => {

    useEffect(() => {
        if(props.hostId) {
            props.dispatch(fetchCategoriesStart({host_id: props.hostId}));
        } else {
            props.dispatch(fetchCategoriesStart());
        }
        
    }, []);
    console.log(props.host);
    return (
        <>
            <div>
                {!props.categories.loading && (

                    <div className="new-listing-body-wrapper">
                        <div className="step-1">
                            <div className="new-listing-category-box">
                                {props.categories
                                    ? props.categories.data.categories.length > 0
                                        ? props.categories.data.categories.map((category, index) =>
                                            <div className="new-listing-category-item">
                                                <input
                                                    type="radio"
                                                    name="category_id"
                                                    value={category.category_id}
                                                    id={`radio-category-${index}`}
                                                    defaultChecked={category.is_selected == 1 ? true : false}
                                                    onClick={(event) => {
                                                        props.dispatch(
                                                            draftHostDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                            )
                                                        );
                                                    }}
                                                />
                                                <label for={`radio-category-${index}`} className="new-listing-category-card">
                                                    <div className="new-listing-category-info">
                                                        <h4>{category.category_provider_display_name}</h4>
                                                        <img
                                                            src={category.picture}
                                                            className="category-host-img"
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                        : null
                                    : null}

                            </div>
                        </div>
                        <div className="new-listing-footer-btn-sec">
                    <button className="back-btn">
                        Back
                    </button>
                    <button onClick={() => props.setStep(2)} className="next-btn">
                        Next
                    </button>
                </div>
                    
                    </div>
                )}
                
            </div>
        </>
    );
};
const mapStateToPros = (state) => ({
    categories: state.host.categories,
    host: state.host.host,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingStep1));