/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import TripBanner from "./tripBanner";
import TripGallery from "./tripGallery";
import TripBookingForm from "./tripBookingForm";
import TripReviewSection from "./tripReviewSection";
import TripHostSection from "./tripHostSection";
import TripSimilarListing from "./tripSimilarListing";
import TripShowAllAmen from "./tripShowAllAmen";
import api from "../../../Environment";
import TripLocation from "./TripLocation";
import renderHTML from "react-render-html";
import Slider from "react-slick";
import Loader from "../../Helper/Loader";
// import { DatePicker, Calendar } from "@y0c/react-datepicker";
// import "@y0c/react-datepicker/assets/styles/calendar.scss";
import { Link } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import GetAvailability from "../../Helper/getAvailability";
import { translate, t } from "react-multi-lang";

class SingleTrip extends Component {
  state = {
    singleTripDetails: null,
    loading: true,
    formData: {
      host_id: this.props.match.params.id,
    },
  };

  componentDidMount() {
    const formData = {
      host_id: this.props.match.params.id,
    };
    this.singleTripViewApiCall(formData);
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const formData = {
      host_id: nextProps.match.params.id,
    };
    // this.setState({ loading: true });
    this.singleTripViewApiCall(formData);
  }

  singleTripViewApiCall(formData) {
    api.postMethod("hosts_view", formData).then((response) => {
      if (response.data.success === true) {
        this.setState({
          singleTripDetails: response.data.data,
          loading: false,
        });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.props.history.goBack();
      }
    });
  }

  render() {
    let load = new Loader("");
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
    let { singleTripDetails, loading } = this.state;

    return loading ? (
      load.bannerLoader()
    ) : (
      <div className="main-sec-content mt-3">
        {/* <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="space_baner_heading">
                    <h1>Beverly Springfield</h1>
                    <p>2821 Lake Sevilla, Palm Harbor, TX</p>
              </div>
            </div>
             <div className="col-6">
              
            </div>
          </div>
        </div> */}




        <TripBanner
          basicDetails={singleTripDetails.basic_details}
          status="user"
        />
        <div class="site-content"> 
                      <div className="row space_banner_row">
                        <div className="col-md-8">
                            <div className="host_col8_inner_banner" >
                            {singleTripDetails.basic_details.gallery.map((image,i) => {
                                          if (i === 0) {
                                            return <div style={{height:'396px'}} key={image.picture} className=" homes-img-sec2 new_homes_img_sec2">
                                            <img
                                              srcSet={image.picture}
                                              src={image.picture}
                                              alt="Loading..."
                                              className="homes-img  m-auto "
                                            />
                                          </div>;
                                          }  else {
                                            return <div></div>;
                                          }
                                        })} 
                           
                                {/* <div className="top-right-btn">
                                    <Link
                                        to="#"
                                        className="white-btn btn-small m-2"
                                        onClick={e =>
                                            this.favourite(e, this.props.basicDetails)
                                        }
                                    >
                                        {this.props.basicDetails.wishlist_status ? (
                                            <div>
                                                <i className="fa fa-check" />
                                                &nbsp; {t("saved")}
                                            </div>
                                        ) : (
                                            <div>
                                                <i className="far fa-heart" />
                                                &nbsp; {t("save")}
                                            </div>
                                        )}
                                    </Link>
                                </div> */}
              
                            </div>
                         </div>

                        <div className="col-md-4">
                            <div className="col4wrapper">
                                <div class="space_ban_image1 space_right_img">
                                    <div className="col_img1 h_col_img">
                           
                                        {singleTripDetails.basic_details.gallery.map((image,i) => {
                                          if (i === 1) {
                                            return <div key={image.picture} className=" homes-img br-0">
                                            <img
                                              srcSet={image.picture}
                                              src={image.picture}
                                              alt="Loading..."
                                              className="homes-img  m-auto "
                                            />
                                          </div>;
                                          }  else {
                                            return <div>
                                              
                                            </div>;
                                          }
                                        })} 
                                    </div>
                                </div>

                                <div className="col_img1 space_b_img2 space_right_img">
                                    <div className="space_ban_image1 ">
                                       {singleTripDetails.basic_details.gallery.map((image,i) => {
                                          if (i === 2) {
                                            return <div key={image.picture} className=" homes-img-sec2 new_homes_img_sec2">
                                            <img
                                              srcSet={image.picture}
                                              src={image.picture}
                                              alt="Loading..."
                                              className="homes-img  m-auto "
                                            />
                                          </div>;
                                          }  else {
                                            return <div></div>;
                                          }
                                        })} 

                                        <div className="bottom_right_btn">
                                            <a
                                                href="#"
                                                className="white-btn btn-small m-2"
                                                data-toggle="modal"
                                                data-target="#image-gal"
                                            >
                                                {/* {t("view_photos")} */}
                                                <img className="img-fluid" srcSet="../../../assets/img/v_gallery.png" /> {t("View all photos")}
                                            </a>
                                        </div>
                                        {/* <div className="bottom-left-btn"> */}
                                        
                                     </div>
                                </div>
                            </div>

                        </div>

                      </div>
        </div>

        <TripGallery gallery={singleTripDetails.basic_details.gallery} />

        <div className="main">
          <div className="site-content">
            <div className="top-bottom-spacing top_bottom_spacing ">
              <div className="row">
                <div className="col-xl-7 col-lg-10 col-md-10 auto-margin">
                  <div id="overview ">

                    {/* <div className="media">
                      <div className="media-body mr-3">
                        <a href="#">
                          <p className="red-text txt-overflow">
                            {singleTripDetails.basic_details.location}
                          </p>
                        </a>
                        <h1 className="category-section-head">
                          {singleTripDetails.basic_details.dimension}{" "}
                          {singleTripDetails.basic_details.host_name}
                        </h1>
                        <h4 className="captalize section-subhead">
                          {singleTripDetails.basic_details.host_location !=
                            "" &&
                          singleTripDetails.basic_details.host_location !=
                            undefined &&
                          singleTripDetails.basic_details.host_location !=
                            "undefined"
                            ? singleTripDetails.basic_details.host_location
                            : ""}
                        </h4>
                      </div>
                      <div>
                      
                      </div>
                    </div> */}

                    <h4 className="single-cat-text medium-cls space_feature">
                      {/* {singleTripDetails.amenties.title} */}
                      Feature
                    </h4>
                    <div className="spac_sec_btn property_owner_card br-8">
                    <ul className="home-requirements home_requirements">
                    
                      {singleTripDetails.basic_details.storage_details.length >
                      0
                      
                        ? singleTripDetails.basic_details.storage_details.map(
                            (details ,i ) => {
                              
                              if (i === 0) {
                                return <div  className=" d-flex mr-2" >
                                <img
                                  src="../assets/img/beds.png"
                                  alt="Loading..."
                                  
                                />
                                <li className="">
                                
                                {/* <span className="">{details.title}</span> */}
                                <span className=" ">{details.title}</span>



                              </li>
                              </div>;
                              } 
                              if (i === 1) {
                                return <div  className="d-flex mr-2" >
                                <img
                                style={{width: '20px',height: '20px',margin:'auto'}}
                                  src="../assets/img/Group 48095663.png"
                                  alt="Loading..."
                                  
                                />
                                <li className="">
                                
                                {/* <span className="">{details.title}</span> */}
                                <span className=" ">{details.title}</span>



                              </li>
                              </div>;
                              }
                               if (i === 2) {
                                return <div  className=" d-flex " >
                                <img
                                  src="../assets/img/sqare.png"
                                  alt="Loading..."
                                  
                                />
                                <li className="">
                                
                                {/* <span className="">{details.title}</span> */}
                                <span className=" ">{details.title}</span>



                              </li>
                              </div>;
                              } 

                            }
                          )
                        : ""}
                    </ul>
                    </div>

                    <div className="home_about pt-5"> 
                    <div className="space_home_heading">
                      <h1 className="space_feature">About this home</h1>
                      </div> 
                      <div className="home_description pt-2">    
                    <p className="single-cat-text">
                      {renderHTML(
                        singleTripDetails.basic_details.host_description
                      )}

                    </p>
                    </div>  
                    </div>

                    <TripHostSection
                    providerDetails={singleTripDetails.provider_details}
                    singleTripDetails={singleTripDetails}
                  />

                    
                    {/* <p className="overview-line" /> */}

                    {/* <h4 className="single-cat-text medium-cls ">
                      {singleTripDetails.amenties.title}
                      Features
                    </h4> */}
                    <ul className="amenties-list">
                      {singleTripDetails.amenties.data.length > 0 ? (
                        <div className="new-amenties-card">
                          <div className="new-amenties-item">
                            {singleTripDetails.amenties.data.map((amentie) => (
                              <li className="">
                                <img
                                  src={amentie.picture}
                                  className="amentieimg"
                                />
                                <div>{amentie.name}</div>
                              </li>
                            ))}
                          </div>
                          <div className="clearfix" />
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#amenities"
                          >
                            <h4 className="amenities-head">
                              {t("show_all_amenities")}
                            </h4>
                          </a>

                          <TripShowAllAmen
                            amenties={singleTripDetails.amenties.data}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </ul>

                    {/* <p className="overview-line" />
                    <h4 className="single-cat-text medium-cls">
                      {t("sleeping_arrangements")}
                    </h4> */}
                    {/* {singleTripDetails.arrangements.data.length > 0 ? (
                      <section className="arrangements slider">
                        <Slider {...settings}>
                          {singleTripDetails.arrangements.data.map(
                            (arrangement) => (
                              <div>
                                <div className="box">
                                  <h3 className="">
                                    <img
                                      src={arrangement.picture}
                                      style={{ width: "24px", height: "16px" }}
                                    />
                                  </h3>
                                  <h4 className="captalize medium-cls top1">
                                    {arrangement.title}
                                  </h4>
                                  <h4 className="captalize">
                                    {arrangement.note}
                                  </h4>
                                </div>
                              </div>
                            )
                          )}
                          <div className="box">
                            <h3 className="">
                              <img
                                src="#"
                                style={{ width: "24px", height: "16px" }}
                              />
                            </h3>
                            <h4 className="captalize medium-cls top1">
                              {t("bed_room")}
                            </h4>
                            <h4 className="captalize">{t("bed_room")}</h4>
                          </div>
                        </Slider>
                      </section>
                    ) : (
                      ""
                    )} */}

                    {/* <p className="overview-line" /> */}
                    {singleTripDetails.policies.data.length > 0
                      ? singleTripDetails.policies.data.map((homeDetails) => (
                          <div>
                            <h4 className="single-cat-text medium-cls">
                              {homeDetails.title}
                            </h4>
                            <h4 className="rules-text">
                              {renderHTML(homeDetails.description)}
                            </h4>
                            {/* <p className="overview-line" /> */}
                          </div>
                        ))
                      : ""}

                    {/* <h4 className="single-cat-text medium-cls space_feature mb-0">
                      {t("availability")}
                    </h4> */}
                    {/* <h4 className="single-cat-text">
                     
                      <div>
                        <GetAvailability
                          host_id={singleTripDetails.basic_details.host_id}
                        />
                      </div>
                      <span className="medium-cls">{singleTripDetails.basic_details.min_days} {t("night")}</span> {t("minimum_stay")}
                      <span className="dot">
                        <i className="fas fa-circle" />
                      </span>{" "}
                      <span className="medium-cls">
                        {singleTripDetails.basic_details.max_days} {t("night")}
                      </span>{" "}
                      {t("maxiumum_stay")}
                      <span className="dot">
                        <i className="fas fa-circle" />
                      </span>{" "}
                      {t("updated_day_ago")}
                    </h4> */}
                    {/* <p className="overview-line" /> */}
                  </div>

                  <TripReviewSection
                    details={singleTripDetails.basic_details}
                  />

                

                  <TripLocation location={singleTripDetails.basic_details} />
                  <div className="clearfix" />
                </div>
                <TripBookingForm
                  basicDetails={singleTripDetails.basic_details}
                  status="user"
                  providerDetails={singleTripDetails.provider_details}
                  location={this.props.location}
                  history={this.props.history}
                />
              </div>

              <TripSimilarListing
                host_id={singleTripDetails.basic_details.host_id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(SingleTrip));
