import React, { Component } from "react";
import api from "../../../HostEnvironment";
import io from "socket.io-client";
import { Link } from "react-router-dom";
import { apiConstants } from "./../../Constant/constants";
import { translate, t } from "react-multi-lang";
import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";
let socket;

class HostInbox extends Component {
  state = {
    chatList: null,
    loadingChatList: true,
    socket: false,
    inputMessage: null,
    chatData: null,
    loadingChatData: true,
    headerDetail: null,
    headerDetailsLoadFirst: true,
    headerContentLoading: true,
    loadingStatus: true,
    loadingContent: null,
    loadingStatusChatHistory: true,
    loadingContentChatHistory: null,
    chatListskipCount: 0,
    chatHistorySkipCount: 0,
    loadMoreChatHistory: false,
    chatIndex: 0,
  };

  componentDidMount() {
    this.chatListApiCall(0);
  }

  chatListApiCall = (loadMoreStatus) => {
    let items;
    api
      .postMethod("bookings_inbox", { skip: this.state.chatListskipCount })
      .then((response) => {
        if (response.data.success) {
          if (this.state.chatList != null) {
            items = [...this.state.chatList, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            chatList: items,
            loadingChatList: false,
            chatListskipCount:
              response.data.data.length + this.state.chatListskipCount,
            loadingStatus: true,
          });

          if (this.state.chatList.length > 0 && loadMoreStatus == 0) {
            this.headerDetails(0);
            this.chatDetailsApiCall(0);
          } else {
            this.setState({
              headerContentLoading: false,
            });
          }
        }
      });
  };

  socketConnectionfun = (userId, providerId, hostId) => {
    socket = io(apiConstants.socketUrl, {
      query:
        `commonid: 'user_id_` +
        userId +
        `_provider_id_` +
        providerId +
        `_host_id_` +
        hostId +
        `', myid: ` +
        providerId,
    });

    socket.emit("update sender", {
      commonid:
        "user_id_" +
        userId +
        "_provider_id_" +
        providerId +
        "_host_id_" +
        hostId,
      myid: providerId,
    });
    let chatContent;
    socket.on("message", (newData) => {
      //  if(newData.chattype)

      let content = [];
      content.push(newData);
      chatContent = [...this.state.chatData, ...content];
      this.setState({ chatData: chatContent });
    });
  };

  chatDetailsApiCall = (index) => {
    let inputData;
    if (this.state.chatList[index].booking_id == 0) {
      inputData = {
        host_id: this.state.chatList[index].host_id,
        user_id: this.state.chatList[index].user_id,
        skip: this.state.chatHistorySkipCount,
      };
    } else {
      inputData = {
        booking_id: this.state.chatList[index].booking_id,
        host_id: this.state.chatList[index].host_id,
        user_id: this.state.chatList[index].user_id,
        skip: this.state.chatHistorySkipCount,
      };
    }

    api.postMethod("bookings_chat_details", inputData).then((response) => {
      let chathistory;
      if (response.data.success) {
        if (this.state.loadMoreChatHistory) {
          if (this.state.chatData != null) {
            chathistory = [
              ...response.data.data.reverse(),
              ...this.state.chatData,
            ];
          } else {
            chathistory = [...response.data.data.reverse()];
          }
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount:
              response.data.data.length + this.state.chatHistorySkipCount,
            loadingStatusChatHistory: true,
            loadMoreChatHistory: false,
          });
        } else {
          chathistory = response.data.data.reverse();
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount: response.data.data.length,
            loadingStatusChatHistory: true,
            chatIndex: index,
          });
        }
      } else {
        //
      }
    });
  };

  headerDetails = async (index) => {
    await this.setState({
      headerDetail: this.state.chatList[index],
      headerContentLoading: false,
    });

    if (!this.state.headerContentLoading) {
      this.socketConnectionfun(
        this.state.headerDetail.user_id,
        localStorage.getItem("hostId"),
        this.state.headerDetail.host_id
      );
    }
  };

  handleOnChange = ({ currentTarget: input }) => {
    this.setState({ inputMessage: input.value });
    //
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let chatData = [
      {
        user_id: this.state.chatData[0].user_id,
        provider_id: this.state.chatData[0].provider_id,
        message: this.state.inputMessage,
        host_id: this.state.chatData[0].host_id,
        booking_id: this.state.chatData[0].booking_id,
        chat_type: "pu",
        provider_picture: localStorage.getItem("host_picture"),
      },
    ];

    socket.emit("message", chatData[0]);

    let messages;

    if (this.state.chatData != null) {
      messages = [...this.state.chatData, ...chatData];
    } else {
      messages = [...chatData];
    }
    this.setState({
      chatData: messages,
      inputMessage: "",
    });
  };

  changeChatfun = async (event, index) => {
    event.preventDefault();
    socket.disconnect();
    await this.setState({ chatHistorySkipCount: 0 });
    this.headerDetails(index);
    this.chatDetailsApiCall(index);
  };

  loadMoreChatList = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.chatListApiCall(1);
  };

  loadMoreChatHistory = (event) => {
    event.preventDefault();
    this.setState({
      loadingStatusChatHistory: false,
      loadingContentChatHistory: "Loading...",
      loadMoreChatHistory: true,
    });
    this.chatDetailsApiCall(this.state.chatIndex);
  };

  render() {
    const {
      chatList,
      loadingChatList,
      chatData,
      loadingChatData,
      headerDetail,
      headerContentLoading,
      loadingStatus,
      loadingContent,
      loadingContentChatHistory,
      loadingStatusChatHistory,
    } = this.state;

    return (
      <div className="main">
        {loadingChatList ? (
          "Loading..."
        ) : chatList.length > 0 ? (
             <div className="main host-main">
                <div className="container-fluid">

                  <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                      <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Inbox</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                          <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 mb-3" >
                             {/*<div className="input-group mb-3 search-box" style={{bordeBottom:'unset!important'}}>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="search"  style={{background: '#CEFFE0',borderRadius:'10px 0px 0px 10px'}}>
                                        <i className="fas fa-search" />
                                    </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("search_here")}
                                  aria-label="Username"
                                  aria-describedby="search"
                                  style={{background: '#CEFFE0',borderRadius:'0px 10px 10px 0px'}}
                              /> 
                            </div>*/}
                            <div className="chat-list-wrap chat-leftsec chat-leftsec2">
                              <button
                                className="navbar-toggler chat-menu"
                                type="button"
                                data-toggle="collapse"
                                data-target="#chat-list"
                                aria-controls="chat-list"
                                aria-expanded="false"
                              >
                                <span className="float-left">{t("chat_list")}</span>
                                <span className="navbar-toggler-icon chat-menu-icon float-right">
                                  <img src="assets/img/comment.png" alt="comments" />
                                </span>
                              </button>
                              <div className="d-flex pl-3 pr-3 pt-4">
                              
                              <h2 className="" style={{fontWeight:'700',fontSize:'22px'}}>inbox</h2>
                                        <img src="assets/img/ic_baseline-search.png" className="ml-auto" />
                              </div>

                              <div
                                className="chat-list-block list-left show collapse navbar-collapse"
                                id="chat-list"
                              >
                                {chatList.map((list, index) => (
                                  <Link
                                    to="#"
                                    key={list.user_id}
                                    className="chat-list-box"
                                    onClick={(event) => this.changeChatfun(event, index)}
                                  >
                                    <div className="chat-prof-img sdsd">
                                      <img src={list.user_picture} alt={list.user_name} />
                                    </div>
                                    <div className="chat-prof-content d-flex">
                                      <div>
                                        <h5 className="chat-name">{list.user_name}</h5>
                                        <p className="chat-msg">{list.message}</p>
                                      </div>
                                      <div className="ml-auto">
                                        <p className="chat-date">{list.updated}</p>
                                        <div class="chat-circle">2</div>
                                      </div>
                                    </div>
                                    
                                    <div className="clear-both" />
                                  </Link>
                                ))}
                              </div>
                              <div className="text-center">
                                {loadingStatus ? "" : loadingContent}
                                {chatList.length > 0 ? (
                                  <a
                                    href="#"
                                    class="show-all"
                                    onClick={this.loadMoreChatList}
                                  >
                                    {t("show_more")}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 chat-rightsec">
                            {/*<div style={{textAlign:'right',marginRight:'24px'}}><a class="  rounded border-0 px-3 py-2 ml-4 mt-4 " style={{color: '#1DD05D',backgroundColor:'white'}}> More</a>

                              <a class="  rounded border-0 px-3 py-2 ml-4 mt-4 " style={{color: '#1DD05D',backgroundColor:'white'}}>Clear Chat</a>
                            </div> */}
                            {loadingChatList ? (
                              t("loading")
                            ) : (

                              <div className="relative  chat-rightsec-wrp" style={{height:'600px'}}>
                                {headerContentLoading ? (
                                  t("loading")
                                ) : headerDetail ? (
                                  <div className="chat-header">
                                    <div className="chat-list-box single-chat-box">
                                      <div>
                                        <div className="chat-prof-img">
                                          <img
                                            src={headerDetail.user_picture}
                                            alt={headerDetail.user_name}
                                          />
                                        </div>
                                        <div className="chat-prof-content d-flex">

                                        <div className="">
                                          <h5 className="chat-name">
                                            {headerDetail.user_name}
                                          </h5>
                                          <p className="chat-date">{headerDetail.updated}</p></div>
                                          <div className="ml-auto">
                                        <img className="img-fluid" srcSet="../../../assets/img/ic_outline-flag.png" alt="" />


                                        <img className="" srcSet="../../../assets/img/del.png" alt="" />

                                    </div>
                                        </div>
                                        
                                       
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="chat-name m-t-10">
                                    <p className="text-center">{t("no_message")}</p>
                                  </div>
                                )}
                                {loadingChatData ? (
                                  ""
                                ) : (
                                  <div className="chat-content">
                                    {chatData.length > 0 ? (
                                      <div className="text-center">
                                        {loadingStatusChatHistory
                                          ? ""
                                          : loadingContentChatHistory}
                                        <a
                                          href="#"
                                          class="show-all"
                                          onClick={(event) => this.loadMoreChatHistory(event)}
                                        >
                                          <i className="fas  fa-chevron-up mr-1" /> {t("show_more")}
                                        </a>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {chatData.map((chat) =>
                                      chat.chat_type == "up" ? (
                                        <div className="chat-left">
                                          <div className="display-inline">
                                            <img
                                              className="chat-img-left"
                                              src={chat.user_picture}
                                            />
                                            <div className="chat-content-right">
                                              <div className="chat-message">
                                                {chat.message}{" "}
                                                <p className="chat-time">{chat.updated}</p>

                                              </div>
                                              <p className="clearfix m-0" />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="clearfix" />
                                          <div className="chat-right">
                                            <div className="display-inline">
                                              <div className="chat-content-right">
                                                <div className="chat-message  text-right">
                                                  {chat.message}{" "}
                                                  <p className="chat-time text-right">
                                                  {chat.updated}
                                                </p>
                                                </div>
                                                <p className="clearfix m-0" />
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                                <div className="chat-footer">
                                  <form onSubmit={this.handleSubmit}>
                                    <div className="input-group dropdown justify-content-between">
                                      <div className="chat-footer-wrp" style={{width:'84%',position: 'relative'}}>
                                                                        <img className="chat-smile-icon" src={window.location.origin + "/assets/img/Group48.png"} />

                                        <input style={{border: '1.58665px solid rgba(18, 20, 29, 0.1)'}}
                                          type="text"
                                          className="form-control dropdown-toggle"
                                          data-toggle="dropdown"
                                          placeholder={t("enter_your_message")}
                                          name="message"
                                          value={this.state.inputMessage}
                                          onChange={this.handleOnChange}
                                        /><i className="fas fa-solid fa-paperclip chat-file-upload" />

                                      </div> 
                                      <div className="input-group-append" >
                                        <span style={{color:'white'}}
                                          className="input-group-text"
                                          id="basic-addon"
                                          onClick={this.handleSubmit}
                                        >
                                          <i className="fas fa-paper-plane mr-1" /> Send
                                        </span>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        ) : (
          t("no_message_for_you")
        )}

      </div>
    );
  }
}

export default translate(HostInbox);
