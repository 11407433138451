import React, { Component } from "react";

class EmptyLayout extends Component {
  constructor(props) {
    super(props);

    // define states
  }

  render() {
    return (
      <div className="wrapper empty-wrapper">
        <div>{React.cloneElement(this.props.children)}</div>
      </div>
    );
  }
}
export default EmptyLayout;
