import React, { Component } from "react";

import { Link } from "react-router-dom";

import Loader from "../../Helper/Loader";

import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import Helper from "../../Helper/Helper";
import configuration from "react-global-configuration";
import AddCardForm from "../AccountSettings/addCardForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import { translate, t } from "react-multi-lang";

const $ = window.$;
const onlyDay = {
  weekday: "long"
};
const onlyMonth = {
  month: "short"
};
const onlyDate = {
  day: "numeric"
};

class Checkout extends Helper {
  // constructor(props) {
  //   super(props);

  //   // States and props usage
  // }
  state = {
    loading: true,
    adults: 0,
    children: 0,
    infants: 0,
    apiResponses: null,
    apiCallStatus: true,
    total_days_price: null,
    total_guest: null,
    total_days: null,
    total: null,
    description: null,
    step: 1,
    cardList: null,
    cardLoading: true,
    agreement: false,
    agreement2:false
  };
  handleChange2 = e => this.setState({ agreement: e.target.checked });
  handleChange22 = e => this.setState({ agreement2: e.target.checked });

  componentDidMount() {
    // Call api function

    if (this.props.location.state) {
      this.setState({
        loading: false,
        adults: this.props.location.state.adults,
        children: this.props.location.state.children,
        infants: this.props.location.state.infants
      });
      this.listCardApi();
    } else {
      this.props.history.goBack();
    }
  }

  listCardApi = () => {
    api.postMethod("cards_list").then(response => {
      if (response.data.success) {
        this.setState({
          cardList: response.data.data,
          cardLoading: false
        });
        // if (response.data.data.cards.length < 1) {
        //   ToastDemo(
        //     this.props.toastManager,
        //     "There is no card in your account. Please add card and try again.. ",
        //     "error"
        //   );
        //   this.props.history.push("/user-payment");
        // }
      } else {
        ToastDemo(
          this.props.toastManager,
          response.data.error,
          "error"
        );
        this.props.history.goBack();
      }
    });
  };

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.listCardApi();
  }

  nextStep = event => {
    event.preventDefault();
    this.setState({ step: 2 });
  };
  prevStep = event => {
    event.preventDefault();
    this.setState({ step: 1 });
  };

  priceCal = async () => {
    const inputDate = {
      adults: this.state.adults,
      children: this.state.children,
      checkin: this.props.location.state.apiResponse.checkin,
      checkout: this.props.location.state.apiResponse.checkout,
      host_id: this.props.location.state.apiResponse.host_id
    };
    await api.postMethod("host_price_calculator", inputDate).then(response => {
      if (response.data.success) {
        this.setState({
          total_days_price: response.data.data.total_days_price,
          apiResponses: response.data.data,
          total_days: response.data.data.total_days,
          total_guests: response.data.data.total_guests,
          total: response.data.data.total,
          apiCallStatus: false
        });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  bookingCreate = event => {
    event.preventDefault();
    var agr=this.state.agreement;
    var agr2=this.state.agreement2;
    if( agr2 != false && agr != false )
    {
      const inputData = {
        storing_details: this.props.location.state.storing_details,
        checkin: this.props.location.state.apiResponse.checkin,
        checkout: this.props.location.state.apiResponse.checkout,
        host_id: this.props.location.state.apiResponse.host_id,
        payment_mode: "card",
        description: this.state.description,
        step: 5
      };
      api.postMethod("bookings_create", inputData).then(response => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, "Booking successfull", "success");
          this.props.history.push("/history");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          if (
            response.data.error_code == 1004 ||
            response.data.error_code == 1002
          ) {
            this.props.history.push("/");
            ToastDemo(this.props.toastManager, "Please login to book", "error");
          }
        }
      });
    }
    else{
      alert("Please Checked The Rules And Policy,  ");
    }  
  };

  handleChange = ({ currentTarget: input }) => {
    this.setState({ description: input.value });
  };

  changeCard = ({ currentTarget: input }) => {
    api
      .postMethod("cards_default", { user_card_id: input.value })
      .then(response => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  render() {
    const {
      loading,
      loadingStatus,
      loadingContent,
      adults,
      children,
      infants,
      apiCallStatus,
      step,
      cardList,
      cardLoading
    } = this.state;
    if (loading) {
      return "Loading...";
    } else {
      const {
        basicDetails,
        apiResponse,
        providerDetails
      } = this.props.location.state;

      return (
        <div>
           <div class="checkout_tab">
           <div className="checkout_heading">
           <h1>Please review your details</h1>
           </div>
          <div className="display-inline display_inline pad-1">
           
           <div className="stepwizard checkout-left">
             <div className="stepwizard-row setup-panel">
              <div className="step_wizard_flex">
               <div className="stepwizard-step">
                 <a
                   href="#step-1"
                   className={
                     step == 1 ? "checkout-head checkout_head active" : " checkout-head checkout_head"
                   }
                   onClick={this.prevStep}
                 >
                   <div className=" icon_gap">
                   <div className={
                     step == 1 ? "circle-head circle_head active" : "circle-head circle_head"
                   }
                   
                   onClick={this.prevStep}
                   ><p className="d-flex justify-content-center align-items-center">1</p></div> 
                 
                   
                   <div> {t("review_trip_details")}</div>
                   </div>
                  
                 </a>
               </div>
               <div className="sep_icon">
                     <i className="fas fa-chevron-right ml-3 mr-3" />
                   </div>
               <div className="stepwizard-step">
                 <a
                   href="#step-3"
                   className={
                     step == 2 ? "checkout-head checkout_head active" : "checkout-head checkout_head"
                   }
                   onClick={this.nextStep}
                 >
                  <div className="icon_gap">
                  <div className={
                     step == 2 ? "circle-head circle_head active" : "circle-head circle_head"
                   }
                   onClick={this.nextStep}> <p className="d-flex justify-content-center align-items-center">2</p></div> 

                   <div>  Confirm & Pay</div>
                  </div>
                 </a>
               </div>
               </div>
             </div>
           </div>
         </div>
          </div>
          <hr />
        <div className="site-content">
         

          

          
            <div className="row pt-5">
              <div className="col-sm-12 col-md-6 col-lg-7 col-xl-7">
                <div className="payment_rht">
                <form className="checkout-form checkout_form ">
                  <div className="mediLink common-msg common_msg">
                    <img
                      src="../../../assets/img/mark .png"
                      alt="John Doe"
                      className="mr-3 align-self-center common-img"
                    />
                    <div className="media-body media_body2 ">
                      <span className="">
                        <span className="">
                          {t("this_is_rare_find")}
                        </span>{" "}
                        {providerDetails.provider_name}'s space is usually
                        booked.
                      </span>
                    </div>
                  </div>
                  <div className="">
                    {step == 1 ? (
                      <div className="panel-primary setup-content pt-md-3" id="step-1">
                        <h1 className="checkout-section-head checkout_section_head">
                         Review Your Reservation
                        </h1>
                        {/* Calendar section */}
                        <div>
                          {/* <h4 className="checkout-section-subhead">
                            {apiResponse.total_days} in{" "}
                            {basicDetails.host_location}

                          </h4> */}
                           <h4 className="checkout-section-subhead">
                            {/* {apiResponse.total_days} in{" "}
                            {basicDetails.host_location} */}
                            
                          </h4>
{/* 
                          <div className="row">
                            <div className="col-md-6">
                              <div className="calendar_col_container">
                                <div className="calendar_col_content"> 
                              <div className="booking-calendar-div">
                                <div className="booking-calendar-div-section">
                                  <p className="date ">

                                
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyMonth)}
                                   
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyDate)}
                                  </p>
                                
                                </div>
                              </div>
                              <div className="booking-calendar-text-section">
                              check-in
                                  <p className="date text-dark">
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyDay)}{" "}
                                   
                                  </p>
                                  <h4>{apiResponse.checkin_time}</h4>
                              </div>
                              </div>

                            </div>
                            </div>

                            <div className="col-md-6">
                            <div className="calendar_col_container">
                            <div className="calendar_col_content"> 
                              <div className="booking-calendar-div">
                                <div className="booking-calendar-div-section">
                                  <p className="date">
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyMonth)}
                                  
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyDate)}
                                  </p>
                                </div>
                              </div>
                              <div className="booking-calendar-text-section">
                                <div>
                                  <p>
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyDay)}{" "}
                                    check-out
                                  </p>
                                  <h4>{apiResponse.checkout_time}</h4>
                                </div>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div> */}


                          <div className="row">
                            <div className="col-md-6">
                              <div className="calendar_col_container">
                                <div className="calendar_col_content"> 
                              {/* <div className="booking-calendar-div">
                                <div className="booking-calendar-div-section">
                                  <p className="date ">

                                
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyMonth)}
                                   
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyDate)}
                                  </p>
                                
                                </div>
                              </div> */}
                              <div className="booking-calendar">
                                <p className="ck_in">check-in</p> 
                                 <div class="checkout_date d-flex">
                                  <div className="checkout_day">
                                    <div> 
                                  <p className="date date2 text-dark">
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyDay)}{" "}
                                   
                                  </p>
                                  </div>
                                  </div>
                                  <div className="checkout_card booking-calendar-div-section">
                                  <p className="date text-dark">

                                
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyMonth)}
                                   
                                    {new Date(
                                      apiResponse.checkin
                                    ).toLocaleDateString("en-US", onlyDate)}
                                  </p>
                                
                                </div>
                                  </div>
                                  <h4 className="pl-1">{apiResponse.checkin_time}</h4>
                              </div>
                               
                              </div>
                              <div className="checkout_icon"> 
                                <i class="fa-regular fa-calendar"></i>
                                </div>
                            </div>
                            
                            </div>

                            <div className="col-md-6">
                            <div className="calendar_col_container">
                            <div className="calendar_col_content"> 
                            <p className="ck_in"> check-out</p> 
                              {/* <div className="booking-calendar-div">
                            
                                <div className="booking-calendar-div-section">
                                  <p className="date text-dark">
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyMonth)}
                                  
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyDate)}
                                  </p>
                                </div>
                              </div> */}
                              <div className="booking-calendar-text-section">
                                <div className="booking_calander_inner m-0">
                                <div className="booking-calendar-div-section booking_c_section">
                                <div className="booking_calender_rft d-flex m-0">
                                <p className="text-dark date2">
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyDay)}{" "}
                                    
                                  </p>

                                  <p className="date date3 text-dark">
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyMonth)}
                                  
                                    {new Date(
                                      apiResponse.checkout
                                    ).toLocaleDateString("en-US", onlyDate)}
                                  </p>
                                  </div>
                                </div>
                                </div>
                                <h4>{apiResponse.checkout_time}</h4>
                              </div>
                              </div>
                              <div class="checkout_icon"><i class="fa-regular fa-calendar"></i></div>
                            </div>
                          </div>
                        </div>

                        </div>

                        <hr />

                        <div>
                          <div className="media">
                            <div className="media-body">
                              <h4 className="checkout-section-head checkout_section_head">
                                {t("say_hello_your_host")}
                              </h4>
                              <p class="pay_p" style={{color:"#12141D",fontSize:"13px"}}>
                                {t("say_hello_your_host_para")}
                              </p>
                            </div>
                            {/* <img
                              src={providerDetails.picture}
                              className=" ml-3 rounded-circle review-img1"
                            /> */}
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              rows="5"
                              id="comment"
                              name="description"
                              placeholder="Your massage here"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        {/* Guest details + description */}

                        <button
                          className="green-btn checkout_btn nextBtn checkout-btn"
                          type="button"
                          onClick={this.nextStep}
                        >
                          {/* {t("agree_and_continue")} */}
                          Agree & Continue

                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {step == 2 ? (
                      <div className="setup-content" id="step-2">
                        <h1 className="checkout-section-head checkout_section_head">
                          {t("confirm_and_pay")}
                        </h1>
                        <div className="form-group form_group pt-2">
                          <label>pay with</label>
                          
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#AddCardModel"
                            className="green-link edit-link"
                          >
                            {t("add_a_card_method")}
                          </a>
                          <StripeProvider apiKey={configuration.get("configData.stripe_publishable_key")}>
                            <Elements>
                              <AddCardForm {...this.props} />
                            </Elements>
                          </StripeProvider>
                          {cardLoading
                            ? "Loading..."
                            : cardList.cards.length > 0
                              ? cardList.cards.map(card => (
                                <div
                                  className="form-check add-list-block"
                                  key={card.user_card_id}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="card"
                                    value={card.user_card_id}
                                    id={card.user_card_id}
                                    defaultChecked={
                                      card.is_default == 1 ? true : false
                                    }
                                    onChange={this.changeCard}
                                  />
                                  <label
                                    className="form-check-label row"
                                    htmlFor={card.user_card_id}
                                  >
                                    <h5 className="flow-check-tit">
                                      XXXX....{card.last_four}
                                    </h5>
                                  </label>
                                  <div className="clear-both" />
                                </div>
                              ))
                              : ""}
                        </div>

                        <h4 className="checkout-text checkout_text">
                           <input type="checkbox" 
                            checked={this.state.agreement2} 
                            onChange={this.handleChange22} className="myinput" />{t("cancellation_policy_para1")}
                        </h4>
                        <h4 className="checkout-para checkout_para">
                          {t("cancellation_policy_para2")}
                        </h4>

                        <h5 className="checkout-smalltext checkout_smalltext">
                         <span> {t("cancellation_policy_para3")} f</span>
                          <span className="bold-cls">
                            {t("cancellation_policy_para4")}
                          </span>
                        </h5>

                        <h5 className="checkout-smalltext checkout_smalltext">
                          <input type="checkbox" 
                          checked={this.state.agreement} 
                            onChange={this.handleChange2} className="myinput" /> <span>{t("i_agree_to_the")}</span> 
                          <Link to="/page/cancellation-policies" target="_blank" className="link"> Space Owner Rules,</Link>
                          <Link to="/page/cancellation-policies" target="_blank"  className="link"> {t("cancellation_policy")}, </Link> {t("and_to_the")}
                          <Link to="/page/policies" target="_blank"  className="link"> <span>{t("Refund Policy")} </span></Link>
                          
                         <span> {t("i_agree_para")}</span>
                        </h5>

                        <button
                          className="pink-btn checkout-btn"
                          onClick={this.bookingCreate}
                        >
                          {t("confirm_and_pay")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5">
                <div className="checkout-box checkout_box pricedetails-box">
                  {/* <div className="media">
                    <div className="media-body">
                      <h4 className="mt-0 captalize bold-cls bold_cls">
                        {basicDetails.host_name}{" "}
                      </h4>
                      <p className="captalize">{basicDetails.host_type}</p>

                      <h5 className="rating-sec">
                        <span className="rating-star">
                          {this.starRatingHost(basicDetails.overall_ratings)}
                        </span>
                        <span>&nbsp;{basicDetails.total_ratings} {t("reviews")}</span>
                      </h5>
                    </div>
                    <img 
                      style={{borderRadius:'9px'}}
                      src={basicDetails.host_picture}
                      alt="John Doe"
                      className="ml-3 review-img3"
                    />
                  </div> */}
                   <div className="media">
                    <div className="media-body">
                      <h4 className="mt-0 captalize bold-cls bold_cls">
                        {basicDetails.host_name}{" "}
                      </h4>
                      <div class="enter_price">
                      <p className="captalize ">{basicDetails.host_type}</p>
                      <h5 className="rating-sec">
                        <span className="rating-star rating_star">
                          {this.starRatingHost(basicDetails.overall_ratings)}
                        </span>
                        <span style={{fontSize:"13px",fontFamily:'JakartaSans-Regular2', color:"#12141D",fontWeight:"500"}}>&nbsp;{basicDetails.total_ratings} {t("reviews")}</span>
                      </h5>
                      </div>
                     

                    
                    </div>
                    {/* <img 
                      style={{borderRadius:'9px'}}
                      src={basicDetails.host_picture}
                      alt="John Doe"
                      className="ml-3 review-img3"
                    /> */}
                  </div>

                  {apiCallStatus ?
                    <>
                      <p className="overview-line overview_lines" />
                      <div className="check_card_details">
                        <h6>DETAILS</h6>
                      </div>
                      <h5 className="choosen-details res_details">
                      {/* <i className="fas fa-plus mr-3" /> */}
                      {apiResponse ? (
                        apiResponse.room + ' Space, '+ apiResponse.floor + ' Floor, '+ apiResponse.area + ' Square Meter.'
                      ) :''}
                      </h5>
                    </>
                    : ''}
                  <h5 className="choosen-details d-flex">
                    {/* <i className="far fa-calendar-alt mr-3" /> */}
                    <div className="choosen_ckin">
                      <h6>Check-in date</h6>
                      <span>{apiResponse.checkin}</span>
                    
                    </div>
                    <i className="  ml-5 mr-5" />
                    <div className="choosen_ckin">
                    <h6>Check-out date</h6>
                   <span>{apiResponse.checkout}</span> 
                    </div>
                  
                  
                  </h5>

                  <p className="overview-line overview_lines" />

                  <div className="row">
                    <div className="col-6">
                      <h5 className="choosen-details choosen_details">
                        {apiResponse.per_month} 
                        {/* {t('per_month')} */}
                        Monthly Charges
                      </h5>
                      <h5 className="choosen-details choosen_details">{t("cleaning_fee")}</h5>
                      <h5 className="choosen-details choosen_details">{t("tax_fee")}</h5>
                    </div>
                    <div className="col-6 text-right">
                      <h5 className="choosen-details choosen_details">
                        {apiResponse.total_days_price}
                      </h5>
                      <h5 className="choosen-details choosen_details">
                        {apiResponse.cleaning_fee}
                      </h5>
                      <h5 className="choosen-details choosen_details">{apiResponse.tax_price}</h5>
                    </div>
                  </div>

                  <p className="overview-line overview_lines" />
                  <div className="row">
                    <div className="col-6">
                      <h5 className="total_usd_ckout">{t("total_usd")}</h5>
                    </div>
                    <div className="col-6 text-right">
                      <h5 className="total_usd_ckout ">
                        {apiCallStatus ? apiResponse.total : this.state.total}
                      </h5>
                    </div>
                  </div>
                  <p className="top usd_para">
                    {t("quoted_fees_para")}
                  </p>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withToastManager(translate(Checkout));
