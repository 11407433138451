import React, { Component } from "react";
import SideBar from "./sideBar";

import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";

import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import { t , translate} from "react-multi-lang";

class HostTransactionHistory extends Component {
  state = {
    loading: true,
    transactions: null,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null
  };
  componentDidMount() {
    this.transactionApiCall();
  }

  transactionApiCall() {
    let items;
    api
      .postMethod("transactions_history", { skip: this.state.skipCount })
      .then(response => {
        if (response.data.success) {
          if (this.state.transactions != null) {
            items = [...this.state.transactions, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            transactions: items,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadingStatus: true
          });
        }
      });
  }

  loadMore = event => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.transactionApiCall();
  };
  render() {
    const { loading, transactions, loadingContent, loadingStatus } = this.state;
    return (
      <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                        <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Transaction</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                  <div className="row pt-3">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="transactions">
                      <div className="tab-content prov-tab-content">
                        <div className="tab-pane active" id="home" role="tabpanel">
                          <div className="clear-both" />

                          <div className="trans-table table-responsive">
                            <table className="table table_trans">
                              <thead>
                              <tr>
                                  <th className="head1" scope="col" colspan="4">Transaction Histroy</th>
                                
                                </tr>
                                <tr className="head2">
                                  <th scope="col">{t("date")}</th>
                                  <th scope="col">{t("payment_mode")}</th>
                                  <th scope="col">{t("details")}</th>
                                  <th scope="col">{t("your_commission")}</th>
                                  <th scope="col">{t("total_amount")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading
                                  ? ""
                                  : transactions.length > 0
                                  ? transactions.map(transaction => (
                                      <tr className="trow" key={transaction.booking_payment_id}>
                                        <td scope="row">{transaction.paid_date}</td>
                                        <td>{transaction.payment_mode}</td>
                                        <td>{transaction.message}</td>
                                        <td>
                                          {transaction.provider_amount_formatted}
                                        </td>
                                        <td>{transaction.paid_amount_formatted}</td>
                                      </tr>
                                    ))
                                  : "No data found"}
                                {loadingStatus ? "" : loadingContent}
                              </tbody>
                            </table>
                            {loading ? (
                              ""
                            ) : transactions.length > 0 ? (
                              <Link to={"#"} onClick={this.loadMore} className="btn btn-info" style={{backgroundColor:'#1DD05D',borderColor:'#1DD05D'}}>
                                {t("load_more")}
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          ...
                        </div>
                        <div
                          className="tab-pane"
                          id="contact"
                          role="tabpanel"
                          aria-labelledby="contact-tab"
                        >
                          ...
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        
      </div>
    );
  }
}

export default translate(HostTransactionHistory);
