export const HOSTS_UPLOAD_FILES_START = "HOSTS_UPLOAD_FILES_START";
export const HOSTS_UPLOAD_FILES_SUCCESS = "HOSTS_UPLOAD_FILES_SUCCESS";
export const HOSTS_UPLOAD_FILES_FAILURE = "HOSTS_UPLOAD_FILES_FAILURE";

export const HOSTS_REMOVE_FILES_START = "HOSTS_REMOVE_FILES_START";
export const HOSTS_REMOVE_FILES_SUCCESS = "HOSTS_REMOVE_FILES_SUCCESS";
export const HOSTS_REMOVE_FILES_FAILURE = "HOSTS_REMOVE_FILES_FAILURE";

export const HOSTS_SAVE_START = "HOSTS_SAVE_START";
export const HOSTS_SAVE_SUCCESS = "HOSTS_SAVE_SUCCESS";
export const HOSTS_SAVE_FAILURE = "HOSTS_SAVE_FAILURE";
export const HOSTS_SAVE_STATUS = "HOSTS_SAVE_STATUS";

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_SUB_CATEGORIES_START = "FETCH_SUB_CATEGORIES_START";
export const FETCH_SUB_CATEGORIES_SUCCESS = "FETCH_SUB_CATEGORIES_SUCCESS";
export const FETCH_SUB_CATEGORIES_FAILURE = "FETCH_SUB_CATEGORIES_FAILURE";

export const HOSTS_AMENITIES_START = "HOSTS_AMENITIES_START";
export const HOSTS_AMENITIES_SUCCESS = "HOSTS_AMENITIES_SUCCESS";
export const HOSTS_AMENITIES_FAILURE = "HOSTS_AMENITIES_FAILURE";

export const HOSTS_AMENITIES_SAVE_START = "HOSTS_AMENITIES_SAVE_START";
export const HOSTS_AMENITIES_SAVE_SUCCESS = "HOSTS_AMENITIES_SAVE_SUCCESS";
export const HOSTS_AMENITIES_SAVE_FAILURE = "HOSTS_AMENITIES_SAVE_FAILURE";

export const DRAFT_HOST_DETAILS = "DRAFT_HOST_DETAILS";

export const FETCH_SERVICE_LOCATIONS_START = "FETCH_SERVICE_LOCATIONS_START";
export const FETCH_SERVICE_LOCATIONS_SUCCESS = "FETCH_SERVICE_LOCATIONS_SUCCESS";
export const FETCH_SERVICE_LOCATIONS_FAILURE = "FETCH_SERVICE_LOCATIONS_FAILURE";

export const FETCH_HOST_DETAILS_START = "FETCH_HOST_DETAILS_START";
export const FETCH_HOST_DETAILS_SUCCESS = "FETCH_HOST_DETAILS_SUCCESS";
export const FETCH_HOST_DETAILS_FAILURE = "FETCH_HOST_DETAILS_FAILURE";
