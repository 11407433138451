import React, { Component } from "react";
import SideBar from "./sideBar";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import Loader from "../../Helper/Loader";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import { translate, t } from "react-multi-lang";
import LeftSideBar from "../HostLayout/sideBar";
import { ProSidebarProvider } from 'react-pro-sidebar';

class HostBookingManagement extends HostHelper {
    state = {
        loading: true,
        bookings: null,
        skipCount: 0,
        loadingStatus: true,
        loadingContent: null
    };
    componentDidMount() {
        this.boookingManagementApiCall();
    }

    boookingManagementApiCall() {
        let items;
        api.postMethod("bookings_history", { skip: this.state.skipCount }).then(
            response => {
                if (response.data.success === true) {
                    if (this.state.bookings != null) {
                        items = [...this.state.bookings, ...response.data.data];
                    } else {
                        items = [...response.data.data];
                    }
                    this.setState({
                        bookings: items,
                        loading: false,
                        skipCount:
                            response.data.data.length + this.state.skipCount,
                        loadingStatus: true
                    });
                }
            }
        );
    }

    loadMore = event => {
        this.setState({ loadingStatus: false, loadingContent: "Loading..." });
        event.preventDefault();

        this.boookingManagementApiCall();
    };

    handleCancel = async (event, booking) => {
        event.preventDefault();
        api.postMethod("bookings_cancel", {
            booking_id: booking.booking_id
        }).then(response => {
            if (response.data.success) {
                ToastDemo(
                    this.props.toastManager,
                    response.data.message,
                    "success"
                );
            } else {
                ToastDemo(
                    this.props.toastManager,
                    response.data.error,
                    "error"
                );
            }
        });
    };

    render() {
        let load = new Loader();
        const { loading, bookings, loadingContent, loadingStatus } = this.state;
        return (
            <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 inner-container" >
                        <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Booking</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                  <div className="row pt-3">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                    <div className="trans-table table-responsive">
                                        <table className="cmn-table table booking-table table_trans">
                                            <thead>
                                                <tr>
                                                  <th className="head1" scope="col" colspan="8">Booking Management</th>
                                                
                                                </tr>
                                                <tr className="head2">
                                                    <th scope="col">#</th>
                                                    <th scope="col">{t("user")}</th>
                                                    <th scope="col">
                                                        {t("listing_or_checkIn")}
                                                    </th>
                                                    {/* <th scope="col">Check In - Out</th> */}
                                                    <th scope="col">{t("nights")}</th>
                                                    <th scope="col">{t("guest")}</th>
                                                    <th scope="col">{t("price")}</th>
                                                    <th scope="col">{t("status")}</th>
                                                    <th scope="col">{t("action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading
                                                    ? load.propertyLoader()
                                                    : bookings.length > 0
                                                    ? bookings.map(
                                                          (booking, index) => (
                                                              <tr
                                                                  key={
                                                                      booking.booking_id
                                                                  }
                                                              >
                                                                  <td>
                                                                      {index + 1}
                                                                  </td>
                                                                  <td>
                                                                      <div>
                                                                          <div className="book-img">
                                                                              <Link
                                                                                  to={`/profile/${booking.user_id}`}
                                                                              >
                                                                                  <img
                                                                                      src={
                                                                                          booking.user_picture
                                                                                      }
                                                                                  />
                                                                              </Link>
                                                                          </div>
                                                                          <div className="book-prof-name">
                                                                              {
                                                                                  booking.user_name
                                                                              }

                                                                              <Link
                                                                                  to={`/host/booking-details/${booking.booking_id}`}
                                                                              >
                                                                                  <p>
                                                                                      #
                                                                                      {
                                                                                          booking.booking_unique_id
                                                                                      }
                                                                                  </p>
                                                                              </Link>
                                                                          </div>
                                                                      </div>
                                                                  </td>
                                                                  <td>
                                                                      <div>
                                                                          <div className="">
                                                                              <h6 className="host-tit">
                                                                                  {
                                                                                      booking.host_name
                                                                                  }
                                                                              </h6>
                                                                              <p>
                                                                                  {
                                                                                      booking.checkin
                                                                                  }{" "}
                                                                                  -{" "}
                                                                                  {
                                                                                      booking.checkout
                                                                                  }
                                                                              </p>
                                                                          </div>
                                                                      </div>
                                                                  </td>

                                                                  <td>
                                                                      {
                                                                          booking.total_days
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          booking.total_guests
                                                                      }
                                                                  </td>

                                                                  <td>
                                                                      <span>
                                                                          {
                                                                              booking.total_formatted
                                                                          }
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <span className="badge status-btn badge-success">
                                                                          {
                                                                              booking.status_text
                                                                          }
                                                                      </span>
                                                                  </td>
                                                                  <td>
                                                                      <ul className="action-menu nav">
                                                                          <li className="nav-item dropdown">
                                                                              <a
                                                                                  className="dropdown-toggle action-menu-icon"
                                                                                  href="#"
                                                                                  id="navbarDropdown"
                                                                                  role="button"
                                                                                  data-toggle="dropdown"
                                                                                  aria-haspopup="true"
                                                                                  aria-expanded="false"
                                                                              >
                                                                                  <img
                                                                                      src="../assets/img/menu.svg"
                                                                                      alt="menu"
                                                                                  />
                                                                              </a>

                                                                              <div
                                                                                  className="dropdown-menu dropdown-menu-right animate slideIn"
                                                                                  aria-labelledby="navbarDropdown"
                                                                              >
                                                                                  <Link
                                                                                      className="dropdown-item"
                                                                                      to={`/host/booking-details/${booking.booking_id}`}
                                                                                  >
                                                                                      <i className="fas fa-eye" />{" "}
                                                                                      {t("view_booking")}
                                                                                  </Link>
                                                                                  {booking
                                                                                      .buttons
                                                                                      .cancel_btn_status ? (
                                                                                      <Link
                                                                                          className="dropdown-item"
                                                                                          to="#"
                                                                                          onClick={e => {
                                                                                              if (
                                                                                                  window.confirm(
                                                                                                      "Are you sure you want to cancel booking?"
                                                                                                  )
                                                                                              )
                                                                                                  this.handleCancel(
                                                                                                      e,
                                                                                                      this
                                                                                                          .boookingManagementApiCall
                                                                                                  );
                                                                                          }}
                                                                                      >
                                                                                          <i className="fas fa-trash-alt" />{" "}
                                                                                          {t("cancel")}
                                                                                      </Link>
                                                                                  ) : (
                                                                                      ""
                                                                                  )}
                                                                              </div>
                                                                          </li>
                                                                      </ul>
                                                                  </td>
                                                              </tr>
                                                          )
                                                      )
                                                    : t("no_data_found")}
                                                {loadingStatus
                                                    ? ""
                                                    : loadingContent}
                                            </tbody>
                                        </table>
                                        {loading ? (
                                            ""
                                        ) : bookings.length > 0 ? (
                                            <Link to={"#"} onClick={this.loadMore}>
                                                {t("load_more")}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                            </div>
                          </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default withToastManager(translate(HostBookingManagement));
