import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";

class TripHostSection extends Helper {
    state = {};
    render() {
        const { providerDetails, singleTripDetails } = this.props;

        return (
            // <div id="host">
            //     <div className="media ">
            //         <div className="media-body">
            //             <h1 className="section-head-green host-bottom">
            //                 {t("hosted_by")} {providerDetails.provider_name}
            //             </h1>
            //             <h4 className="host-text">
            //                 {providerDetails.full_address} Joined in{" "}
            //                 {providerDetails.joined}
            //             </h4>
            //             <h4 className="host-text mb-0">
            //                 <span>
            //                     {this.starRatingHost(
            //                         providerDetails.overall_ratings,
            //                         13
            //                     )}
            //                 </span>
            //             </h4>
            //             <h4 className="host-text mb-0">
            //                 <span>{providerDetails.total_reviews} {t("reviews")}</span>
            //                 &nbsp;&nbsp;
                           
            //             </h4>
            //         </div>
            //         <Link
            //             to={`/provider-profile/${providerDetails.provider_id}`}
            //             target="_blank"
            //         >
            //             <img
            //                 src={providerDetails.picture}
            //                 alt={providerDetails.provider_name}
            //                 className="ml-3 rounded-circle review-img"
            //             />
            //         </Link>
                    
            //     </div>

            //     <h4 className="host-text">{providerDetails.description}</h4>

            //     <div className="host-details">
            //         {providerDetails.language
            //             ? `
            //             <h4>
            //                 Languages:
            //                 <span className="medium-cls">
            //                     {providerDetails.language}
            //                 </span>
            //             </h4>
            //             `
            //             : ""}
            //     </div>
            //     {localStorage.getItem("userLoginStatus") ? (
            //         <Link
            //             to={{
            //                 pathname: "/single-trip/chat",
            //                 state: {
            //                     host_details: singleTripDetails.basic_details,
            //                     provider_details:
            //                         singleTripDetails.provider_details,
            //                     pricing_details:
            //                         singleTripDetails.pricing_details,
            //                     page: "singletrip"
            //                 }
            //             }}
            //             className="green-outline-btn btn-small"
            //         >
            //             {t("contact_host")}
            //         </Link>
            //     ) : (
            //         ""
            //     )}

            //     <h1 className="section-head-green host-bottom">
            //                 About  myself
            //     </h1>
            //     <h4 className="host-text">
                   
            //             {t("trip_host_para1",{siteName: configuration.get(
            //         "configData.site_name")})}
                   
            //         {t("trip_host_para2",{siteName: configuration.get(
            //         "configData.site_name")})}
            //     </h4>
            //     <p className="overview-line" />
            // </div>

            






            <div id="host" className="">
                {/* ------------about_my_self_section--------- */}
           {/* <div className="about_host_self">
            <h1 className="section-head-green host-bottom">
                        About  myself
            </h1>
            <h4 className="host-text">
               
                    {t("trip_host_para1",{siteName: configuration.get(
                "configData.site_name")})}
               
                {t("trip_host_para2",{siteName: configuration.get(
                "configData.site_name")})}
            </h4>
            </div> */}
            {/* <div className="media"> */}
            <div className="property_owner_card br-8">
                <div>
                <p>Listed by property owner</p>
                </div>
               
                <div className="trip_host_sec">
              
                <div className="trip_host_img_sec">
               
                <Link
                    to={`/provider-profile/${providerDetails.provider_id}`}
                    target="_blank"
                >
                    <img
                        src={providerDetails.picture}
                        alt={providerDetails.provider_name}
                        className="ml-3 rounded-circle review-img"
                    />
                </Link>
                </div> 

                <div className="media-body media_body">
                    <h1 className="section-head-green host-bottom">
                        {t("hosted_by")} {providerDetails.provider_name}
                    </h1>
                    <h4 className="host-text">
                        {providerDetails.full_address} Joined in{" "}
                        {providerDetails.joined}
                    </h4>
                    <h4 className="host-text mb-0">
                        <span>
                            {this.starRatingHost(
                                providerDetails.overall_ratings,
                                13
                            )}
                        </span>
                    </h4>
                    <div class="property_owner_btn">
                    <div> 
                    <h4 className="host-text mb-0">
                        <span>{providerDetails.total_reviews} {t("reviews")}</span>
                        &nbsp;&nbsp;
                       
                    </h4>
                    </div>
                    <div className="property_button">
                         {localStorage.getItem("userLoginStatus") ? (
                      <Link
                        to={{
                          pathname: "/single-trip/chat",
                          state: {
                            host_details: singleTripDetails.basic_details,
                            provider_details:
                              singleTripDetails.provider_details,
                            pricing_details: singleTripDetails.pricing_details,
                            page: "singletrip",
                          },
                        }}
                        className="btn btn1"
                      >
                        Ask a question
                      </Link>
                    ) : (
                      ""
                    )}

                        <button className="btn btn1"> <i class="fa-regular fa-circle-question"></i>&nbsp;&nbsp;Get more info</button>
                    </div>
                    </div>
                </div>
               
            </div>
            </div>
            <h4 className="host-text ">{providerDetails.description}</h4>

            <div className="host-details">
                {providerDetails.language
                    ? `
                    <h4>
                        Languages:
                        <span className="medium-cls">
                            {providerDetails.language}
                        </span>
                    </h4>
                    `
                    : ""}
            </div>
            {/*{localStorage.getItem("userLoginStatus") ? (
                <Link
                    to={{
                        pathname: "/single-trip/chat",
                        state: {
                            host_details: singleTripDetails.basic_details,
                            provider_details:
                                singleTripDetails.provider_details,
                            pricing_details:
                                singleTripDetails.pricing_details,
                            page: "singletrip"
                        }
                    }}
                    className="green-outline-btn btn-small"
                >
                    {t("contact_host")}
                </Link>
            ) : (
                ""
            )}*/}
{/*         
            <p className="overview-line" /> */}
        </div>
            
        );
    }
}

export default translate(TripHostSection);
