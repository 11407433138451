import React, { Component } from "react";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Search, Grid, Header, Segment } from "semantic-ui-react";
import { translate, t } from "react-multi-lang";

import LeftSideBar from "../HostLayout/sideBar";
import { ProSidebarProvider } from 'react-pro-sidebar';

import _ from "lodash";
const DATE_OPTIONS = {
  year: "numeric",
  month: "short",
  day: "numeric"
};

class HostListingManagement extends Component {
  state = {
    listings: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    isLoading: false,
    results: [],
    value: ""
  };
  componentDidMount() {
    this.listingApiCall();
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.message,
        "success"
      );
    }
  }

  handleResultSelect = (e, { result }) =>
    this.setState({ value: result.host_name });

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          isLoading: false,
          results: [],
          value: ""
        });

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.host_name);

      this.setState({
        isLoading: false,
        results: _.filter(this.state.listings, isMatch)
      });
    }, 300);
  };

  hostStatusChange = (event, host_id) => {
    event.preventDefault();

    api.postMethod("hosts_status", { host_id: host_id }).then(response => {
      if (response.data.success) {
        this.setState({ loading: true, skipCount: 0, listings: null });
        this.listingApiCall();
        ToastDemo(this.props.toastManager, response.data.message, "success");
      }
    });
  };

  deleteHost = (event, host_id) => {
    event.preventDefault();
    api.postMethod("hosts_delete", { host_id: host_id }).then(response => {
      if (response.data.success) {
        this.setState({ loading: true, skipCount: 0, listings: null });
        this.listingApiCall();
        ToastDemo(this.props.toastManager, response.data.message, "success");
      }
    });
  };

  listingApiCall() {
    let items;
    api
      .postMethod("hosts_index", { skip: this.state.skipCount })
      .then(response => {
        if (response.data.success) {
          if (this.state.listings != null) {
            items = [...this.state.listings, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            listings: items,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadingStatus: true
          });
        }
      });
  }
  loadMore = event => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.listingApiCall();
  };

  completionTextColor = complete_percentage => {
    if (complete_percentage <= 0) {
      return "text-danger";
    } else if (complete_percentage > 0 && complete_percentage <= 25) {
      return "text-warning";
    } else if (complete_percentage > 25 && complete_percentage <= 75) {
      return "text-info";
    } else if (complete_percentage > 75 && complete_percentage <= 100) {
      return "text-success";
    } else {
      return "display-hide";
    }
  };
  render() {
    const { loading, loadingContent, loadingStatus } = this.state;
    let listings;
    const { isLoading, value, results } = this.state;
    if (results.length > 0) {
      listings = results;
    } else {
      listings = this.state.listings;
    }
    return (
      <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 inner-container" >
                        <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Listings</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                  <div className="row pt-3">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="rooms top-bottom-spacing custom-listing">
                        <div className="rooms-head">
                          <div className="room-filter input-group mb-3">
                            <Grid>
                              <Grid.Column width={6}>
                                <Search
                                
                                  loading={isLoading}
                                  onResultSelect={this.handleResultSelect}
                                  onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                    leading: true
                                  })}
                                  results={results}
                                  value={value}
                                  {...this.props}
                                  placeholder={t('search')}
                                />
                              </Grid.Column>
                            </Grid>
                          </div>
                        </div>
                        <div className="room-content">
                          <div className="trans-table rooms-table table-responsive">

                           {/* <table className="cmn-table table">*/}
                            <table className="cmn-table table subs-table table_trans">
  
                              <thead>

                                <tr>
                                    <th className="head1" scope="col" colspan="7">Saved Listings</th>
                                                
                                </tr>
                                <tr className="head2">
                                  <th scope="col" className="t1">{t("s_no")}</th>
                                  <th scope="col" className="t2">{t("listing")}</th>
                                  {/* <th scope="col">
                                    {t("instant_book")} <i className="fas fa-chevron-down" />
                                  </th> */}
                                  <th scope="col" className="t3">
                                    {t("host_type")} 
                                  </th>

                                  <th scope="col" className="t3">
                                    {t("location")} 
                                  </th>
                                  <th scope="col" className="t2">
                                    {t("status")} 
                                  </th>
                                  <th scope="col" className="t3">
                                    {t("last_modified")} {/*<img src="../assets/img/tbl-arrow.png" alt="arrow"  className="tbl-arrow"/>*/}
                                  </th>
                                  <th scope="col" className="t3">{t("action")}</th>
                                </tr>
                              </thead>
                              <tbody>

                                {loading
                                  ? ""
                                  : listings.length > 0
                                  ? listings.map((listing, index) => (
                                      <tr key={listing.host_id} className="">
                                        <td className="t1">{index + 1}</td>
                                        <td className="t2">
                                          <div>
                                            <a href="#" className="room-list-img">
                                              <img src={listing.host_picture} alt={listing.host_name} />
                                            </a>
                                            <div className="room-list-content">
                                              <Link
                                                to={`/host/single/${listing.host_id}`}
                                                className="room-list-tit"
                                              >
                                                {listing.host_name}
                                              </Link>
                                              <p>
                                                <small
                                                  className={this.completionTextColor(
                                                    listing.complete_percentage
                                                  )}
                                                >
                                                  {listing.complete_percentage} {t("%_completed")}
                                                </small>
                                              </p>
                                            </div>
                                          </div>
                                        </td >
                                        {/* <td className="text-center">
                                          <a
                                            href="#"
                                            className="light-img"
                                            title="Instant Book"
                                          >
                                            <img src="../assets/img/light.svg" />
                                          </a>
                                        </td> */}

                                        <td className="t3">{listing.host_type}</td>
                                        <td className="t3">{listing.host_location} {t("Milan")}</td>
                                        <td className="t2">
                                          {listing.admin_host_status ? (
                                            <small className="text-success">
                                              <i className="fas fa-thumbs-up" /> {t("admin_approved")}
                                            </small>
                                          ) : (
                                            <small className="text-danger">
                                              <i className="fas fa-thumbs-down" /> {t("waiting_for_approval")}
                                            </small>
                                          )}

                                          <div className="clearfix" />

                                          {listing.provider_host_status ? (
                                            <small className="text-success">
                                              <i className="fas fa-check" /> {t("visible_for_user")}
                                            </small>
                                          ) : (
                                            <small className="text-danger">
                                              <i className="far fa-times-circle" /> {t("hided_for_user")}
                                            </small>
                                          )}

                                          <div className="clearfix" />

                                          {listing.provider_host_status ? (
                                            <small className="text-success">
                                              <i className="fa fa-eye" /> {t("details_verified")}
                                            </small>
                                          ) : (
                                            <small className="text-danger">
                                              <i className="far fa-eye-slash" /> {t("waiting_for_verification")}
                                            </small>
                                          )}
                                        </td>
                                        <td className="t3">
                                          {listing.updated_at
                                            ? new Date(
                                                listing.updated_at
                                              ).toLocaleDateString("en-US", DATE_OPTIONS)
                                            : "-"}
                                        </td>
                                        <td className="t3">
                                          <ul className="action-menu nav">
                                            <li className="nav-item dropdown">
                                              <a
                                                className="dropdown-toggle action-menu-icon"
                                                href="#"
                                                id="navbarDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <img src="../assets/img/tbl-dots58.png" alt="menu" />
                                              </a>
                                              <div
                                                className="dropdown-menu dropdown-menu-right animate slideIn"
                                                aria-labelledby="navbarDropdown"
                                              >
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/host/edit-listing/${
                                                    listing.host_id
                                                  }`}
                                                >
                                                  <i className="far fa-edit" /> {t("edit")}
                                                </Link>
                                                {listing.complete_percentage == 100 ? (
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    onClick={event =>
                                                      window.confirm("Are you sure?") &&
                                                      this.hostStatusChange(
                                                        event,
                                                        listing.host_id
                                                      )
                                                    }
                                                  >
                                                    <i className="far fa-copy" />{" "}
                                                    {listing.provider_host_status != 1
                                                      ? "Enable Visibility"
                                                      : "Disable Visibility"}{" "}
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/host/gallery/${listing.host_id}`}
                                                >
                                                  <i className="far fa-image" /> {t("gallery")}
                                                </Link>
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/host/availability/${
                                                    listing.host_id
                                                  }`}
                                                >
                                                  <i className="far fa-image" />{" "}
                                                  {t("availability")}
                                                </Link>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={event =>
                                                    window.confirm("Are you sure?") &&
                                                    this.deleteHost(event, listing.host_id)
                                                  }
                                                >
                                                  <i className="fas fa-trash-alt" /> {t("delete")}
                                                </a>
                                                {listing.complete_percentage >= 75 ? (
                                                  <Link
                                                    className="dropdown-item"
                                                    to={`/host/single/${listing.host_id}`}
                                                  >
                                                    <i className="fas fa-eye" /> {t("preview")}
                                                  </Link>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                    ))
                                  : t("no_data_found")}
                                {loadingStatus ? "" : loadingContent}
                              </tbody>
                            </table>
                            {loading ? (
                              ""
                            ) : listings.length > 0 ? (
                              <Link to={"#"} onClick={this.loadMore}>
                                Load More
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
              </div>
               </div>
              </div>
          </div>
      </div>
    );
  }
}

export default withToastManager(translate(HostListingManagement));
