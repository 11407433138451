import React from "react";
import { DatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
// import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";
import api from "../../../Environment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";

import dayjs from "dayjs";

// import {
//   DateRangePicker,
//   SingleDatePicker,
//   DayPickerRangeController
// } from "react-dates";
import "react-dates/initialize";

const $ = window.$;

class TripBookingForm extends Helper {
    state = {
        checkin: null,
        host_id: null,
        loading: true,
        total_days_price: null,
        basicDetails: null,
        apiResponse: null,
        apiCallStatus: false,
        providerDetails: null,
        storing_details:''
        

    };

    componentDidMount() {
        this.setState({
            host_id: this.props.basicDetails.host_id,
            basicDetails: this.props.basicDetails,
            providerDetails: this.props.providerDetails
        });
    }

    priceCalcultor = event => {
        event.preventDefault();
        // const response = this.priceCalculatorApiCall(inputDate);
        // console.log("REposne from call", response);
        console.log($("#guest-mobile-btn").dropdown("toggle"));
        this.priceCal();
    };

    priceCal = async () => {
        await api
            .postMethod("host_price_calculator", this.state)
            .then(response => {
                console.log("response", response);
                if (response.data.success) {
                    this.setState({
                        loading: false,
                        total_days_price: response.data.data.total_days_price,
                        apiResponse: response.data.data,
                        apiCallStatus: true
                    });
                } else {
                    ToastDemo(
                        this.props.toastManager,
                        response.data.error,
                        "error"
                    );
                    return false;
                }
            });
    };

    bookNow = async event => {
        event.preventDefault();
        if (!localStorage.getItem("userLoginStatus")) {
            ToastDemo(
                this.props.toastManager,
                "Please login and book your Space",
                "error"
            );
        } else {
            await this.priceCal();
            if (this.state.apiCallStatus) {
                if (
                    this.state.storing_details !== '' &&
                    this.state.checkin != null &&
                    this.state.apiResponse != null
                ) {
                    console.log("After API reponse");
                    this.props.history.push("/checkout", this.state);
                } else {
                    ToastDemo(
                        this.props.toastManager,
                        "Please select Guest and Date, Try again",
                        "error"
                    );
                }
            } else {
                // Do nothing
                console.log("Else ");
            }
        }
        console.log("Book now Clicked");
    };

    convert(str) {
        let date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return "Date", [date.getFullYear(), mnth, day].join("-");
    }

    onChangeDate = (date) => {
        const checkin = this.convert(date);
        // const checkout = this.convert(args[1]);
        this.setState({ checkin: checkin });
    };

    handleChange = ({ currentTarget: input }) => {
        this.setState({ storing_details:input.value });
    };

    render() {
        const { basicDetails, status } = this.props;

        return (
            <div className="col-xl-5 padding-left-sm relative">
                <div className="pricedetails-box pricedetails_box ">
                    <p>Rent price</p>
                    <h3 className="home-price-details home_price_details ">
                        {this.state.loading
                            ? basicDetails.per_month_formatted
                            : this.state.total_days_price}
                        {/* <small> {t("per_month")}</small> */}
                        <small style={{color:"gray",}}> /month</small>
                    </h3>
                    <div className="rating-sec">
                        <span className="rating-star">
                            {this.starRatingHost(basicDetails.overall_ratings)}
                        </span>
                        <span>&nbsp;{basicDetails.total_ratings} {t("reviews")}</span>
                    </div>

                    <p className="overview-line1 overview_line1" />

                    <div className="form-group">
                        <label className="medium-cls medium_cls">{t("checkin_date")}</label>
                        <div className=" input_group">
                            {/* <DateRangePicker /> */}
                            <DatePicker
                                initialDate={dayjs(new Date())}
                                onChange={this.onChangeDate}
                                className="form-control form-control-lg input_border"
                            // dateFormat="YYYY/MM/DD"
                            />
                        </div>
                    </div>
                    <div className="form-group ">
                        <label className="medium-cls medium_cls">{t("what_are_you_sorting")}</label>
                        <div className="input-group input_group input_2">
                            <input
                                type="text"
                                id="storing_details"
                                name="storing_details"
                                className="form-control  form_control storing_details"
                                placeholder={t("what_are_you_sorting")}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <button
                        className="pink-btn btn-block book-btn"
                        disabled={status === "host" ? true : false}
                        onClick={this.bookNow}
                    >
                       <img className="img-fluid" srcSet="../../../assets/img/map2.png" /> {status === "host" ? "You can't BOOK" : "BOOK"}
                    </button>
                    
                    {/* <p>Note: Only the items that are mentioned above would be eligible for coverage under the protection plan</p> */}

                    {/* <h5 className="small-text">you won’t be charged yet</h5>
                    <p className="overview-line" /> */}
                    {/* <div className="media top1">
                        <div className="media-body">
                            <h4 className="home-info">
                                {t("booking_form_para1")}
                            </h4>
                            <p className="home-info-text">
                                {t("booking_form_para2")}
                            </p>
                        </div>
                        <img
                            src={
                                window.location.origin + "/assets/img/light.gif"
                            }
                            className="align-self-center ml-3 rounded-circle review-img1"
                            alt="you won’t be charged yet"
                        />
                    </div> */}
                </div>
               
            </div>
        );
    }
}

export default withToastManager(translate(TripBookingForm));
