import React, { Component } from "react";
import CalForm from "../Helper/calForm";
import FaqSection from "../Helper/faqSection";
import ToastDemo from "../Helper/toaster";
import About from "../Home/AboutUs";
import BecomeHost from "../Home/BecomeHost";

import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";

class BecomeAHost extends Component {
  state = {};
  componentDidMount() {
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.error,
        "error"
      );
    }
  }
  render() {
    return (
      <div>
      {/* <div className="site-content host_baner_wrp mt-5"> */}
      <div className="host_bg">
      <div className=" site_content_2 host_baner_wrp mt-2">
        <img className="host_baner" src="../../../assets/img/host_banner3.png" />
        {/* <img className="host_baner2" src="../../../assets/img/shade.png" /> */}
        <div className="host_baner_text  p_6 text-white ">
          <h1>Make quick cash as a SpaceEazy host!</h1>
          {/* <h1>Spaceazy Host.</h1> */}
          <p className="mb-0 mt-3 home_banner_para"> A great platform to buy, sell, or even rent your <br /> properties without any commisions. </p>
        </div>
      </div>
        {/*<div
          
        >
          <div className="host-banner-sec-overlay">
            <div className="site-content">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-5 host-banner-aligncenter">
                  <div className="">
                    <h2 className="host-banner-subhead">{t("host_on_site",{siteName: configuration.get(
                    "configData.site_name")})}</h2>
                    <h1 className="host-banner-head">
                      {t("earn_money_as_an_site_host",{siteName: configuration.get(
                    "configData.site_name")})}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="host-details-sec banner-mobileview">
          <div className="host-details-head">
            <h2>{t("find_out_what_top_hosts_earn_in_your_area")}</h2>
          </div>
          <form className="host">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder={t("bangaluru")}
              />
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>4 {t("guests")}</option>
                <option>1 {t("guests")}</option>
                <option>2 {t("guests")}</option>
                <option>3 {t("guests")}</option>
                <option>4 {t("guests")}</option>
              </select>
            </div>
            <div className="form-group">
              <select className="form-control">
                <option>{t("entire_palce")}</option>
                <option>{t("private_room")}</option>
                <option>{t("shared_room")}</option>
              </select>
            </div>
            <div>
              <h1 className="amount">₹18,296</h1>
              <h4 className="amount-subhead">{t("per_month")}</h4>
            </div>
            <div className="mt-4">
              <button className="pink-btn">{t("get_started")}</button>
            </div>
          </form>
        </div>*/}

        <div className="main main_2">
          <div className="provider-features">
            <div className="site-content">
            <div className="why_host_spa">
              <div className="row main_2_row">
                <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  <div className="host_spacezy">
                  {/* <h2 className="features-head">Why host on Spaceazy?</h2> */}
                  <div className="host_spa_img">
                  <img src="../assets/img/12.png" alt="safety-icon" class="mr-3  review-img" />
                  </div>
                  <div className="spacezy_content">
                  <h2 className="features-head features_head">Why Choose SpacEazy?
</h2>
                  <p className="features-para features_para">
                 No matter the space you have available, we're simplifying the process of earning money from 
                sharing your space with others. As a storage space rental platform, we will connect you with 
                those who can utilize your available space, helping you quickly and easily earn passive income.
                  </p>
                  </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  {/* <h2 className="features-head">{t("you_are_in_control")}</h2> */}
                  {/* <h2 className="features-head">{t("you_are_in_control")}</h2>  */}

                  <div className="host_spacezy">
                  {/* <h2 className="features-head">Why host on Spaceazy?</h2> */}
                  <div className="host_spa_img">
                  <img src="../assets/img/13.png" alt="safety-icon" class="mr-3  review-img" />
                  </div>
                  <div className="spacezy_content">
                  <h2 className="features-head features_head">You're in Control</h2>
                  <p className="features-para features_para">
                  When using SpacEazy, you have total control over everything, including your availability, rules 
                  for renters, details on interactions, and prices you will charge for the storage space provided. 
                  You will even have the opportunity to set check-in times for those renting some room from you.
                  </p>
                  </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-4 co-xl-4">
                  {/* <h2 className="features-head">{t("We_are_there_at_every_step")}</h2> */}
                  <div className="host_spacezy">
                  {/* <h2 className="features-head">Why host on Spaceazy?</h2> */}
                  <div className="host_spa_img">
                  <img src="../assets/img/14.png" alt="safety-icon" class="mr-3 review-img" />
                  </div>
                  <div className="spacezy_content">
                  <h2 className="features-head features_head">We're there at every step</h2>
                  <p className="features-para features_para">
                  Renting your space has never been easier with help from SpacEazy. We offer convenient, time-
                    saving tools, hospitality tips, and around-the-clock support to keep you connected. You can 
                    even join the online community to stay engaged and get actionable advice from other hosts.
                  </p>
                  </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="site-content">
          <div className="host-top-bottom-spacing">
            <div className="host-section-head">
              <h1>How to be an Spaceazy host.</h1>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mob-listing-view">
               <div className="shadow-imgs">
                  <img src="../assets/img/listing1.png" className="listing-polygone" alt="Listing1" />
                  <img src="../assets/img/shadowPolygon16.png" className="" alt="Listing1" />
                </div>  
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-auto">
                <div className="media">
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>{t("create_your_listing")}</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                        It’s free and easy to create a listing on Spaceazy. Describe your space, how many guests you can accommodate, and add photos and details.
                        Our pricing tool can recommend competitive rates, 
                        but what you charge is always up to you.
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 listing-view text-right">
          
                <div className="shadow-imgs">
                  <img src="../assets/img/listing1.png" className="listing-polygone" alt="Listing1" />
                  <img src="../assets/img/shadowPolygon16.png" className="" alt="Listing1" />
                </div>
              </div>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              
                <div className="shadow-imgs">
                  <img src="../assets/img/listing2.jpg" className="listing-polygone" alt="Listing1" />
                  <img src="../assets/img/shadowPolygon16.png" className="" alt="Listing1" />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-auto">
                <div className="media">
                
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>{t("Get booked")}</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                        It’s free and easy to create a listing on Spaceazy. Describe your space, how many guests you can accommodate, and add photos and details.
                        Our pricing tool can recommend competitive rates, 
                        but what you charge is always up to you.
                      </p>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="row listings">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mob-listing-view">

             <div className="shadow-imgs">
                  <img src="../assets/img/listing3.jpg" className="listing-polygone" alt="Listing1" />
                  <img src="../assets/img/shadowPolygon16.png" className="" alt="Listing1" />
                </div>              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-auto">
                <div className="media">
             
                  <div className="media-body">
                    <div className="listings-head">
                      <h3>{t("Start earning")}</h3>
                    </div>
                    <div className="listings-para">
                      <p>
                       It’s free and easy to create a listing on Spaceazy. Describe your space,<br /> how
                       many guests you can accommodate, and add photos and<br /> details.
                       Our pricing tool can recommend competitive rates, <br />
                       but what you charge is always up to you.
                      </p>
                    </div>
                
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 listing-view text-right">
             
                <div className="shadow-imgs">
                  <img src="../assets/img/listing3.jpg" className="listing-polygone" alt="Listing1" />
                  <img src="../assets/img/shadowPolygon16.png" className="" alt="Listing1" />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="site-content">

        <div className="host-section-head host_sec3_headings host_sec3_heading2">
              <h1>How to be an Spaceazy host.</h1>
            </div>

        <div className="bec_host_row">
            <div className="row p-4">
              <div className="col-md-6 m-auto">
                <div className="spczy_host_sec">
                <div className="listings_heads">
                <h3>{t("create_your_listing")}</h3>
                </div>
                <div className="listings_para pt-md-2">
                <p>
                It’s free and easy to create a listing on Spaceazy. Describe your space, how many guests you can accommodate, and add photos and details. Our pricing tool can recommend competitive rates, but what you charge is always up to you.
                      </p>
                    </div>
                    </div>
              </div>
              <div className="col-md-6">
              <img src="../assets/img/host_sec1.png" className="img-fluid" alt="Listing1" />
              </div>
              </div>
              <div className="row p-4">
              <div className="col-md-6">
              <img src="../assets/img/host_sec2.png"  className="img-fluid" alt="Listing1" /> 
              </div>
              <div className="col-md-6 m-auto">

                <div className="listings_heads pt-3">
                  <div className="spazcy_hot_sec2 ">
                <h3>Get booked</h3>
                
                <div className="listings_para pt-md-2">
                      <p>
                      It’s free and easy to create a listing on Spaceazy. Describe your space, how many guests you can accommodate, and add photos and details. Our pricing tool can recommend competitive rates, but what you charge is always up to you.
                      </p>
                    </div>
                    </div>
                    </div>
              </div>
             </div>
             <div className="row p-4">
              <div className="col-md-6 m-auto">
              <div className="spczy_host_sec">
                <div className="listings_heads">
                <h3>Start earning</h3>
                </div>
                <div className="listings_para pt-md-2">
                      <p>
                      It’s free and easy to create a listing on Spaceazy. Describe your space, how many guests you can accommodate, and add photos and details. Our pricing tool can recommend competitive rates, but what you charge is always up to you.
                      </p>
                    </div>
                    </div>
              </div>
              <div className="col-md-6">
              <img src="../assets/img/host_sec3.png" className="img-fluid" alt="Listing1" />
              </div>
            </div>

            </div>
                      {/* how_card_2 */}

          </div>
          </div>






<div className="about_bg">
        <div className="site-content">
        {/* <About /> */}
          <div className="row p-4 young_family_row">
            <div className="col-md-6 m-auto">
             <div className="family_card">
              <div className="listings_head">
              <h3>About us</h3>
              </div>
              <div className="young_family_para pt-4">
              <p className="listings_paras">
              Spaceasyis the simplest, smartest, most sustainable way for anyone to share and fill storage space. Whatever your situation or ambition, we have spaces of all sizes for people at all stages.</p>
              <p className="listings_paras">Ultimately, Spaceasy spaces have been used for everything. Household items stored during moves and refurbishments, stock for businesses on the up, classic cars, movie props… We even stored several tonnes of chocolate!</p>
              <p className="listings_paras">Today, we have millions of square feet of space on our platform, and we’re helping Spaceasy Hosts across the USA earn millions of pounds from their unused space by connecting them with Renters who need it. We’ve got a great range of spaces to choose from, including basement, garages, room, many more.
              </p>
              </div>
              </div>
            </div>
           
            <div className="col-md-6">
              <div className="about_img">
              <img src="../assets/img/young.png" className="img-fluid" alt="Listing1" />
              </div>
            </div>
          </div>

        </div>
        </div>

    <div className="saftey_spacezy_row ">
        <div className="site-content">
          <div className="host-top-bottom-spacing">
            {/* <div className="host-section-head"> */}
            <div className="host_sec3_headings host-section-head pb-md-4">
              <h1>{t("safety_on_site",{siteName: configuration.get(
                    "configData.site_name")})}</h1>
            </div>

            <div className="row saftey_spacezy_card_row pt-md-2">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                {/* <div className="media safety"> */}
                <div className="media_safety">
                  <div className="saftey_spaaczy_card">
                  <div className="saftey_img">
                  <img
                    src="../assets/img/12.png"
                    alt="safety-icon"
                    className="mr-3  review-img"
                  />
                  
                  </div>
                  {/* <div className="media-body"> */}
                  <div className="media_body">
                    {/* <h1>₹{t("60000000_host_gurantee")}</h1> */}
                    <div className="saftey_heading">
                    <h1>$6M host guarantee</h1>
                    </div>
                    <div className="saftey_sec_para">
                    <p class="text_dark pt-2">
                    If your guests get hurt or cause property damage, our Host Protection Insurance protects you from liability claims up to a million dollars, included free for every Spaceazy host.
                    </p>
                    </div>
                  </div>
                  </div>
                </div>


              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                {/* <div className="media safety"> */}
                <div className="media_safety">
                <div className="saftey_spaaczy_card">
                <div className="saftey_img">
                  <img
                    src="../assets/img/13.png"
                    alt="safety-icon"
                    className="mr-3  review-img"
                  />
                  </div>
                  <div className="media-body">
                  <div className="saftey_heading">
                    <h1>Host Protection Insurance</h1>

                    </div>
                    <div className="saftey_sec_para">
                    <p class="text_dark">
                      {t("host_protection_insurance_text",{siteName: configuration.get(
                    "configData.site_name")})}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                {/* <div className="media safety"> */}
                <div className="media_safety">
                <div className="saftey_spaaczy_card">
                <div className="saftey_img">
                  <img
                    src="../assets/img/14.png"
                    alt="safety-icon"
                    className="mr-3  review-img"
                  />
                  </div>
                  <div className="media-body">
                  <div className="saftey_heading">
                    <h1>Spaceazy is built on trust </h1>
                     

                    </div>
                    <div className="saftey_sec_para">
                    <p class="text_dark">
                      {t("host_protection_insurance_text",{siteName: configuration.get(
                    "configData.site_name")})}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* <div className="container">
        <About />
        </div> */}
        <FaqSection />


        <div
          className=""
          
        >
          <div className="site-content">
            {/* <div className="row">
              <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <div className="host-footer-content">
                  <div>
                    <div className="host-footer-content-head">
                      <h1>Put Space to Work & Start creating your listing.</h1>
                    </div>
                    <a href="/host/login" className="pink-btn">
                      {t("get_started")}
                    </a>
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-4">
                <img className="host_footer_top" src="../../../assets/img/footer.jpg" />
                <div className="shadow-imgs host_footer_top">
                  <img src="../assets/img/footer.jpg" className="listing-polygone" alt="Listing1" />
                  <img src="../assets/img/shadowPolygon16.png" className="" alt="Listing1" />
                </div>
              </div>

            </div> */}
             {/* <BecomeHost /> */}
             <div className="custom_container">
            <div className=" pb-5 become_host_section">
                {/* <div className="green green_become_host_new_sec"  style={{
                                backgroundRepeat: 'no-repeat',
                                // borderRadius :'20px',
                                backgroundColor:'#1DD05D',
                            }}> */}
                     <div className=" green_become_host_new_sec"  style={{
                                backgroundRepeat: 'no-repeat',

                                backgroundColor:'#1DD05D',
                            }}>           
                                <div className="become_landing_section">
                    <h1 className="text-white fw_bolder pt-3 become_an_host_2">Put Space to Work & <br />
                            Start creating your listing.</h1>
                    
                    {/* <div className="_become_host_para">
                        <p>Find out how much you could earn sharing your unused <br /> 
                             spaceTurn your extra space into extra income.</p>
                    </div> */}
                    <div className="">
                        <a href={"/host"}>

                       <button className=" become_host_new_btn  px-3 py-2 mt-3 rounded border-0 become_an_host_button">{t("Become_an_Host")}</button> 
                        </a>
                    </div>
                    </div>
                </div>

            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(BecomeAHost));
