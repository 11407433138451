import React from "react";

import { Link } from "react-router-dom";

import HostHelper from "../../Helper/hostHelper";

import configuration from "react-global-configuration";

import { translate, t } from "react-multi-lang";

class HostHeader extends HostHelper {
  // constructor(props) {
  //     super(props);
  // }

  state = {
    categories: [],
    data: {},
  };

  componentDidMount() {
    // Call api function
    // this.getCategories();
  }

  render() {
    let renderDetails;
    if (!localStorage.getItem("hostLoginStatus")) {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item">
            <Link className="nav-link" to={"#"}>
              {t("help")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/host/register"}>
              {t("signup")}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={"/host/login"}>
              {t("login")}
            </Link>
          </li>
        </React.Fragment>
      );
    } else {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/add-listing"}
              id="navbardrop"
            >
              {t("add_listing")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/transaction-history"}
              id="navbardrop"
            >
              {t("transactions_history")}
            </Link>
          </li>
          
           <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/subscriptions"}
              id="navbardrop"
            >
              {t("subscriptions")}
            </Link>
          </li>
           <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/inbox"}
              id="navbardrop"
            >
            Messages
            </Link>
          </li>
           <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/subscription-history"}
              id="navbardrop"
            >
              {t("subscriptions_history")}
            </Link>
          </li>


          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/booking-management"}
              id="navbardrop"
            >
              {t("booking_managements")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              to={"/host/listing"}
              className="nav-link dropdown-toggle"
              id="navbardrop"
            >
              {t("listing_managements")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/inbox"}
              id="navbardrop"
            >
              {t("messages")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/edit-profile"}
              id="navbardrop"
            >
              {t("account_settings")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              to={"/host/logout"}
              id="navbardrop"
            >
              {t("logout")}
            </Link>
          </li>

        </React.Fragment>
      );
    }
    return (
      <div >
        <nav
          className="navbar host_nav_bar navbar-expand-xl  navbar-light white-header "
          id="navbar"
         style={{marginLeft:'6%',marginRight:'4%',background:'unset!important',borderBottom:'unset'}}>
          <Link className="navbar-brand" to={"/host/dashboard"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
                            src={window.location.origin + "/assets/img/logo.png"}

              alt={configuration.get("configData.site_name")}
            />
          </Link>
          {/* <form className="form-inline justify-content-start">
            <div className="input-group dropdown">
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon">
                  <i className="fas fa-search" />
                </span>
              </div>
              <input
                type="text"
                className="form-control form-control-lg dropdown-toggle"
                data-toggle="dropdown"
                placeholder={t("try_london")}
              />

              <div className="dropdown-menu nav-dropdown-menu">
                <h5 className="dropdown-title">{t("explore_site")}</h5>
                <Link to="#" className="grey-outline-btn">
                  {t("all")}
                </Link>
                {this.renderCategory("grey-outline-btn")}
                <h5 className="dropdown-title top">{t("recent_searches")}</h5>
                <Link to="#">
                  <div className="recent-search display-inline">
                    <div className="recent-search-left">
                      <div>
                        <i className="far fa-clock" />
                      </div>
                    </div>
                    <div className="recent-search-right">
                      <h5 className="medium-cls mt-0">{t("india")}</h5>
                      <h5 className="mb-0">
                        <span className="captalize">7 jun - 14 jun</span>
                        <span className="dot">
                          <i className="fas fa-circle" />
                        </span>
                        <span>{t("guests_infant")}</span>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </form> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <i className="fas fa-chevron-down" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav">{renderDetails}</ul>
          </div>
        </nav>
        

      </div>
    );
  }
}

export default translate(HostHeader);
