import React, { Component } from "react";
import HostProfileSideBar from "./hostProfileSideBar";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";
import { translate, t } from "react-multi-lang";
import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";


class HostPhoto extends HostHelper {
  state = {
    data: null,
    loading: true,
    inputData: [],
    imagePreviewUrl: null,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false
  };

  componentDidMount() {
    this.getHostDetails();
  }
  handleChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };
    if (input.type === "file") {
      inputData[input.name] = input.files[0];
      this.setState({ inputData });
    }
    let reader = new FileReader();
    let file = input.files[0];

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    const inputData = { ...this.state.inputData };
    inputData["name"] = this.state.data.username;
    inputData["email"] = this.state.data.email;
    this.setState({ inputData });
    api.postMethod("update_profile", inputData).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
        localStorage.setItem("host_picture", response.data.data.picture);
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };
  render() {
    const { data, loading, imagePreviewUrl } = this.state;
    return (
     <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                      <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Account Settings</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                          <HostProfileSideBar />
                          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                            <form onSubmit={this.handleSubmit}>
                              {loading ? (
                                t("loading")
                              ) : (
                                <div className="panel">
                                  <div className="panel-heading">{t("photos")} </div>
                                  <div className="panel-body account">
                                    <div className="media user-profile-sec">
                                      <img
                                        src={
                                          imagePreviewUrl ? imagePreviewUrl : data.picture
                                        }
                                        alt={data.username}
                                        className="mr-3 rounded-circle user-profile-img"
                                      />
                                      <div className="media-body">
                                        <h4>
                                          {t("host_photo_description")}
                                        </h4>
                                        <input
                                          type="file"
                                          name="picture"
                                          className="grey-outline-btn bold-cls w-100 text-center bottom"
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-8 offset-3 text-center">
                                      <button
                                        className="pink-btn btn-block"
                                        type="submit"
                                        disabled={this.state.buttonDisable}
                                      >
                                        {this.state.loadingContent != null
                                          ? this.state.loadingContent
                                          : t("submit")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(HostPhoto));
