import React, { Component } from "react";
import InputField from "../Helper/inputfield";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import { translate, t } from "react-multi-lang";

const $ = window.$;

class ForgotPassword extends Helper {
  state = {};
  handleSubmit = (event) => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("forgot_password", this.state.data)
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#login").modal("hide");
          $("#forgot-password").modal("hide");
          if (path) {
            this.props.history.push(path.pathname);
          } else {
            this.props.history.push("/home");
          }
        } else {
          this.setState({ loadingContent: null, buttonDisable: false });
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };
  render() {
    return (
      <div>
        <div className="modal fade auth" id="forgot-password">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  id="close-forgot"
                  data-dismiss="modal"
                >
                  <i className="material-icons">close</i>
                </button>
              </div>

              <div className="modal-body">
                <h1 className="section-head">{t("reset_password")}</h1>
                <p className="small-line" />
                <h4>{t("reset_password_text")}</h4>
                <form className="top1">
                  <InputField
                    type="text"
                    name="email"
                    onChange={this.handleChange}
                    placeholder={t("email")}
                    iconClassName="fas fa-envelope"
                  />

                  <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <a
                        href="#"
                        className="back-to-login close-forgot"
                        data-toggle="modal"
                        data-target="#login"
                      >
                        <i className="fas fa-chevron-left" />
                        &nbsp;&nbsp;{t("back_to_login")}
                      </a>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <button
                        className="pink-btn bottom1"
                        onClick={this.handleSubmit}
                      >
                        {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : t("send_reset_link")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withToastManager(translate(ForgotPassword));
