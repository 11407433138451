import {ProSidebarProvider, Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { Link } from 'react-router-dom';

function SideBar() {
  const { collapseSidebar } = useProSidebar();

  return (
    

    <div style={{ display: 'flex', height: '100%' }}>
            <Sidebar>
        <Menu>
         <li style={{padding: '6%'}}>
         <img style={{width:'100%'}}
              src={window.location.origin + "/assets/img/logo.png"}

              alt={configuration.get("configData.site_name")}
            /></li>
          <MenuItem component={<Link to="/host/dashboard" />}><img src="../assets/img/Group 48095663.png" alt="arrow"  className="tbl-arrow" style={{marginRight:'3%'}}/>  {t("dashboard")}</MenuItem>
          <MenuItem  component={<Link to="/host/add-listing" />}> <img src="../assets/img/Icon.png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/>Add Listing</MenuItem>

          <MenuItem  component={<Link to="/host/transaction-history" />}> <img src="../assets/img/Icon (2).png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/>{t("transactions_history")}</MenuItem>
          <MenuItem  component={<Link to="/host/subscription-history" />}><img src="../assets/img/Icon (2).png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/> {t("subscriptions_history")}</MenuItem>
          <MenuItem  component={<Link to="/host/subscriptions" />}> <img src="../assets/img/Icon (2).png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/> {t("subscriptions")}</MenuItem>
          <MenuItem  component={<Link to="/host/inbox" />}> <img src="../assets/img/Icon (3).png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/> Messages</MenuItem>

          <MenuItem  component={<Link to="/host/booking-management" />}><img src="../assets/img/Icon (1).png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/> {t("booking_managements")}</MenuItem>
          <MenuItem  component={<Link to="/host/listing" />}><img src="../assets/img/Icon (1).png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/>{t("listing_managements")}</MenuItem>
          <MenuItem  component={<Link to="/host/edit-profile" />} ><img src="../assets/img/set.png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/>{t("account_settings")}</MenuItem>
          <MenuItem  component={<Link to="/host/logout" />} ><img src="../assets/img/set.png" alt="arrow" style={{marginRight:'3%'}}  className="tbl-arrow"/>{t("logout")}</MenuItem>

 

        </Menu>
      </Sidebar>
      <main>
      </main>
      
    </div>


  );
}

export default SideBar;
