import React, { Component } from "react";

import FixedHeaderWithSection from "./Headers/FixedHeaderWithSectionLogin";

import FloatingFooter from "./Footers/FloatingFooter";

// This Layouts used in Home page Header,  static footer and content

class EightLayout extends Component {
  render() {
    return (
      <div className="wrapper">
        <FixedHeaderWithSection {...this.props.children} />

        <div>{React.cloneElement(this.props.children)}</div> 
      </div>
    );
  }
}
export default EightLayout;