import React from "react";
import api from "../../../HostEnvironment";
// import Loader from "../../Helper/Loader";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import HostHelper from "../../Helper/hostHelper";
import { Redirect } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import { ProSidebarProvider } from 'react-pro-sidebar';
import SideBar from "../HostLayout/sideBar";

import moment from "moment/moment";

let last_x_days_labels = [];
let last_x_days_data = [];
let items;
class HostDashbaord extends HostHelper {
    state = {
        datas: null,
        loading: true,
        error: null,
        loginStatus: true,
        isMonthlyRevenueChart: false
    };

    componentDidMount() {
        api.postMethod("dashboard").then(response => {
            if (response.data.success) {
                this.setState({ datas: response.data.data, loading: false });
                let totalDaysEarnings = 10;
                items = [];
                last_x_days_data = [];
                last_x_days_labels = [];
                items = this.state.datas.last_x_days_earnings.map(earn => {
                    console.log(earn.total);
                    if (earn.total > 0.0) {
                    } else {
                        totalDaysEarnings--;
                    }

                    last_x_days_labels.push(earn.date);
                    last_x_days_data.push(earn.total);
                });

                if (totalDaysEarnings > 0) {
                    // if (totalDaysEarnings == 1) {
                    //     last_x_days_labels.push("07 Aug 2019");
                    //     last_x_days_data.push("10.00");
                    // }
                    this.setState({ isMonthlyRevenueChart: true });
                }
                // alert(totalDaysEarnings);
            } else {
                this.checkLoginHost(response.data);
                ToastDemo(
                    this.props.toastManager,
                    response.data.error,
                    "error"
                );
            }
        });
    }

    render() {
        const { loading, datas, error, loginStatus } = this.state;

        const data = {
            labels: last_x_days_labels,
            datasets: [
                {
                    label: "Last 10 days",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    borderWidth: 2,
                    lineTension: 0.1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: last_x_days_data
                }
            ]
        };

        if (!loginStatus) {
            return (
                <Redirect
                    to={{
                        pathname: "/host/login",
                        state: { error: error }
                    }}
                />
            );
        }
        // let load = new Loader();
        return (
            <div className="main host-main">

           
                <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <SideBar /></ProSidebarProvider></div>
                    <div className="ml-3 inner-container" >
                        <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>{t("dashboard")}</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                            {loading ? (
                                ""
                            ) : (
                                <div className="provider-right col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="widget dashboard-block row mb-30">
                                        <div className="col-lg-3">
                                            <div className="widget-card">
                                                <p className="widget-txt">
                                                    {t("total_earnings")}
                                                </p>
                                                <div className="d-flex">
                                                    <h6 className="widget-tit ">
                                                    
                                                    <span className="widget-tit ">{datas.currency}
                                                  
                                                    {datas.total_earnings}</span></h6>
                                                    <span className="dash_card" style={{padding:'3%',fontSize:'15px',background:'rgba(29, 208, 93, 0.1)',borderRadius:'12px'}}>45</span>
                                                
                                               
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="widget-card border-primary ">
                                                <p className="widget-txt">
                                                    {t("current_month_earning")}
                                                </p>
                                                <div className="d-flex">
                                                    <h6 className="widget-tit ">
                                                    
                                                    <span className="widget-tit ">{datas.currency}
                                                  
                                                    {datas.current_month_earnings}</span></h6>
                                                    <span className="dash_card" style={{padding:'3%',fontSize:'15px',background:'rgba(29, 208, 93, 0.1)',borderRadius:'12px'}}>45</span>
                                                
                                               
                                                </div>
                                                

                                                
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="widget-card border-warning ">
                                                <p className="widget-txt">
                                                    {t("today_earnings")}
                                                </p>
                                                <div className="d-flex">
                                                    <h6 className="widget-tit ">
                                                    
                                                    <span className="widget-tit ">{datas.currency}
                                                  
                                                    {datas.today_earnings}</span></h6>
                                                    <span className="dash_card" style={{padding:'3%',fontSize:'15px',background:'rgba(29, 208, 93, 0.1)',borderRadius:'12px'}}>45</span>
                                                
                                               
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="widget-card border-danger card-shadow-danger">
                                                <p className="widget-txt">
                                                    {t("total_bookings")}
                                                </p>
                                                <div className="d-flex">
                                                    <h6 className="widget-tit ">
                                                    
                                                    <span className="widget-tit ">{datas.currency}
                                                  
                                                    {datas.total_bookings}</span></h6>
                                                    <span className="dash_card" style={{padding:'3%',fontSize:'15px',background:'rgba(29, 208, 93, 0.1)',borderRadius:'12px'}}>45</span>
                                                
                                               
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="report-sec row dashboard-block ">
                                        <div className="col-md-8">
                                            <div className="dashboard-card">
                                                <h3 className="dashboard-tit last_10_days_revenues">
                                                    {t("last_10_days_revenues")}{" "}
                                                </h3>
                                                {this.state
                                                    .isMonthlyRevenueChart ? (
                                                    <Line
                                                        data={data}
                                                        width={500}
                                                        height={350}
                                                        options={{
                                                            maintainAspectRatio: true,
                                                            responsive: true,
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        gridLines: {
                                                                            display: true
                                                                        }
                                                                    }
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        gridLines: {
                                                                            display: true
                                                                        },
                                                                        ticks: {
                                                                            beginAtZero: true
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    t("earnings_empty_message")
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="dashboard-card">
                                                <h2 className="dashboard-tit recent_t2">
                                                    {t("booking_highlights")}<br />
                                                </h2>
                                                <h3 className="dashboard-tit recent_t3">

                                                    {moment(new Date()).format(
                                                        "MMM YYYY"
                                                    )}{" "}
                                                    
                                                </h3>

                                                {loading
                                                    ? t("loading")
                                                    : datas
                                                          .current_month_highlights
                                                          .length > 0
                                                    ? datas.current_month_highlights.map(
                                                          (
                                                              current_month_highlight,
                                                              index
                                                          ) => (
                                                              <Link
                                                                  to={`/host/booking-details/${current_month_highlight.booking_id}`}
                                                              >
                                                                  <div className="highlight-box-wrap">
                                                                      {" "}
                                                                      <div className="highlight-box">
                                                                          <div className="highlight-img">
                                                                              <img
                                                                                  src={
                                                                                      current_month_highlight.user_picture
                                                                                  }
                                                                                  alt={
                                                                                      current_month_highlight.user_name
                                                                                  }
                                                                              />
                                                                          </div>
                                                                          <div className="highlight-content">
                                                                              <h5 className="highlight-tit">
                                                                                  {
                                                                                      current_month_highlight.user_name
                                                                                  }
                                                                              </h5>
                                                                              <span className=" dashboard-high-price">
                                                                                  {
                                                                                      current_month_highlight.provider_amount_formatted
                                                                                  } USD
                                                                              </span>
                                                                              <p>
                                                                                  {
                                                                                      current_month_highlight.paid_date
                                                                                  } 
                                                                              </p>
                                                                          </div>
                                                                      </div>
                                                                      <div className="clear-both" />
                                                                  </div>
                                                              </Link>
                                                          )
                                                      )
                                                    : t("no_data_found")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="row pt-3">
                           
                                <div className="provider-right col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                    <div className="widget dashboard-block row mb-30">
                                            <div className="dashboard-card">
                                            <h3 className="dashboard-tit last_10_days_revenues">Notifications </h3>
                                            <div className="" href="/host/inbox">
                                                <div className="chat-prof-img">
                                                    <img src="https://admin.spaceazy.com/uploads/users/333d343bd2d5cdccc9021616c2d66910db7149f5.jpg" alt="User" />
                                                    <div className="vl"></div>

                                                </div>
                                                
                                            
                                                <div className="pl-3 ml-3">
                                                    <h5 className="" style={{padding:'2% 13%'}}>Omar Khan <span style={{color:'#6C727F'}}>paid rent invoice ES-3258X</span></h5>
                                                    <p className="chat-date" style={{padding:'0% 13%'}}>2 mins ago</p>
                                                </div>
                                            </div>
                                            <div className="" href="/host/inbox" style={{marginTop:'7%'}}>
                                                <div className="chat-prof-img">
                                                    <img src="https://admin.spaceazy.com/placeholder.jpg" alt="User" />
                                                    <div className="vl"></div>

                                                </div>
                                                
                                            
                                                <div className="pl-3 ml-3">
                                                    <h5 className="" style={{padding:'2% 13%'}}>Khalid Binh <span style={{color:'#6C727F'}}> paid rent invoice ES-3258X</span></h5>
                                                    <p className="chat-date" style={{padding:'0% 13%'}}>Thursday, 16:54 PM</p>
                                                </div>
                                            </div>
                                            <div className="" href="/host/inbox" style={{marginTop:'7%'}}>
                                                <div className="chat-prof-img">
                                                    <img src="https://admin.spaceazy.com/uploads/users/333d343bd2d5cdccc9021616c2d66910db7149f5.jpg" alt="User" />

                                                </div>
                                                
                                            
                                                <div className="pl-3 ml-3">
                                                    <h5 className="" style={{padding:'2% 13%'}}>Omar Khan <span style={{color:'#6C727F'}}> paid rent invoice ES-3258X</span></h5>
                                                    <p className="chat-date" style={{padding:'0% 13%'}}>Wednesday, 11:55 AM</p>
                                                </div>
                                            </div>
                                            </div>

                                    </div>
                                </div>
                                <div className="provider-right col-12 col-sm-8 col-md-8 col-xl-8">
                                    <div className="widget row mb-30">
                                            <div className="m-auto" style={{width: '90%'}}>
                        

                                                <div className="trans-table table-responsive">
                                                    <table className="table table_trans">
                                                      <thead>
                                                      <tr>
                                                          <th className="head1" scope="col" colspan="5">Recent Payments</th>
                                                        
                                                        </tr>
                                                        <tr className="head2">
                                                          <th scope="col">Invoice</th>
                                                          <th scope="col">Due Date</th>
                                                          <th scope="col">Tenant</th>
                                                          <th scope="col">Amount</th>
                                                          <th scope="col">Status</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        
                                                              <tr className="trow" >
                                                                <td scope="row" style={{fontSize:'14px',fontWeight:'unset'}}>ES-32590</td>
                                                                <td style={{fontSize:'14px',fontWeight:'unset'}}>Dec 9, 2021</td>
                                                                <td style={{fontSize:'14px',fontWeight:'unset'}} className="d-flex"><div className="chat-circle2">O</div>Omar Khan</td>
                                                                <td style={{fontSize:'14px',fontWeight:'unset'}}>$1,500</td>
                                                                <td style={{fontSize:'14px',fontWeight:'unset', }}><span style={{backgroundColor:'#FDF7F7',color:'#F06565' ,padding: '8%',borderRadius: '20%'}}>Overdue</span></td>
                                                              </tr>
                                                                                              
                                                      </tbody>
                                                    </table>
                                                    
                                                  </div>
                                                </div> 
                                                 </div>
                                                </div>  
                                                </div>                
                    </div>
                    </div>
                </div>
                 
                    
                </div>
            </div>
        );
    }
}

export default withToastManager(translate(HostDashbaord));
// export default App;
