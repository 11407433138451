import {
    HOSTS_UPLOAD_FILES_START,
    HOSTS_UPLOAD_FILES_SUCCESS,
    HOSTS_UPLOAD_FILES_FAILURE,
    HOSTS_REMOVE_FILES_START,
    HOSTS_REMOVE_FILES_SUCCESS,
    HOSTS_REMOVE_FILES_FAILURE,
    HOSTS_SAVE_START,
    HOSTS_SAVE_SUCCESS,
    HOSTS_SAVE_FAILURE,
    FETCH_CATEGORIES_START,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_SUB_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_SUCCESS,
    FETCH_SUB_CATEGORIES_FAILURE,
    HOSTS_AMENITIES_START,
    HOSTS_AMENITIES_SUCCESS,
    HOSTS_AMENITIES_FAILURE,
    HOSTS_AMENITIES_SAVE_START,
    HOSTS_AMENITIES_SAVE_SUCCESS,
    HOSTS_AMENITIES_SAVE_FAILURE,
    DRAFT_HOST_DETAILS,
    FETCH_SERVICE_LOCATIONS_START,
    FETCH_SERVICE_LOCATIONS_SUCCESS,
    FETCH_SERVICE_LOCATIONS_FAILURE,
    FETCH_HOST_DETAILS_START,
    FETCH_HOST_DETAILS_SUCCESS,
    FETCH_HOST_DETAILS_FAILURE,
    HOSTS_SAVE_STATUS,
} from "../actions/ActionConstant";

const initialState = {
    hostsUploadFiles: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    hostsRemoveFiles: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    hostsSave: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    categories: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    subCategories: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    hostsAmenities: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    hostsAmenitiesSave: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
    host: {
        data: {},
        loading: true,
        error: false,
    },
    serviceLocations: {
        data: {},
        loading: true,
        error: false,
        buttonDisable: false,
        loadingButtonContent: null,
    },
};


const hostReducer = (state = initialState, action) => {
    switch (action.type) {
        case HOSTS_UPLOAD_FILES_START:
            return {
                ...state,
                hostsUploadFiles: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case HOSTS_UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                hostsUploadFiles: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case HOSTS_UPLOAD_FILES_FAILURE:
            return {
                ...state,
                hostsUploadFiles: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case HOSTS_REMOVE_FILES_START:
            return {
                ...state,
                hostsRemoveFiles: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case HOSTS_REMOVE_FILES_SUCCESS:
            return {
                ...state,
                hostsRemoveFiles: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case HOSTS_REMOVE_FILES_FAILURE:
            return {
                ...state,
                hostsRemoveFiles: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case HOSTS_SAVE_START:
            return {
                ...state,
                hostsSave: {
                    data: {
                        category_id: state.host.data.category_id,
                        host_id: state.host.data.host_id ? state.host.data.host_id : 0,
                        sub_category_id: state.host.data.sub_category_id,
                        room: state.host.data.room,
                        floor: state.host.data.floor,
                        area: state.host.data.area,
                        height: state.host.data.height,
                        width: state.host.data.width,
                        host_name: state.host.data.host_name,
                        description: state.host.data.description,
                        per_month: state.host.data.per_month,
                        service_location_id: state.host.data.service_location_id,
                        full_address: state.host.data.full_address,
                        latitude: state.host.data.latitude,
                        longitude: state.host.data.longitude,
                        host_gallery_ids: state.host.data.host_gallery_ids,
                        amenities: state.host.data.amenities,
                        street_details: state.host.data.street_details,
                        city: state.host.data.city,
                        state: state.host.data.state,
                        country: state.host.data.country,
                        zipcode: state.host.data.zipcode,
                    },
                    loading: true,
                    error: false,
                },
            };
        case HOSTS_SAVE_SUCCESS:
            return {
                ...state,
                hostsSave: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case HOSTS_SAVE_STATUS:
            return {
                ...state,
                hostsSave: {
                    // data: {},
                    loading: true,
                    error: false,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case HOSTS_SAVE_FAILURE:
            return {
                ...state,
                hostsSave: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_CATEGORIES_START:
            return {
                ...state,
                categories: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                categories: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_SUB_CATEGORIES_START:
            return {
                ...state,
                subCategories: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_SUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                subCategories: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_SUB_CATEGORIES_FAILURE:
            return {
                ...state,
                subCategories: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case HOSTS_AMENITIES_START:
            return {
                ...state,
                hostsAmenities: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case HOSTS_AMENITIES_SUCCESS:
            return {
                ...state,
                hostsAmenities: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case HOSTS_AMENITIES_FAILURE:
            return {
                ...state,
                hostsAmenities: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case HOSTS_AMENITIES_SAVE_START:
            return {
                ...state,
                hostsAmenitiesSave: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case HOSTS_AMENITIES_SAVE_SUCCESS:
            return {
                ...state,
                hostsAmenitiesSave: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case HOSTS_AMENITIES_SAVE_FAILURE:
            return {
                ...state,
                hostsAmenitiesSave: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case DRAFT_HOST_DETAILS:
            return {
                ...state,
                host: {
                    loading: false,
                    error: false,
                    data: {
                        ...state.host.data,
                        [action.name]: action.value,
                    },
                },
            };

        case FETCH_SERVICE_LOCATIONS_START:
            return {
                ...state,
                serviceLocations: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_SERVICE_LOCATIONS_SUCCESS:
            return {
                ...state,
                serviceLocations: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_SERVICE_LOCATIONS_FAILURE:
            return {
                ...state,
                serviceLocations: {
                    data: {},
                    loading: false,
                    error: action.error,
                },
            };
        case FETCH_HOST_DETAILS_START:
            return {
                ...state,
                host: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_HOST_DETAILS_SUCCESS:
            return {
                ...state,
                host: {
                    data: action.data.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_HOST_DETAILS_FAILURE:
            return {
                ...state,
                host: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        default:
            return state;
    }
};

export default hostReducer;
