import {
    HOSTS_UPLOAD_FILES_START,
    HOSTS_UPLOAD_FILES_SUCCESS,
    HOSTS_UPLOAD_FILES_FAILURE,
    HOSTS_REMOVE_FILES_START,
    HOSTS_REMOVE_FILES_SUCCESS,
    HOSTS_REMOVE_FILES_FAILURE,
    HOSTS_SAVE_START,
    HOSTS_SAVE_SUCCESS,
    HOSTS_SAVE_FAILURE,
    FETCH_CATEGORIES_START,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_SUB_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_SUCCESS,
    FETCH_SUB_CATEGORIES_FAILURE,
    HOSTS_AMENITIES_START,
    HOSTS_AMENITIES_SUCCESS,
    HOSTS_AMENITIES_FAILURE,
    HOSTS_AMENITIES_SAVE_START,
    HOSTS_AMENITIES_SAVE_SUCCESS,
    HOSTS_AMENITIES_SAVE_FAILURE,
    DRAFT_HOST_DETAILS,
    FETCH_SERVICE_LOCATIONS_START,
    FETCH_SERVICE_LOCATIONS_SUCCESS,
    FETCH_SERVICE_LOCATIONS_FAILURE,
    FETCH_HOST_DETAILS_START,
    FETCH_HOST_DETAILS_SUCCESS,
    FETCH_HOST_DETAILS_FAILURE,
    HOSTS_SAVE_STATUS,
} from "./ActionConstant";

// hosts file upload

export function hostsUploadFilesStart(data) {
    return {
        type: HOSTS_UPLOAD_FILES_START,
        data,
    };
}

export function hostsUploadFilesSuccess(data) {
    return {
        type: HOSTS_UPLOAD_FILES_SUCCESS,
        data,
    };
}

export function hostsUploadFilesFailure(error) {
    return {
        type: HOSTS_UPLOAD_FILES_FAILURE,
        error,
    };
}

// hosts file remove

export function hostsRemoveFilesStart(data) {
    return {
        type: HOSTS_REMOVE_FILES_START,
        data,
    };
}

export function hostsRemoveFilesSuccess(data) {
    return {
        type: HOSTS_REMOVE_FILES_SUCCESS,
        data,
    };
}

export function hostsRemoveFilesFailure(error) {
    return {
        type: HOSTS_REMOVE_FILES_FAILURE,
        error,
    };
}

// hosts save

export function hostsSaveStart(data) {
    return {
        type: HOSTS_SAVE_START,
        data,
    };
}

export function hostsSaveSuccess(data) {
    return {
        type: HOSTS_SAVE_SUCCESS,
        data,
    };
}

export function hostsSaveFailure(error) {
    return {
        type: HOSTS_SAVE_FAILURE,
        error,
    };
}

export function hostsSaveStatus(data) {
    return {
        type: HOSTS_SAVE_STATUS,
        data,
    };
}
// hosts categories

export function fetchCategoriesStart(data) {
    return {
        type: FETCH_CATEGORIES_START,
        data,
    };
}

export function fetchCategoriesSuccess(data) {
    return {
        type: FETCH_CATEGORIES_SUCCESS,
        data,
    };
}

export function fetchCategoriesFailure(error) {
    return {
        type: FETCH_CATEGORIES_FAILURE,
        error,
    };
}

// hosts subcategories

export function fetchSubCategoriesStart(data) {
    return {
        type: FETCH_SUB_CATEGORIES_START,
        data,
    };
}

export function fetchSubCategoriesSuccess(data) {
    return {
        type: FETCH_SUB_CATEGORIES_SUCCESS,
        data,
    };
}

export function fetchSubCategoriesFailure(error) {
    return {
        type: FETCH_SUB_CATEGORIES_FAILURE,
        error,
    };
}

// hosts amenities

export function hostsAmenitiesStart(data) {
    return {
        type: HOSTS_AMENITIES_START,
        data,
    };
}

export function hostsAmenitiesSuccess(data) {
    return {
        type: HOSTS_AMENITIES_SUCCESS,
        data,
    };
}

export function hostsAmenitiesFailure(error) {
    return {
        type: HOSTS_AMENITIES_FAILURE,
        error,
    };
}

// hosts amenities save

export function hostsAmenitiesSaveStart(data) {
    return {
        type: HOSTS_AMENITIES_SAVE_START,
        data,
    };
}

export function hostsAmenitiesSaveSuccess(data) {
    return {
        type: HOSTS_AMENITIES_SAVE_SUCCESS,
        data,
    };
}

export function hostsAmenitiesSaveFailure(error) {
    return {
        type: HOSTS_AMENITIES_SAVE_FAILURE,
        error,
    };
}

export function draftHostDetails(name, value) {
    return {
        type: DRAFT_HOST_DETAILS,
        name,
        value,
    };
}

export function fetchServiceLocationsStart(data) {
    return {
        type: FETCH_SERVICE_LOCATIONS_START,
        data,
    };
}

export function fetchServiceLocationsSuccess(data) {
    return {
        type: FETCH_SERVICE_LOCATIONS_SUCCESS,
        data,
    };
}

export function fetchServiceLocationsFailure(error) {
    return {
        type: FETCH_SERVICE_LOCATIONS_FAILURE,
        error,
    };
}


export function fetchHostDetailsStart(data) {
    return {
        type: FETCH_HOST_DETAILS_START,
        data,
    };
}

export function fetchHostDetailsSuccess(data) {
    return {
        type: FETCH_HOST_DETAILS_SUCCESS,
        data,
    };
}

export function fetchHostDetailsFailure(error) {
    return {
        type: FETCH_HOST_DETAILS_FAILURE,
        error,
    };
}