import React, { Component } from "react";
import api from "../../../Environment";
import io from "socket.io-client";
import { Link } from "react-router-dom";
import { apiConstants } from "./../../Constant/constants";
import { translate, t } from "react-multi-lang";

let socket;

class Inbox extends Component {
  state = {
    chatList: null,
    loadingChatList: true,
    socket: false,
    inputMessage: null,
    chatData: null,
    loadingChatData: true,
    headerDetail: null,
    headerDetailsLoadFirst: true,
    headerContentLoading: true,
    loadingStatus: true,
    loadingContent: null,
    loadingStatusChatHistory: true,
    loadingContentChatHistory: null,
    chatListskipCount: 0,
    chatHistorySkipCount: 0,
    loadMoreChatHistory: false,
    chatIndex: 0,
  };
  componentDidMount() {
    this.chatListApiCall(0);
  }

  chatListApiCall = (loadMoreStatus) => {
    let items;
    api
      .postMethod("bookings_inbox", { skip: this.state.chatListskipCount })
      .then((response) => {
        if (response.data.success) {
          if (this.state.chatList != null) {
            items = [...this.state.chatList, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            chatList: items,
            loadingChatList: false,
            chatListskipCount:
              response.data.data.length + this.state.chatListskipCount,
            loadingStatus: true,
          });

          if (this.state.chatList.length > 0 && loadMoreStatus == 0) {
            this.headerDetails(0);
            this.chatDetailsApiCall(0);
          } else {
            this.setState({
              headerContentLoading: false,
            });
          }
        }
      });
  };

  socketConnectionfun = (userId, providerId, hostId) => {
    socket = io(apiConstants.socketUrl, {
      query:
        `commonid: 'user_id_` +
        userId +
        `_provider_id_` +
        providerId +
        `_host_id_` +
        hostId +
        `', myid: ` +
        userId,
    });

    socket.emit("update sender", {
      commonid:
        "user_id_" +
        userId +
        "_provider_id_" +
        providerId +
        "_host_id_" +
        hostId,
      myid: userId,
    });
    let chatContent;
    socket.on("message", (newData) => {
      //  if(newData.chattype)

      let content = [];
      content.push(newData);
      chatContent = [...this.state.chatData, ...content];
      this.setState({ chatData: chatContent });
    });
  };

  chatDetailsApiCall = (index) => {
    let inputData;
    if (this.state.chatList[index].booking_id == 0) {
      inputData = {
        host_id: this.state.chatList[index].host_id,
        provider_id: this.state.chatList[index].provider_id,
        skip: this.state.chatHistorySkipCount,
      };
    } else {
      inputData = {
        booking_id: this.state.chatList[index].booking_id,
        host_id: this.state.chatList[index].host_id,
        provider_id: this.state.chatList[index].provider_id,
        skip: this.state.chatHistorySkipCount,
      };
    }

    api.postMethod("bookings_chat_details", inputData).then((response) => {
      let chathistory;
      if (response.data.success) {
        if (this.state.loadMoreChatHistory) {
          if (this.state.chatData != null) {
            chathistory = [
              ...response.data.data.reverse(),
              ...this.state.chatData,
            ];
          } else {
            chathistory = [...response.data.data.reverse()];
          }
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount:
              response.data.data.length + this.state.chatHistorySkipCount,
            loadingStatusChatHistory: true,
            loadMoreChatHistory: false,
          });
        } else {
          chathistory = response.data.data.reverse();
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount: response.data.data.length,
            loadingStatusChatHistory: true,
            chatIndex: index,
          });
        }
      } else {
        //
      }
    });
  };

  headerDetails = async (index) => {
    await this.setState({
      headerDetail: this.state.chatList[index],
      headerContentLoading: false,
    });

    if (!this.state.headerContentLoading) {
      this.socketConnectionfun(
        localStorage.getItem("userId"),
        this.state.headerDetail.provider_id,
        this.state.headerDetail.host_id
      );
    }
  };

  handleOnChange = ({ currentTarget: input }) => {
    this.setState({ inputMessage: input.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let chatData = [
      {
        user_id: this.state.chatData[0].user_id,
        provider_id: this.state.chatData[0].provider_id,
        message: this.state.inputMessage,
        host_id: this.state.chatData[0].host_id,
        booking_id: this.state.chatData[0].booking_id,
        chat_type: "up",
        user_picture: localStorage.getItem("user_picture"),
      },
    ];

    socket.emit("message", chatData[0]);

    let messages;

    if (this.state.chatData != null) {
      messages = [...this.state.chatData, ...chatData];
    } else {
      messages = [...chatData];
    }
    this.setState({
      chatData: messages,
      inputMessage: "",
    });
  };

  changeChatfun = async (event, index) => {
    event.preventDefault();
    socket.disconnect();
    await this.setState({ chatHistorySkipCount: 0 });
    this.headerDetails(index);
    this.chatDetailsApiCall(index);
  };

  loadMoreChatList = (event) => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });

    this.chatListApiCall(1);
  };

  loadMoreChatHistory = (event) => {
    event.preventDefault();
    this.setState({
      loadingStatusChatHistory: false,
      loadingContentChatHistory: "Loading...",
      loadMoreChatHistory: true,
    });
    this.chatDetailsApiCall(this.state.chatIndex);
  };

  render() {
    const {
      chatList,
      loadingChatList,
      chatData,
      loadingChatData,
      headerDetail,
      headerContentLoading,
      loadingStatus,
      loadingContent,
      loadingContentChatHistory,
      loadingStatusChatHistory,
    } = this.state;

    return (
      <div className="main-sec-content site-content">
        <hr />
        <div className="main chat-main-wrp-inner">
          {loadingChatList ? (
            "Loading"
          ) : chatList.length > 0 ? (
            <div className="row pt-3 m-0">

              {/* <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3  offset-md-1 chat-leftsec chat_leftsec"> */}
              {/* <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3  offset-md-1 "> */}
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 "> 
                <div className="input_lft_con">
                  <div className="input_lft_cont">
                 <div className="input-group mb-3 search-box" style={{bordeBottom:'unset!important'}}>
                  <div className="inbox_2">
                    <h3>Inbox(2)</h3>
                  </div>
                       
                        <input
                            type="text"
                            className="form-control"
                            // placeholder={t("search_here")}
                            placeholder=""
                            aria-label="Username"
                            aria-describedby="search"
                            // style={{background: '#CEFFE0',borderRadius:'0px 10px 10px 0px'}}
                            style={{borderRadius:'0px 10px 10px 0px'}}
                        />
                         <div className="input-group-prepend">
                            <span className="input-group-text" id="search"  style={{borderRadius:'10px 0px 0px 10px'}}>
                                {/* <i className="fas fa-search" style={{fontSize:"20px"}} /> */}
                                {/* <img className="img-fluid" srcSet="../../../assets/img/ser.png" style={{marginBottom:"4px"}} /> */}
                                <img src="assets/img/ic_baseline-search.png" class="ml-auto" />
                            </span>
                        </div>
                    </div>
                <div className="chat-list-wrap mb-3 ">
                  
                  <button
                    className="navbar-toggler chat-menu"
                    type="button"
                    data-toggle="collapse"
                    data-target="#chat-list"
                    aria-controls="chat-list"
                    aria-expanded="false"
                  >
                    <span className="float-left">{t("chat_list")}</span>
                    <span className="navbar-toggler-icon chat-menu-icon float-right">
                    </span>
                  </button>
                  

                  <div
                    className="chat-list-block collapse show navbar-collapse"
                    id="chat-list"
                  >
                    {chatList.map((list, index) => (
                      <Link
                        to="#"
                        key={list.provider_id}
                        className="chat-list-box chat_list_box chat-list-box-activ chat_list_box_activ"
                        onClick={(event) => this.changeChatfun(event, index)}
                      >
                        <div className="chat-prof-img">
                          <img
                            src={list.provider_picture}
                            alt={list.provider_name}
                          />
                        </div>
                        <div className="mes_container d-flex justify-content-between">
                        <div className="chat-prof-content chat_prof_content">
                          <h5 className="chat-name chat_name">{list.provider_name}</h5>
                          <p className="chat-msg">{list.message}</p>
                       
                        </div>
                        <div className="chart_date">
                        <p className="chat-date">{list.updated}</p>
                        <div class="chat_circle">2</div>
                        </div>
                        </div>
                        <div className="clear-both" />
                      </Link>
                     
                    ))}
                  </div>
                  <div className="text-center text_center">
                    {loadingStatus ? "" : loadingContent}
                    {chatList.length > 0 ? (
                      <a
                        href="#"
                        class="show-all m-0"
                        onClick={this.loadMoreChatList}
                      >
                        {t("show_more")}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
               </div> 
               </div>        
              {/* <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-7 " style={{paddingTop:'7%'}}> */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 ">
                <div className="input_rft_container">
              <div className=" input_rft_content">
              <div style={{display:"flex",justifyContent:"space-between",borderBottom:"1px solid rgba(18, 20, 29, 0.1)", padding:"10px 0px 20px 0px"}}>
           
                <div className="inbox_heading">
                  <h3>Jenny Wilson</h3>
                </div>
             
                <div className="inbox_icons">
                <a class="  rounded border-0 px-3 py-2 ml-4 mt-4 " style={{color: '#1DD05D',backgroundColor:'white'}}>

              <img className="chat-smile-icon chat_smile_icon"  style={{paddingTop:"3px"}}src="/assets/img/flag.png" />
              </a>

              <a class="  rounded border-0  py-2 ml-4 mt-4 " style={{color: '#1DD05D',backgroundColor:'white'}}>
                <img className="chat-smile-icon chat_smile_icon" src="/assets/img/del.png" />
              </a>
              </div>
              </div>  
              
              {/* <div className="chat-rightsec"> */}
              <div className="">
                {loadingChatList ? (
                  t("loading")
                ) : (
                  <div className="relative height-100" style={{marginTop:'5%'}}>
                    {headerContentLoading ? (
                      "Loading..."
                    ) : headerDetail ? (
                     <div className="chat-header">
                        {/*<div className="chat-list-box single-chat-box">
                          <div className="chat-prof-img">
                            <img
                              src={headerDetail.provider_picture}
                              alt={headerDetail.provider_name}
                            />
                          </div>
                          <div className="chat-prof-content">
                            <h5 className="chat-name">
                              {headerDetail.provider_name}
                            </h5>
                            <p className="chat-date">
                              {headerDetail.updated}
                            </p>
                          </div>
                          <div className="clear-both" />
                        </div>*/}
                      </div>
                    ) : (
                      <div className="chat-name m-t-10">
                        <p className="text-center">{t("no_message")}</p>
                      </div>
                    )}

                    {loadingChatData ? (
                      ""
                    ) : (
                      <div className="chat-content chat_contents ">
                        {chatData.length > 0 ? (
                          <div className="text-center">
                            {loadingStatusChatHistory
                              ? ""
                              : loadingContentChatHistory}
                            <a
                              href="#"
                              class="show-all"
                              onClick={(event) =>
                                this.loadMoreChatHistory(event)
                              }
                            >
                                <i className="fas  fa-chevron-up mr-1" />{t("show_more")}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}

                        {chatData.map((chat, index) =>
                          chat.chat_type == "pu" ? (
                            <div className="chat-left chat_left">
                              <div className="display-inline">
                                <img
                                  className="chat-img-left"
                                  src={chat.provider_picture}
                                />
                                
                                <div className="chat-content-right">
                                  <div className="chat-message">
                                    {chat.message}{" "}
                                 
                                  <p className="clearfix m-0" />
                                  <p className="chat-time">{chat.updated}</p>
                                  </div>
                                </div>
                               
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="clearfix" />
                              <div className="chat-right chat_right">
                                <div className="display-inline">
                                  <div className="chat-content-right">
                                    <div className="chat-message">
                                      {chat.message}{" "}
                                      <p className="clearfix m-0" />
                                      <p className="chat-time text-left">
                                      {chat.updated}
                                    </p>
                                    </div>
                                   
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}
                    <div className="chat-footer chat_footer">
                      <form onSubmit={this.handleSubmit}>
                                  {/* <div className="input-group dropdown"> */}
                                  <div className="dropdown d-flex">
                                    <div className="chat-footer-wrp chat_footer_wrp">
                                    <img className="chat-smile-icon" src={window.location.origin + "/assets/img/smiles.png"} />
                                      <input
                                        type="text"
                                        className="form-control dropdown-toggle"
                                        data-toggle="dropdown"
                                        placeholder={t("enter_your_message")}
                                        name="message"
                                       value={this.state.inputMessage}
                                    onChange={this.handleOnChange}
                                      />
                                      <i className="fas fa-solid fa-paperclip chat-file-upload chat_file_upload" />

                                    </div> 
                                    <div className="input-group-append input_group_append">
                                      <span
                                        className="input-group-text input_group_text "
                                      
                                        id="basic-addon"
                                        onClick={this.handleSubmit}
                                      >
                                     <i className="fas fa-paper-plane" /> Send
                                      
                                      </span>
                                    </div>
                                  </div>
                        
                      </form>
                    </div>
                  </div>
                )}
              </div>
              </div>
              </div>
              </div>
            </div>
          ) : (
            t("no_message_for_you")
          )}

        </div>
      </div>
    );
  }
}

export default translate(Inbox);
