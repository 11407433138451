import React, { Component } from "react";
import { Link } from "react-router-dom";
import ToastDemo from "../../Helper/toaster";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import api from "../../../HostEnvironment";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class HostRegister extends HostHelper {
  state = {
    data: {
      name: "",
      email: "",
      password: "",
      device_type: "web",
      device_token: "123466",
      login_by: "manual",
      timezone: const_time_zone
    },
    loadingContent: null,
    buttonDisable: false,
    type: 'password',

  };
  handleClickShowPassword = () => this.setState(({type}) => ({
    type: type === 'password' ? 'text' : 'password'
  }))
  
  handleSubmit = event => {
    event.preventDefault();
    const { path } = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    api
      .postMethod("register", this.state.data)
      .then(response => {
        if (response.data.success === true) {
          localStorage.setItem("hostId", response.data.data.provider_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("hostLoginStatus", true);
          localStorage.setItem("provider_name", response.data.data.username);
          window.location = path ? path.from.pathname : "/host/dashboard";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({ loadingContent: null, buttonDisable: false });
        }
      })
      .catch(error => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };
  render() {
    const { data } = this.state;
    return (

      <div className="site-content">
      <div className="row pt-5">
      <div className="col-md-6">
         <div className="heading_form">
         <div className="login_logo">
          <span class="mb-4"><img class="img-fluid" srcset="../../../assets/new_imgs/footer_logo.png" /></span>
          </div>
          {/* <h1 className="section-head log_text Login_text " style={{paddingBottom: 'unset',color:'#1DD05D'}}>
                Create Account
                </h1> */}
          <div className="newlogin_heading">
          <h1 className="section-head log_text Login_text">Welcome back </h1>
          <p className="newlogin_para">Welcome back! Please enter your details.</p>
          </div>


                <div className="prov-login prov-login-new "> 
                <form className="top1 prov-login-form" onSubmit={this.handleSubmit}>
                <label htmlFor="" className="email_label">Name</label>
                <div className="form-group input-group new_reg_inputs host_input">
              <input
                type="text"
                className="form-control form_con"
                name="name"
                placeholder="First Name"
                onChange={this.handleChange}
                value={data.name}
              />
            </div>
            <label htmlFor="" className="email_label">Email</label>
            <div className="form-group input-group new_reg_inputs host_input">
              <input
                type="text"
                className="form-control form_con"
                placeholder="Email or Phone"
                name="email"
                onChange={this.handleChange}
                value={data.email}
              />
            </div>
            <label htmlFor="" className="email_label">Password</label>
            <div className="form-group input-group  new_reg_inputs host_input">
              <input
                type={this.state.type}
                className="form-control form_con"
                placeholder="Your password"
                name="password"
                onChange={this.handleChange}
                value={data.password}
              />
              <img src="../../../assets/img/eye.png"  onClick={this.handleClickShowPassword} className="img-fluid eye_icons" />
            </div>
            <button
              className="pink-btn bottom1 block cmn-btn cmn_btn mt-5"
              disabled={this.state.buttonDisable}
            >
              {this.state.loadingContent != null
                ? this.state.loadingContent
                : t("Sign up")}
            </button>

            <button
              className=" pink_btn bottom1 block cmn-btn cmn_btn mt-1">
                  <img class="img-fluid" srcset="../../../assets/img/Frame.png" />
                  &nbsp; Sign up with Google
            
            </button>
          </form>
          {/* <p className="line" /> */}
          <h4 className="m-0 text-left text_left captalize">

  
            {t("already_have_an_account",{siteName: configuration.get(
                    "configData.site_name")})}{" "}
            <Link to={"/host/login"} className="bold-cls bold_cls close-login">
              {" "}
              Log in
            </Link>
          </h4>
        </div>
     
     
      </div>
      </div>
      {/* <div className="col-md-6 logincontainer">
      <img src="../../../assets/img/login44.png" className="img-fluid" />
          <div className="logincentered"><h1 className="heading2" style={{color:'white',textAlign:'left'}}>Discover your dream space in prime location</h1></div>

        
        </div> */}
          <div className="col-lg-6 col-md-6 logincontainer login_centered ">
          <img src="../../../assets/img/login44.png" className="img-fluid" />
          <div className="logincentered login_centered">
            <h1 id="heading"  style={{color:'white',textAlign:'left'}}>Discover your dream space in prime location</h1>
            </div>

        
        </div>
      </div>
     </div>
     
    );
  }
}

export default withToastManager(translate(HostRegister));
