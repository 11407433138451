import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import ProfileInput from "../../Helper/profileInput";
import Form from "../../Helper/form";
import HostProfileSideBar from "./hostProfileSideBar";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";


var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class HostEditProfile extends HostHelper {
  constructor(props) {
    super(props);

    // States and props usage
  }

  state = {
    data: null,
    loading: true,
    error: null,
    loginStatus: true,
    profileUpdateStatusContent: null,
    loadingContent: null,
    buttonDisable: false
  };

  componentDidMount() {
    this.getHostDetails();
    if (this.state.error != null) {
      ToastDemo(this.props.toastManager, this.state.error, "error");
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    let hostDetails = { ...this.state.data };
    const data = {
      name: hostDetails.name,
      description: hostDetails.description,
      email: hostDetails.email,
      mobile: hostDetails.mobile,
      timezone: const_time_zone
    };

    api.postMethod("update_profile", data).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  render() {
    const { data, loading, error, loginStatus } = this.state;
    if (!loginStatus) {
      return (
        <Redirect
          to={{
            pathname: "/host/login",
            state: { error: error }
          }}
        />
      );
    }
    return (
       <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                      <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Account Settings</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                          <HostProfileSideBar />
                          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                            <form onSubmit={this.handleSubmit}>
                              <div className="panel">
                                <div className="panel-heading">{t("edit_profile")}</div>

                                <div className="panel-body  account">
                                  <ProfileInput
                                    label="Name"
                                    type="text"
                                    placeholder=""
                                    id="fname"
                                    name="name"
                                    value={loading ? "" : data.name}
                                    onChange={this.handleChange}
                                    description=""
                                  />

                                  <ProfileInput
                                    label={t("email_address")}
                                    type="text"
                                    placeholder=""
                                    id="email"
                                    name="email"
                                    value={loading ? "" : data.email}
                                    onChange={this.handleChange}
                                    description={t("email_description",{siteName: configuration.get(
                                      "configData.site_name")})}
                                  />

                                  <ProfileInput
                                    label={t("phone_number")}
                                    type="text"
                                    placeholder=""
                                    id="number"
                                    name="mobile"
                                    value={loading ? "" : data.mobile}
                                    onChange={this.handleChange}
                                    description={t("mobile_number_description",{siteName: configuration.get(
                                      "configData.site_name")})}
                                  />

                                  <div className="form-group row">
                                    <div className="col-3 text-right">
                                      <label>{t("describe_yourself")}</label>
                                    </div>
                                    <div className="col-9">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        rows="4"
                                        name="description"
                                        value={loading ? "" : data.description}
                                        onChange={this.handleChange}
                                      />
                                      <h5 className="profile-note">
                                        {t("site_is_built_on_relationships",{siteName: configuration.get(
                                "configData.site_name")})}
                                      </h5>

                                      <h5 className="profile-note">
                                        {t("tell_them_about_the_things_you_like")}
                                      </h5>

                                      <h5 className="profile-note">
                                        {t("tell_them_what_it_is_like_to_have_you",{siteName: configuration.get(
                                "configData.site_name")})}
                                      </h5>

                                      <h5 className="profile-note">
                                        {t("tell_them_about_you")}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-9 offset-3 text-center">
                                      <button
                                        className="pink-btn btn-block"
                                        disabled={this.state.buttonDisable}
                                      >
                                        {this.state.loadingContent != null
                                          ? this.state.loadingContent
                                          : t("save")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
        </div>
      </div>
          
    );
  }
}

export default withToastManager(translate(HostEditProfile));
