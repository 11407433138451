import React, { Component } from "react";
import ForgotPassword from "../Auth/ForgotPassword";
import InputField from "../Helper/inputfield";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { gapi } from "gapi-script";
import SocialButton from "../Helper/SocialButton";

const $ = window.$;

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class Login extends Helper {
  state = {
    data: {
      email: "",
      password: "",
      device_type: "web",
      timezone: const_time_zone,
    },
    loadingContent: null,
    buttonDisable: false,
    type: 'password',

  };
  componentDidMount() {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get(
          "configData.social_logins.GOOGLE_CLIENT_ID"
        ),
        plugin_name: "chat",
      });
    });
  }
  handleClickShowPassword = () => this.setState(({type}) => ({
    type: type === 'password' ? 'text' : 'password'
  }))

  responseFacebook = (response) => {
    const { path } = this.props.location;
    if (response) {
      if (response.status == "unknown") {
        ToastDemo(this.props.toastManager, "Cancelled", "error");
        return false;
      }
      const emailAddress =
        response.email === undefined || response.email === null
          ? response.id + "@facebook.com"
          : response.email;

      const facebookLoginInput = {
        social_unique_id: response.id,
        login_by: "facebook",
        email: emailAddress,
        name: response.name,
        device_type: "web",
        device_token: "123466",
        timezone: const_time_zone,
      };

      api
        .postMethod("register", facebookLoginInput)
        .then((response) => {
          if (response.data.success === true) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("userLoginStatus", true);
            localStorage.setItem("user_picture", response.data.data.picture);
            localStorage.setItem("username", response.data.data.username);

            // window.location = path ? path.from.pathname : "/home";
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.setState({ loadingContent: null, buttonDisable: false });
            $("#login").modal("hide");
            if (path) {
              this.props.history.push(path.pathname);
            } else {
              this.props.history.push("/home");
            }
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingContent: null, buttonDisable: false });
        });
    }
  };

  responseGoogle = (response) => {
    const path = this.props.location;
    let googleData = response._profile;
    if (googleData == undefined || googleData == null || !googleData) {
      return ToastDemo(
        this.props.toastManager,
        "Google login failed.",
        "error"
      );
    }
    const googleLoginInput = {
      social_unique_id: googleData.id,
      login_by: "google",
      email: googleData.email,
      name: googleData.name,
      picture: googleData.profilePicURL,
      device_type: "web",
      device_token: "123466",
      timezone: const_time_zone,
    };
    api
      .postMethod("register", googleLoginInput)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);

          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#login").modal("hide");
          if (path) {
            this.props.history.push(path.pathname);
          } else {
            this.props.history.push("/home");
          }
        } else {
          this.setState({ loadingContent: null, buttonDisable: false });
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("login", this.state.data)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);

          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#login").modal("hide");
          if (path) {
            this.props.history.push(path.pathname);
          } else {
            this.props.history.push("/home");
          }
          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          this.setState({ loadingContent: null, buttonDisable: false });
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
  };

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  render() {
    const { data } = this.state;

    return (
      <div>
        
      </div>
    );
  }
}

export default withToastManager(translate(Login));
