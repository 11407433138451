import React, { Component } from "react";

import { Link } from "react-router-dom";

import configuration from "react-global-configuration";

import $ from "jquery";

import { translate, t } from "react-multi-lang";

const jQuery = window.jQuery;
class FloatingFooter extends Component {
    state = {
        footer_first_section: [],
        footer_second_section: [],
        footer_third_section: [],
        footer_fourth_section: []
    };
    // constructor(props) {
    //     super(props);

    //     // States and props usage
    // }

    componentDidMount() {
        // Call api function
        var footerHeight = jQuery("#footer").outerHeight();
        var deviceheight = jQuery(window).outerHeight();
        var contentheight = deviceheight - footerHeight;
        jQuery(".main-sec-content").css("min-height", contentheight);

        if (configuration.get("configData.footer_first_section")) {
            this.setState({
                footer_first_section: configuration.get(
                    "configData.footer_first_section"
                )
            });
        }

        if (configuration.get("configData.footer_second_section")) {
            this.setState({
                footer_second_section: configuration.get(
                    "configData.footer_second_section"
                )
            });
        }

        if (configuration.get("configData.footer_third_section")) {
            this.setState({
                footer_third_section: configuration.get(
                    "configData.footer_third_section"
                )
            });
        }

        if (configuration.get("configData.footer_fourth_section")) {
            this.setState({
                footer_fourth_section: configuration.get(
                    "configData.footer_fourth_section"
                )
            });
        }
    }

    handleFooterClick = event => {
        event.preventDefault();
        $("#terms-btn").toggleClass("terms-btn");
        $(".floating-footer").slideToggle();
    };

    render() {
        return (
           <div className="footer_Booking ">
                <footer className="bg_light_green   border-top">

                    <section className>
                        <div className="container mt-5">
                            <div className="row justify-content-between mt-3">

                                <div className="col-md-4 mb-4 px-4">

                                    <span className="mb-4"><img className="img-fluid" srcSet="../../../assets/img/footer_logo.png" /></span>

                                    <div className="d_grid_footer mt-5 pt-3 customer_support">
                                        <img className="img-fluid" srcSet="../../../assets/img/footer_custmor.png" />
                                        <div className="">
                                            <h5 className="fw_bolder  footer_heading">24/7 Customer Support</h5>
                                            <p className="mb-0 footer_paras">
                                                If you need help while travelling or <br/>
                                                hosting, contact us at our toll free <br/>
                                                number: 000 800 4405 103
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-4 px-3">

                                    <div className="d_grid_footer ">
                                        <img className="img-fluid" srcSet="../../../assets/img/footer_varified.png" />
                                        <div className="">
                                            <h5 className="fw_bolder  footer_heading">Verified ID</h5>
                                            <p className="mb-0 footer_paras">
                                                We aim to build a trusted community <br/>
                                                to give you more info when you  <br/> 
                                                are deciding who to host or <br/>  stay with
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d_grid_footer mt-5 pt-3">
                                        <img className="img-fluid" srcSet="../../../assets/img/footer_host.png" />
                                        <div className="">
                                            <h5 className="fw_bolder footer_heading">$6,00,00,000 Host Gurantee</h5>
                                            <p className="mb-0 footer_paras">
                                             Host are protected against property <br/>
                                               damages up to $6,00,00,000. <br/>
                                               
                                            </p>
                                        </div>
                                    </div>
                                </div>


                             


                                <div className="col-md-4  mb-4 footer_img_right px-3">
                                    <h1 className=" fw_bolder footer_heading">
                                        Get Your App <br/> Now

                                    </h1>
                                    <div className="mt-5">
                                        <img srcSet="../../../assets/img/footer_apple.png" />
                                    </div>
                                    <div className="mt-4">
                                        <img srcSet="../../../assets/img/footer_play_store.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="text-center bg_success text-white p-2 footer_copy">
                        {t("footer_text_a")}
                    </div>

                </footer>
            </div>
        );
    }
}

export default FloatingFooter;
