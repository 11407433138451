import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class AboutUs extends Component {
    state = {};
    render() {
        return (
            <div>
               
                <div>

                    <div className="display-inline about_us about_pad ">
                        <div className=" new_d_grid d_grid new_abt_card align-items-center ">
                            <div className="">
                                <img className="img-fluid about_img" srcSet="../../../assets/img/img_about2.png" alt="About us" />
                            </div>
                            <div className="">
                            <div className="about_heading_content">
                            <h1 className="Heading_section  how_work_heading  pt-md-5 mt-3 mb-md-4 find_right_spc">
                            {t("About_us")}
                                    </h1>
                                    <p style={{color:"rgba(19, 15, 38, 0.5)"}}>SpacEazy was built out of necessity, providing the public with a stress-free way to store their 
belongings whenever they want, however they want. Take advantage of the simplest, most 
sustainable way to store your belongings within a shared space on someone else's property. No
matter your situation or how many items you'd like to keep safe and secure, you can depend on 
us to help you find the perfect storage space, whether it's a small storage room or something 
even larger to hold more of your belongings.</p>
<p style={{color:"rgba(19, 15, 38, 0.5)"}}>At SpacEazy, we know how important it is for our clients to have enough space. For example, 
you may want to declutter your home or utilize storage space while in the middle of a move. 
Even if you're a small business owner looking to stock up on inventory, we're here to cater to 
your needs, providing a vast selection of storage opportunities for you to take advantage of and 
use as often as you'd like and for however long you need.</p>
<p style={{color:"rgba(19, 15, 38, 0.5)"}}>SpacEazy connects hosts with consumers in dire need of ample storage space, including but 
not limited to basements, attics, garages, and more. With millions of square feet of space 
currently available on our platform, we've created a simple way for hosts to earn money for 
offering their space while making it easier for others to find extra room for just about anything 
they own</p>
                                <div>
                                </div>  
                                </div>
                                   



                                    {/* <p className="about_para">
                                      <strong>{t("Spaceasy")}</strong> 
                                               &nbsp; was bulid out of necessity,providing the public with the stress-free way to store their belongings whenever they want,however they want.Take advantage of the simplest,most sustainable way to store your belongings within a share space on some one else's property.No matter your situation or how many items you'd like to keep save secure,you can depend on us to help you find the perfect storage space,whether it's a small storage room or something even larger to hold more of our belongings.
                                    
                                    </p> */}
                                    {/* <p className="about_para">
                                    At  <strong>{t("Spaceasy")}</strong>,  {t("about_us_text_2")}
                                    </p> */}
                                    {/* <p className="about_para">
                                    <strong>{t("Spaceasy")}</strong> {t("about_us_text_3")}
                                    </p> */}
                               





                            </div>
                            
                        </div>
                     

                        <div className="about_us_card">
                                      <div className="row about_card_row">
                                        <div className="col-md-6">
                                            <h5 className="about_card_heading ">
                                            Verified Identification.
                                            </h5>
                                            <p className=" mb-0 lis_tnk">  Building a trusted community is our priority and reason for ID verification, enabling us to ensure 
                                                    you choose storage space in a safe, comfortable environment.</p>
                                                <div className="about_card_btn">
                                        
                                                <button class="find_loc_see_more pt-4 px-0">See More <img className="img-fluid about_img" srcSet="../../../assets/img/vector.png" alt="About us" /></button>
                                              
                                                </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h5 className="about_card_heading ">
                                            24/7 Customer Support
                                            </h5>
                                            <p className="mb-0 lis_tnk">Do you have questions, comments, or concerns about our storage space rental platform? If so, 
                                                we're available 24/7 to help. Contact us at our toll-free number, 000 800 4405 103, whenever 
                                                    you need us.</p>
                                                <div className="about_card_btn">
                                        
                                                <button class="find_loc_see_more pt-4 px-0">See More <img className="img-fluid about_img" srcSet="../../../assets/img/vector.png" alt="About us" /></button>
                                              
                                                </div>
                                        </div>

                                        <div className="col-md-6">
                                        <h5 className="about_card_heading pt-5">
                                           About us
                                            </h5>
                                            <p className="mb-0 lis_tnk">We offer a free platform where anybody can list and browse any listing as long as it's not for accommodation.</p>
                                                <div className="about_card_btn">
                                        
                                                <button class="find_loc_see_more pt-4 px-0">See More <img className="img-fluid about_img" srcSet="../../../assets/img/vector.png" alt="About us" /></button>
                                              
                                                </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h5 className="about_card_heading pt-5">
                                          Become a Host
                                            </h5>
                                            <p className="mb-0 lis_tnk">Become a host and put your unused storage and parking space to good use.</p>
                                                <div className="about_card_btn">
                                        
                                                <button class="find_loc_see_more pt-4 px-0">See More <img className="img-fluid about_img" srcSet="../../../assets/img/vector.png" alt="About us" /></button>
                                              
                                                </div>
                                        </div>
                                      </div>
                                    </div>                



                    </div>


                  
                </div>


                {/* <p className="about_para">
                                    At  <strong>{t("Spaceasy")}</strong>,  {t("about_us_text_2")}
                                    </p> */}
                                    {/* <p className="about_para">
                                    <strong>{t("Spaceasy")}</strong> {t("about_us_text_3")}
                                    </p> */}
                                    
                                {/* <div className="verifiaction_identification">
                             <h3> Verifiaction identification</h3>
                                <p>Building the trusted community is our priority and reason for ID verification,enabling us to ensure you choose storage space in a safe,comfortable environment.</p>
                                 </div> */}

                                 {/* <div className="Customer_suppor">
                                     <h3> 24/7 Customer support</h3>
                                <p>Do you have questions,comments ,and concerns about our storage space rental platform?if so, we're available 24/7 to help. Contact us at our toll-free number,000 800 4405 103, whenever you need us.</p>
                                 </div> */}
            </div>
        );
    }
}

export default translate(AboutUs);
