import React, { Component } from "react";
import HostFooter from "./hostFooter";
import HostAuthHeader from "./hostAuthHeader";


// This Layouts used in Home page Header,  static footer and content

class HostAuthLayoutLogin extends Component {
  constructor(props) {
    super(props);

    // define states
  }

  render() {
    return (
      <div className="wrapper">

        <div>{React.cloneElement(this.props.children)}</div>
      </div>
    );
  }
}
export default HostAuthLayoutLogin;
