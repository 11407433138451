import React, { Component } from "react";
import SideBar from "./sideBar";
import api from "../../../HostEnvironment";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import LeftSideBar from "../HostLayout/sideBar";
import { ProSidebarProvider } from 'react-pro-sidebar';


class HostSubscriptionHistory extends Component {
  state = {
    subscriptions: null,
    loading: true,
    loadingStatus: true,
    loadingContent: null,
    skipCount: 0
  };
  componentDidMount() {
    this.subscriptionHistoryApi();
  }

  subscriptionHistoryApi() {
    let items;
    api
      .postMethod("subscriptions_history", {
        skip: this.state.skipCount
      })
      .then(response => {
        if (response.data.success === true) {
          if (this.state.subscriptions != null) {
            items = [...this.state.subscriptions, ...response.data.data];
          } else {
            items = [...response.data.data];
          }
          this.setState({
            subscriptions: items,
            loading: false,
            skipCount: response.data.data.length + this.state.skipCount,
            loadingStatus: true
          });
        }
      });
  }

  loadMore = event => {
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });
    event.preventDefault();
    this.subscriptionHistoryApi();
  };
  render() {
    const {
      loading,
      subscriptions,
      loadingContent,
      loadingStatus
    } = this.state;
    return (
      <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 inner-container" >
                        <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Subscription</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                  <div className="row pt-3">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                      {loading ? (
                        ""
                      ) : subscriptions.length > 0 ? (
                        subscriptions[0].is_current_subscription ? (
                          <h5>
                            Current Plan:{" "}
                            <span className="text-success">
                              {subscriptions[0].title}{" "}
                            </span>
                            ( Expires At:{" "}
                            <span className="text-success">
                              {subscriptions[0].expiry_date}{" "}
                            </span>
                            )
                          </h5>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}

                      <br />

                      <div className="trans-table table-responsive">

                        <table className="cmn-table table subs-table table_trans">
                          <thead>
                              <tr>
                                <th className="head1" scope="col" colspan="4">Subscriptions History</th>
                                
                              </tr>
                              <tr className="head2">                              
                                <th scope="col">#</th>
                                <th scope="col">{t("plan_name")}</th>
                                <th>{t("period")}</th>
                                <th>{t("expiry_date")}</th>
                                <th scope="col">{t("payment_mode")}</th>
                                <th scope="col">{t("amount")}</th>
                                <th scope="col">{t("status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading
                              ? ""
                              : subscriptions.length > 0
                              ? subscriptions.map((subscription, index) => (
                                  <tr
                                    key={
                                      subscription.provider_subscription_payment_id
                                    }
                                  >
                                    <td>{index + 1}</td>
                                    <td>
                                      {subscription.title}
                                      <br />
                                      <span>#{subscription.payment_id}</span>
                                    </td>
                                    <td>
                                      {subscription.plan_text}
                                    </td>
                                    <td>{subscription.expiry_date}</td>
                                    <td>{subscription.payment_mode}</td>
                                    <td>{subscription.paid_amount_formatted}</td>
                                    <td>
                                      <span className="badge status-btn badge-success">
                                        {subscription.status_text}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              : t("no_data_found")}
                            {loadingStatus ? "" : loadingContent}
                          </tbody>
                        </table>
                        {loading ? (
                          ""
                        ) : subscriptions.length > 0 ? (
                          <Link to={"#"} onClick={this.loadMore}>
                            {t("load_more")}
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default translate(HostSubscriptionHistory);
