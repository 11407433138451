import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class FindLocation extends Component {
    state = {};
    render() {
        return (
            <div className="custom_container">
                <div className="">
                    <div className="section-title">

                    </div>

                </div>
                <div>

                    {/* <div className="display-inline fint_location">

                        <div className="row m-0 justify-content-between align-items-center">
                            <div className=" col-sm-12 col-md-7 p-0">
                                <div className="">
                                    <h1 className="bold-cls pb-3 location_heading_font_styling">
                                        {t("find_your_dream_place_in_prime_location")}
                                    </h1>
                                    <div className="find_right_space_para">
                            <p>Check out our impressive selection of listing available for your convenience.With plenty of options to choose from,you're sure to find the perfect storage space for rent in an area you will love.</p>
                        </div>
                                    <button className="bg_success text-white rounded border-0 px-3 py-2 ">{t("explore_more")}</button>
                                </div>
                            </div>
                            <div className=" col-sm-12 col-md-5 p-0 text-md-right text-sm-center ">
                                <div className="shadow-imgs">
                                  <img src="../assets/img/find_location.png" className="listing-polygone" alt="Listing1" />
                                  <img src="../assets/img/shadowPolygon16.png" className="" alt="Listing1" />
                                </div>  
                                
                            </div>


                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default translate(FindLocation);
