import React, { Component } from "react";
import { translate, t } from "react-multi-lang";
import HomeRoomContent from "../Home/homeRoomContentAll";
import { Link } from "react-router-dom";
import api from "../../Environment";
import Helper from "../Helper/Helper";
import MapContainer from "../Helper/mapHelper";
import SearchForm from "../Helper/searchForm";

import Loader from "../Helper/Loader";
import Filters from "../Helper/filters";

class ViewAll extends Helper {
  state = {
    first_block: null,
    mainData: null,
    loading: true,
    skipCount: 0,
    loadingStatus: true,
    loadingContent: null,
    contentData: null,
    map: true
  };

  componentDidMount() {
    // Call api function
    const inputData = {
      api_page_type: this.props.match.params.url_type,
      api_page_type_id: this.props.match.params.id,
      skip: this.state.skipCount
    };

    this.seeAll(inputData);
  }

  componentWillReceiveProps(props) {
    this.setState({ loading: true });
    const inputData = {
      api_page_type: props.match.params.url_type,
      api_page_type_id: props.match.params.id,
      skip: this.state.skipCount
    };

    // this.seeAll(inputData);
  }

  loadMore = event => {
    event.preventDefault();
    this.setState({ loadingStatus: false, loadingContent: "Loading..." });
    const inputData = {
      api_page_type: this.props.match.params.url_type,
      api_page_type_id: this.props.match.params.id,
      skip: this.state.skipCount
    };

    this.seeAll(inputData);
  };

  toggleMap = map => {
    this.setState({ map: map });
  };

  render() {
    let load = new Loader();
    const { loading, loadingStatus, loadingContent } = this.state;
    return (
      <div className="main">
        
        <div className="section-spacing">
          <div className="">
            {/* <div className="row">
              <div className={this.state.map ? "col-lg-8 col-md-8 " : "col-12"}>
                {loading ? (
                  load.propertyLoader()
                ) : (
                  <HomeRoomContent
                    contentDetails={this.state.contentData}
                    title={this.state.mainData[0].title}
                    history={this.props.history}
                  />
                )}
              </div>
              {this.state.map ? (
                <div className="col-lg-4 col-md-4">
                  <div className="map-sec ">
                    {this.state.contentData &&
                    this.state.contentData.length > 0 ? (
                      <MapContainer data={this.state.contentData} />
                    ) : (
                      false
                    )}
                  </div>
                </div>
              ) : (
                false
              )}
            </div> */}
<div className="row serach_res_row">

{this.state.map ? (
    <div className="col-lg-6 col-md-6">
      <div className="map-sec pt-0">
        {this.state.contentData &&
        this.state.contentData.length > 0 ? (
          <MapContainer data={this.state.contentData} />
        ) : (
          false
        )}
      </div>
    </div>
  ) : (
    false
  )}



  <div className={this.state.map ? "col-lg-6 col-md-6 " : "col-12"}>
    <SearchForm props={this.props.props} />
    <Filters
          
          match={this.props.match}
          props={this.props}
        />

    {loading ? (
      load.propertyLoader()
    ) : (
      <HomeRoomContent
        contentDetails={this.state.contentData}
        title={this.state.mainData[0].title}
        history={this.props.history}
      />
    )}
  </div>
  
  
</div> 
           {/* {loadingStatus ? "" : loadingContent}
            {loading ? (
              ""
            ) : this.state.contentData.length > 0 ? (
              <Link to="#" className="show-all" onClick={this.loadMore}>
                {t("show_more")}
              </Link>
            ) : (
              ""
            )}*/}
          </div>
        </div>
      </div>
    );
  }
}
export default translate(ViewAll);
