import React, { Component } from "react";
import { Link } from "react-router-dom";
import api from "../../../HostEnvironment";
import ToastDemo from "../../Helper/toaster";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class HostLogin extends HostHelper {
  state = { 
      data: {
      timezone: const_time_zone
    },
    type: 'password',
    loadingContent: null, 
    buttonDisable: false ,
  };
  
 
  handleClickShowPassword = () => this.setState(({type}) => ({
    type: type === 'password' ? 'text' : 'password'
  }))
  
 

  handleSubmit = (event) => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("login", this.state.data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("hostId", response.data.data.provider_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("hostLoginStatus", true);
          localStorage.setItem("host_picture", response.data.data.picture);
          localStorage.setItem("provider_name", response.data.data.username);
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });

          window.location = "/host/dashboard";
          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          this.setState({ loadingContent: null, buttonDisable: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null });
      });
  };

  render() {
    return (
      <div className="site-content">
         <div className="row pt-5 login_row">
        <div className="col-lg-6 col-md-6 ">
        <div className="heading_form">
          {/* <div className="newlogin_heading " style={{marginLeft: '2%'}}> */}
          <div className="login_logo">
          <span class="mb-4"><img class="img-fluid" srcset="../../../assets/new_imgs/footer_logo.png" /></span>
          </div>
          <div className="newlogin_heading">
          <h1 className="section-head log_text Login_text">Welcome back </h1>
          <p className="newlogin_para">Welcome back! Please enter your details.</p>
          </div>

          <div className="prov-login prov-login-new ">
          <form className="top1 prov-login-form" onSubmit={this.handleSubmit}>

            <label htmlFor="" className="email_label">Email</label>
            <div className="form-group input-group host_input new_reg_inputs">
              
              <input
                type="text"
                className="form-control form_con"
                placeholder="Email Or Phone"
                onChange={this.handleChange}
                name="email"
              />
            </div>
            <label htmlFor="" className="email_label">Password</label>
            <div className="form-group input-group host_input new_reg_inputs">
             <input 
                type={this.state.type}
                className="form-control form_con"
                placeholder="Your Password"
                onChange={this.handleChange}
                name="password"
              />
              <div class="input-group-append">
              <img src="../../../assets/img/eye.png" className="img-fluid eye_icons" onClick={this.handleClickShowPassword} />
              </div>
           
          
               
            </div>

            <Link
              to={"/host/forgot-password"}
              className="forgot-pass forgot_pass close-login"
            >
              {t("forgot_password")}
            </Link>

            <button
              className="pink-btn bottom1 block cmn-btn cmn_btn mt-1"
              disabled={this.state.buttonDisable}
            >
              {" "}
              {this.state.loadingContent != null
                ? this.state.loadingContent
                : t("login")}
            </button>
           
            <button
                       className="pink-btn pink_btn bottom1 block cmn-btn cmn_btn mt-1">
                       <img class="img-fluid" srcset="../../../assets/img/Frame.png" />
                      &nbsp; Sign up with Google
            
                  </button>
          </form>
          {/* <p className="line" />       */}
          <h4 className="m-0 text-left text_left captalize">
            {t("do_not_have_an_account")}{" "}
            <Link to={"/host/register"} className="bold-cls bold_cls  close-login">
              {" "}
              {/* {t("signup")} */}
              
              Sign up for free
            </Link>
          </h4>
        </div>
      </div>
      </div>

      <div className="col-lg-6 col-md-6 logincontainer login_centered ">
          <img src="../../../assets/img/login44.png" className="img-fluid" />
          <div className="logincentered login_centered">
            <h1 id="heading"  style={{color:'white',textAlign:'left'}}>Discover your dream space in prime location</h1>
            </div>

        
        </div>
      </div>
     </div>
          
    );
  }
}

export default withToastManager(translate(HostLogin));
