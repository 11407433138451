import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import "./NewListing.css";
import NewListingStep1 from "./NewListingStep1";
import NewListingStep2 from "./NewListingStep2";
import NewListingStep3 from "./NewListingStep3";
import NewListingStep4 from "./NewListingStep4";
import NewListingStep5 from "./NewListingStep5";
import NewListingStep6 from "./NewListingStep6";
import NewListingStep7 from "./NewListingStep7";
import NewListingStep8 from "./NewListingStep8";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchHostDetailsStart } from "../../store/actions/HostAction";
import { ProSidebarProvider } from 'react-pro-sidebar';
import SideBar from "../HostLayout/sideBar";


const NewListingIndex = (props) => {

    const [step, setStep] = useState(1);

    useEffect(() => {
        setStep(1)
        props.dispatch(fetchHostDetailsStart({
            host_id: props.match.params.id,
        }));
    }, []);
    
    return (
        <>
        <div className="container-fluid host-main">

                <div className="row">
                    <div><ProSidebarProvider>  <SideBar /></ProSidebarProvider></div>
                    <div className="ml-3 inner-container" >
                        <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>{t("dashboard")}</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">                       

            
                            <div className="new-listing-sec new-listing-sec-custom">
                                <div className="new-listing-left-sec">
                                   
                                    <div className="new-listing-content-sec">
                                        <h2>
                                            What kind of place will you host?
                                        </h2>
                                    </div>
                                </div>
                                <div className="new-listing-right-sec">
                                    
                                    {step == 1 && (
                                        <NewListingStep1 setStep={setStep} hostId={props.match.params.id}/>
                                    )}
                                    {step == 2 && (
                                        <NewListingStep2 setStep={setStep} hostId={props.match.params.id}/>
                                    )}
                                    {step == 3 && (
                                        <NewListingStep3 setStep={setStep} />
                                    )}
                                    {step == 4 && (
                                        <NewListingStep4 setStep={setStep} />
                                    )}
                                    {step == 5 && (
                                        <NewListingStep5 setStep={setStep} hostId={props.match.params.id}/>
                                    )}
                                    {step == 6 && (
                                        <NewListingStep6 setStep={setStep} hostId={props.match.params.id}/>
                                    )}
                                    {step == 7 && (
                                        <NewListingStep7 setStep={setStep} hostId={props.match.params.id}/>
                                    )}
                                    {step == 8 && (
                                        <NewListingStep8 setStep={setStep} />
                                    )}
                                </div>
                            </div>
                        </div>            
                    </div>
                </div>
                </div>            
            </div>     
        </>
    );
};

const mapStateToPros = (state) => ({
    host:state.host.host
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingIndex));