import React, { Component } from "react";
import HostProfileSideBar from "./hostProfileSideBar";
import api from "../../../HostEnvironment";
import { translate, t } from "react-multi-lang";
import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";


class HostReview extends Component {
  state = {
    reviewByYou: null,
    reviewAboutYou: null,
    loadingReviewByYou: true,
    loadingReviewAboutYou: true
  };
  componentDidMount() {
    api.postMethod("reviews_for_you").then(response => {
      if (response.data.success === true) {
        this.setState({
          reviewAboutYou: response.data.data,
          loadingReviewAboutYou: false
        });
      }
    });
    api.postMethod("reviews_for_users").then(response => {
      if (response.data.success === true) {
        this.setState({
          reviewByYou: response.data.data,
          loadingReviewByYou: false
        });
      }
    });
  }
  render() {
    const {
      loadingReviewAboutYou,
      loadingReviewByYou,
      reviewAboutYou,
      reviewByYou
    } = this.state;
    return (
     <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                      <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Account Settings</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                          <HostProfileSideBar />
                          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                            <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#home"
                                >
                                  {t("reviews_about_you")}
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#menu1">
                                  {t("reviews_by_you")}
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div id="home" className="tab-pane active">
                                <br />
                                <div className="panel">
                                  <div className="panel-heading">{t("past_reviews")} </div>
                                  {loadingReviewAboutYou
                                    ? ( "" )
                                    : reviewAboutYou != "" ? (
                                    <div>
                                      {reviewAboutYou.map(review => (
                                        <div
                                          key={review.booking_user_review_id}
                                          className="panel-body account"
                                        >
                                          <div className="media">
                                            <img
                                              src={review.user_picture}
                                              className="align-self-center mr-3 rounded-circle review-img1"
                                            />
                                            <div className="media-body">
                                              <h4 className="medium-cls mb-0">
                                                {review.user_name}
                                              </h4>
                                              <p className="m-0">{review.created_at}</p>
                                            </div>
                                          </div>
                                          <h4 className="captalize mt-2 mb-0 lh-1-4">
                                            {review.review}
                                          </h4>
                                        </div>
                                      ))}
                                      </div>
                                  ) : (
                                    <p className="text-center">{t("no_reviews_found")}</p>
                                  )}
                                </div>
                              </div>
                              <div id="menu1" className="tab-pane fade">
                                <br />
                                <div className="panel">
                                  <div className="panel-heading">{t("reviews_to_write")} </div>
                                  {loadingReviewByYou
                                    ? ( "" )
                                    : reviewByYou != "" ? (
                                    <div>
                                      {reviewByYou.map(review => (
                                        <div
                                          key={review.booking_provider_review_id}
                                          className="panel-body account"
                                        >
                                          <div className="media">
                                            <img
                                              src={review.user_picture}
                                              className="align-self-center mr-3 rounded-circle review-img1"
                                            />
                                            <div className="media-body">
                                              <h4 className="medium-cls mb-0">
                                                {review.user_name}
                                              </h4>
                                              <p className="m-0">{review.created_at}</p>
                                            </div>
                                          </div>
                                          <h4 className="captalize mt-2 mb-0 lh-1-4">
                                            {review.review}
                                          </h4>
                                        </div>
                                      ))}
                                      </div>
                                  ) : (
                                    <p className="text-center">{t("no_reviews_found")}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        </div>
      </div>
    );
  }
}

export default translate(HostReview);
