import React, { Component } from "react";

import { Link } from "react-router-dom";

import Helper from "../../Helper/Helper";
import Login from "../../Auth/Login";

import Register from "../../Auth/Register";
import $ from "jquery";
import api from "../../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import SearchForm from "../../Helper/searchForm";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";

class FixedHeaderWithSectionLogin extends Helper {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      loadingCategory: true,
      data: {},
      isMapVisible: false,
      searchInput: null,
      searchResult: null,
      searchSuggesstionResult: null
    };
  }

  componentDidMount() {
    // Call api function
    // this.getCategory();
  }

  render() {
    const { loadingCategory } = this.state;

    // const count = Object.keys(this.state.data).length;
    let renderDetails;
    if (!localStorage.getItem("userLoginStatus")) {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item dropdown">
            <Link className="nav-link nav_links dropdown-toggle" to={"/host"}>
              {t("become_a_host")}
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link nav_links new_login_nav_btn"
              to="/login"
              
            >
              {t("login")}
            </Link>
          </li>


          <li className="nav-item get_strt_b_btn">
            <Link className="nav-link nav_links" to="/register">

              {/* {t("signup")} */}
              Get Started Free
            </Link>
          </li>
         
          
        </React.Fragment>
      );
    } else {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item dropdown">
            <Link
              className="nav-link nav_links dropdown-toggle"
              to={"/wishlist"}
              id="navbardrop"
            >
              {t("wishlist")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              to={"/history"}
              className="nav-link nav_links dropdown-toggle"
              id="navbardrop"
            >
              {t("trips")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link  nav_links dropdown-toggle"
              to={"/inbox"}
              id="navbardrop"
            >
              {t("messages")}
            </Link>
          </li>
          {/* <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
            >
              {t("credits")}
            </Link>
            <div className="dropdown-menu become-host">
              <h5 className="captalize medium-cls">
                Get $1,100 for every friend you invite to RentRoom.{" "}
                <Link to="#"> See terms.</Link>
              </h5>
              <Link to="#" className="green-btn-small top">
                {t("invite_friend")}
              </Link>
            </div>
          </li> */}

          <li className="nav-item mt-3 dropdown">
            <Link
              to="#"
              className="nav-link1 dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
            >
              <img
                src={
                  localStorage.getItem("user_picture") === null
                    ? "../assets/img/user-pic.png"
                    : localStorage.getItem("user_picture")
                }
                className="profile-img"
              />
            </Link>
            <div className="dropdown-menu profile-drop">
              <Link to={"/edit-profile"} className="item">
                <div className="msg-head">
                  <h5>{t("edit_profile")}</h5>
                </div>
                <p className="msg-line" />
              </Link>
              <Link to={"/notification"} className="item">
                <div className="msg-head">
                  <h5>{t("account_settings")}</h5>
                </div>
                <p className="msg-line" />
              </Link>
              <Link to={"/logout"} className="item">
                <div className="msg-head">
                  <h5>{t("logout")}</h5>
                </div>
                <p className="msg-line" />
              </Link>
            </div>
          </li>
        </React.Fragment>
      );
    }

    return (
      <div >
        <div className="">

        <header id="navbar" className="">
          <nav className="navbar navbar-expand-xl landing_nav_sett navbar-light">
            <Link className="navbar-brand" to="/">
              <img 
              className="img-fluid"
                data-src={window.location.origin + "/assets/site/favicon.png"}
              src={window.location.origin + "/assets/img/logo.png"}
                alt={configuration.get("configData.site_name")}
              />
            </Link>

            

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <i className="fas fa-chevron-down" />
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="collapsibleNavbar"
            >
              <ul className="navbar-nav navbar_ul">{renderDetails} </ul>
            </div>
          </nav>
        </header>
        </div>

        <div className="header-height" />

        
       
      </div>
    );
  }
}

export default withToastManager(translate(FixedHeaderWithSectionLogin));
