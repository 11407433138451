import React, { Component } from "react";
import HostProfileSideBar from "./hostProfileSideBar";
import HostHelper from "../../Helper/hostHelper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../../Helper/toaster";
import api from "../../../HostEnvironment";
import { translate, t } from "react-multi-lang";
import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";
class HostNotification extends HostHelper {
  state = {
    data: null,
    loading: true,
    inputData: {}
  };

  componentDidMount() {
    this.getHostDetails();
  }

  handleChange = ({ currentTarget: input }) => {
    const inputData = { ...this.state.inputData };

    if (input.checked) {
      inputData["type"] = input.name;
      inputData["status"] = 1;
    } else {
      inputData["type"] = input.name;
      inputData["status"] = 0;
    }
    this.setState({ inputData });

    this.notificationUpdateApiCall(inputData);
  };

  notificationUpdateApiCall = inputData => {
    api.postMethod("notification/settings", inputData).then(response => {
      if (response.data.success) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
      }
    });
  };
  render() {
    const { loading, data } = this.state;
    return (
      <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                      <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Account Settings</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                          <HostProfileSideBar />

                          {loading ? (
                            "Loading..."
                          ) : (
                            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                              <form>
                                <div class="panel">
                                  <div class="panel-heading">{t("notifications")}</div>
                                  <div class="panel-body account">
                                    <h5 class="m-0 captalize lh-1-4">
                                      {t("notifications_note")} 
                                    </h5>
                                    <p class="overview-line" />

                                    <label class="form-checkbox">
                                      {t("email")}
                                      <input
                                        type="checkbox"
                                        onChange={this.handleChange}
                                        name="email"
                                        defaultChecked={data.email_notification_status}
                                      />
                                      <span class="checkmark" />
                                    </label>

                                    <label class="form-checkbox">
                                      {t("notifications")}
                                      <input
                                        type="checkbox"
                                        onChange={this.handleChange}
                                        name="push"
                                        defaultChecked={data.push_notification_status}
                                      />
                                      <span class="checkmark" />
                                    </label>
                                    <h5 class="profile-note ml-35">
                                      {t("to_your_mobile_or_tablet_device")}
                                    </h5>
                                  </div>
                                </div>
                              </form>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                   </div>
                  </div>
    );
  }
}

export default withToastManager(translate(HostNotification));
