import React, { Component } from "react";

import HomeHeader from "./Headers/HomeHeader";

import FloatingFooter from "./Footers/FloatingFooter";

// This Layouts used in Home page Header,  floating footer and content

class LandingLayout extends Component {
    constructor(props) {
        super(props);
        this.eventEmitter = this.props.screenProps;
    }

    render() {
        return (
            <div className="wrapper">
                <div>
                    <HomeHeader {...this.props.children.props} />

                    {React.cloneElement(this.props.children, {
                        eventEmitter: this.eventEmitter
                    })}
                </div>
            </div>
        );
    }
}
export default LandingLayout;
