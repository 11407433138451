import React, { Component } from "react";
import SingleTripPageMap from "../../Helper/singleTripPageMap";
import { translate, t } from "react-multi-lang";

class TripLocation extends Component {
  state = {};
  render() {
    const { location } = this.props;
    return (
      <div id="location">
        {/* <h4 className="collapse-head captalize">
          {location.host_location}'s Guidebook
        </h4> */}
         <h4 className="collapse-head similar_listing_heading">
          Map
        </h4>
        <div
          className="map-section top"
          style={{
            position: "relative",
            height: "330px"
          }}
        >
          <div>
          <SingleTripPageMap location={location} />
        </div>
        </div>
        {/* <h4 className="map-text">
          {t("trip_location_para")}
        </h4> */} 
        {/* <i className="fab fa-twitter text-dark"></i > */}
         <a href="">See more listings in Houston <img className="img-fluid" srcSet="../../../assets/img/less.png"/> </a>
           <hr />
      </div>
   
    );
  }
}

export default translate(TripLocation);
