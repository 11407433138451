import React from "react";

import { Link } from "react-router-dom";

import api from "../../../Environment";

import Login from "../../Auth/Login";

import Register from "../../Auth/Register";

import Helper from "../../Helper/Helper";
// import SavedDropdown from "../../Helper/savedDropdown";

import SearchForm from "../../Helper/searchForm";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";

class FixedHeader extends Helper {
  // constructor(props) {
  //   super(props);
  // }

  state = {
    categories: null,
    loadingCategory: true,
    loadingWishlist: true,
    wishLists: null,
    data: {}
  };

  componentDidMount() {
    // Call api function
    // this.getCategory();
  }

  handleSaveDropdown = event => {
    event.preventDefault();

    api.postMethod("wishlist").then(response => {
      if (response.data.success === true) {
        this.setState({
          wishLists: response.data.data,
          loadingWishlist: false
        });
      }
    });
  };

  render() {
    // const count = Object.keys(this.state.data).length;

    const { loadingCategory, loadingWishlist, wishLists } = this.state;
    let renderDetails;
    if (!localStorage.getItem("userLoginStatus")) {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item ">
            <Link className="nav-link nav_links" to="/host">
              Become A Host
            </Link>
          </li>
          {/*<li className="nav-item">
            <Link
              className="nav-link"
              to="#"
              data-toggle="modal"
              data-target="#signup"
            >
              {t("signup")}
            </Link>
          </li> */}

        <li className="nav-item">
            <Link
              className="nav-link nav_links new_login_nav_btn"
              to="/login"
              
            >
              {t("login")}
            </Link>
          </li>


          <li className="nav-item get_strt_b_btn">
              <Link className="nav-link nav_links " to="/register">

                {/* {t("signup")} */}
                Get Started Free
              </Link>
            </li>


       

        </React.Fragment>
      );
    } else {
      renderDetails = (
        <React.Fragment>
          <li className="nav-item dropdown">
            <Link
              className="nav-link nav_links navlink_color dropdown-toggle"
              to={"/wishlist"}
              id="navbarwishdrop"
              // onClick={this.handleSaveDropdown}
              // data-toggle={loadingWishlist ? "" : "dropdown"}
            >
              {t("wishlist")}
            </Link>
            {/* {loadingWishlist ? "" : <SavedDropdown wishLists={wishLists} />} */}
          </li>
          <li className="nav-item dropdown">
            <Link
              to={"/history"}
              className="nav-link nav_links  dropdown-toggle"
              id="navbardrop"
            >
            
              {t("trips")}
            </Link>
          </li>
          <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle nav_links"
              to={"/inbox"}
              id="navbardrop"
            >
              {t("messages")}
            </Link>
          </li>
          {/* <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
            >
              {t("credits")}
            </Link>
            <div className="dropdown-menu become-host">
              <h5 className="captalize medium-cls">
                Get $1,100 for every friend you invite to RentRoom.{" "}
                <Link to="#"> See terms.</Link>
              </h5>
              <Link to="#" className="green-btn-small top">
                {t("invite_friend")}
              </Link>
            </div>
          </li> */}

          <li className="nav-item mt-3 dropdown">
            <Link
              to="#"
              className="nav-link1 dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
            >
              <img
                src={
                  localStorage.getItem("user_picture") === null
                    ? "../assets/img/user-pic.png"
                    : localStorage.getItem("user_picture")
                }
                className="profile-img"
              />
            </Link>
            <div className="dropdown-menu profile-drop">
              <Link to={"/edit-profile"} className="item">
                <div className="msg-head">
                  <h5>{t("edit_profile")}</h5>
                </div>
                <p className="msg-line" />
              </Link>
              <Link to={"/notification"} className="item">
                <div className="msg-head">
                  <h5>{t("account_settings")}</h5>
                </div>
                <p className="msg-line" />
              </Link>
              <Link to={"/logout"} className="item">
                <div className="msg-head">
                  <h5>{t("logout")}</h5>
                </div>
                <p className="msg-line" />
              </Link>
            </div>
          </li>
        </React.Fragment>
      );
    }
    return (
      <div className="">
        <nav
          className="navbar  navbar-expand-lg navbar-light landing_nav_sett"
          id="navbar"
        >
          <Link className="navbar-brand" to={"/"}>
            <img
              data-src={window.location.origin + "/assets/site/favicon.png"}
              src={window.location.origin + "/assets/img/logo.png"}
              alt={configuration.get("configData.site_name")}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <i className="fas fa-chevron-down" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav navbar_ul">{renderDetails}</ul>
          </div>
        </nav>

        <div className="header-height" />
        <Login {...this.props.props} />
        <Register {...this.props.props} />
      </div>
    );
  }
}

export default translate(FixedHeader);
