import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class Footera extends Component {
    state = {};
    render() {
        return (


<div className="footer_Booking">
 <footer className="bg_light_green bg_light_green_new_footer">
 <footer class="mainfooter" role="contentinfo">
  <div className="footer-middle">
  <div className="container">
    <div className="row footer_row">
      <div className="col-md-3 col-sm-6">
       
        <div className="footer-pad">
        <div className="">
            <span className="mb-4"><img className="img-fluid" srcSet="../../../assets/new_imgs/footer_logo.png" /></span>
            </div>

            <div className="lists">
            <p >
              <a className="text-white text-dark" href="/host">Become a Host</a>
            </p>
            <p>
              <a className="text-white text-dark" a href="#">Careers</a>
            </p>
            <p>
              <a className="text-white text-dark" a href="#">Reviews</a>
            </p>
            <p>
              <a className="text-white text-dark" a href="#">Affiliate Partners</a>
            </p>
            </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-6">
      
        <div className="footer-pad footer_pad">
        <h6 className=" mb-4 font-weight-bold new_footer_headings">Categories</h6>
        <div className="lists mt-4">
            <p >
              <a className="text-white text-dark"href="category/Attic/3/CATEGORY">Attic</a>
            </p>
            <p>
              <a className="text-white text-dark" href="/category/Basement/1/CATEGORY">Basement</a>
            </p>
            <p>
              <a className="text-white text-dark" href="http://localhost:3000/category/Commercial/6/CATEGORY">Commerical</a>
            </p>
            <p>
              <a className="text-white text-dark"href="/category/:name/:id/:url_type">All categories</a>
            </p>
            </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-6">
      
        <div className="footer-pad footer_pad">
        <h6 className=" mb-4 font-weight-bold new_footer_headings">Support</h6>
          
            
        <div className="lists mt-4">
            <p >
              <a className="text-white text-dark" a href="#">Help Center</a>
            </p>
            {/* <p>
              <a className="text-white text-dark" a href="#">Help Center</a>
            </p> */}
            <p>
              <a className="text-white text-dark" a href="#">Trust and Saftey</a>
            </p>
            {/* <p>
              <a className="text-white text-dark">Bootstrap Angular</a>
            </p> */}
            </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-6">
      
      <div className="footer-pad footer_pad">
      <h6 className=" mb-4 font-weight-bold new_footer_headings">Get the app</h6>
        {/* <ul className="list-unstyled">
          <li><a href="#" className="text-white text-dark">Parks and Recreation</a></li>
          <li><a href="#" className="text-white text-dark">Public Works</a></li>
          <li><a href="#" className="text-white text-dark">Police Department</a></li>
          <li><a href="#" className="text-white text-dark">Fire</a></li>
          <li><a href="#" className="text-white text-dark">Mayor and City Council</a></li>
          <li>
            <a href="#"></a>
          </li>
        </ul> */}
         <div className="lists mt-4">
            {/* <p >
              <a className="text-white text-dark">MDBootstrap</a>
            </p> */}
          
            <div className="">
           
            <span className="mb-4"> <a href="#"><img className="img-fluid" srcSet="../../../assets/img/apple_s.png" /></a></span>
            
            </div>
            
           
            <div className="pt-3">
            <span className="mb-4"><a href="#"><img className="img-fluid" srcSet="../../../assets/img/gp.png" /></a></span>
            </div>
          
        
            </div>
      </div>
    </div>
    </div>
	{/* <div className="row">
		<div className="col-md-12 copy">
			<p className="text-center">&copy; Copyright 2018 - Company Name.  All rights reserved.</p>
		</div>
	</div> */}
   <div className=" row text-center text-white footer_copy  content-site">
                      <div className="col-md-6">
                      <div className="icons_link_sec">
                      <div className="footers_icons">
                        <a href="#"> <i className="fab fa-twitter text-dark"></i> </a>
                        <a href="#"><i className="fab fa-facebook-f text-dark"> </i></a>             
                        <a href="#"> <i className="fab fa-instagram text-dark"> </i></a> 
                        <a href="#"> <i class="fab fa-github text-dark"></i></a>   
                       </div>

                    <div className="new_footer_link">
                      <div className="link1">
                        <a href="/Policy" className="text-dark">Privacy</a>
                      </div>
                      <div className="link1">
                        <a href="#" className="text-dark">Terms & Condition</a>
                      </div>
                      <div className="link1">
                        <a href="#" className="text-dark">Support</a>
                      </div>
                    </div>
                    </div>
                      </div>
                      <div className="col-md-6">
                      <div className="copyright text-dark">
                        {/* {t("footer_text_a")} */}
                        © Copyright 2023, All Rights Reserved by TrueOne Tech, Inc. 
                    </div>
                      </div>
                    
                    
            
            
    </div>


  </div>
  </div>
</footer>



             
   </footer>
    </div>
        );
    }
}

export default translate(Footera);
