import React, { Component } from "react";
import HostProfileSideBar from "./hostProfileSideBar";
import api from "../../../HostEnvironment";
import ToastDemo from "../../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";
import HostAddCardForm from "./hostAddCardForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import configuration from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";


class HostCard extends Component {
  state = {
    data: null,
    loading: true,
  };
  componentDidMount() {
    this.listCardApi();
  }

  listCardApi() {
    api.postMethod("cards_list").then((response) => {
      if (response.data.success) {
        this.setState({
          data: response.data.data,
          loading: false,
        });
      } else {
      }
    });
  }

  setDefaultCard = (event, card) => {
    event.preventDefault();
    api
      .postMethod("cards_default", { provider_card_id: card.provider_card_id })
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.listCardApi();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  deleteCard = (event, card) => {
    event.preventDefault();
    api
      .postMethod("cards_delete", { provider_card_id: card.provider_card_id })
      .then((response) => {
        if (response.data.success) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.listCardApi();
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      });
  };

  render() {
    const { loading, data } = this.state;
    return (
      <StripeProvider
        apiKey={configuration.get("configData.stripe_publishable_key")}
      >
        <Elements>
          <div className="main host-main">
            <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                      <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Account Settings</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                          <HostProfileSideBar />
                          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                            <form>
                              <div className="panel">
                                <div className="panel-heading">{t("payment_method")} </div>
                                <div className="panel-body account pt-3">
                                  <div className="row">
                                    {loading
                                      ? ""
                                      : data.cards.map((card) => (
                                          <div
                                            className="col-sm-12 col-md-6 col-lg-6 col-xl-4 top"
                                            key={card.provider_card_id}
                                          >
                                            <div className="payment-box">
                                              <h5>XXXX XXXX XXXX {card.last_four}</h5>
                                              <div className="payment-bottom">
                                                {card.is_default == 1 ? (
                                                  <p className="captalize m-0">
                                                    {t("default_card")}
                                                    <img
                                                      src="../assets/img/credit-card.png"
                                                      className="credit-img"
                                                    />
                                                  </p>
                                                ) : (
                                                  <div>
                                                    <Link
                                                      to="#"
                                                      onClick={(event) =>
                                                        this.setDefaultCard(event, card)
                                                      }
                                                    >
                                                      <p className="red-text1 m-0">
                                                        {t("set_as_default")}
                                                      </p>
                                                    </Link>
                                                    <Link
                                                      to="#"
                                                      onClick={(event) =>
                                                        this.deleteCard(event, card)
                                                      }
                                                    >
                                                      <p className="captalize m-0">
                                                        {t("remove")}
                                                        <img
                                                          src="../assets/img/credit-card.png"
                                                          className="credit-img"
                                                        />
                                                      </p>{" "}
                                                    </Link>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ))}

                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 top">
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#AddCardModel"
                                      >
                                        <div className="payment-box text-center">
                                          <i className="material-icons big-icon">{t("add")}</i>
                                          <h4 className="captalize top">
                                            {t("add_card_details")}
                                          </h4>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                  <HostAddCardForm {...this.props} />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>  
        </Elements>
      </StripeProvider>
    );
  }
}

export default withToastManager(translate(HostCard));
