import React, { Component } from "react";
import api from "../../../Environment";
import { translate, t } from "react-multi-lang";

import { Link } from "react-router-dom";

import configuration from "react-global-configuration";

class HostFooter extends Component {
    state = {
        footer_first_section: [],
        footer_second_section: [],
        footer_third_section: [],
        footer_fourth_section: []
    };
    constructor(props) {
        super(props);

        // States and props usage
    }

    componentDidMount() {
        // Call api function

        if (configuration.get("configData.footer_first_section")) {
            this.setState({
                footer_first_section: configuration.get(
                    "configData.footer_first_section"
                )
            });
        }

        if (configuration.get("configData.footer_second_section")) {
            this.setState({
                footer_second_section: configuration.get(
                    "configData.footer_second_section"
                )
            });
        }

        if (configuration.get("configData.footer_third_section")) {
            this.setState({
                footer_third_section: configuration.get(
                    "configData.footer_third_section"
                )
            });
        }

        if (configuration.get("configData.footer_fourth_section")) {
            this.setState({
                footer_fourth_section: configuration.get(
                    "configData.footer_fourth_section"
                )
            });
        }
    }

    render() {
        return (
           
            //  <div className=" footer_Booking ">
            //     <footer className="bg_light_green    border-top">

            //         <section className="section_footer">
            //             <div className="mt-5">
            //                 <div className="row justify-content-between mt-3">
            //                     <div className="col-md-3 mb-4">

            //                         <span className="mb-4"><img className="img-fluid" srcSet="../../../assets/img/footer_logo.png" /></span>

            //                         <div className="d_grid_footer mt-5 pt-3">
            //                             <img className="img-fluid" srcSet="../../../assets/img/footer_custmor.png" />
            //                             <div className="">
            //                                 <h5 className="fw_bolder text-dark">24/7 Customer Support</h5>
            //                                 <p className="mb-0">
            //                                     If you need help while travelling or
            //                                     hosting, contact us at our toll free
            //                                     number: 000 800 4405 103
            //                                 </p>
            //                             </div>
            //                         </div>
            //                     </div>

            //                     <div className="col-md-3 mb-4">

            //                         <div className="d_grid_footer ">
            //                             <img className="img-fluid" srcSet="../../../assets/img/footer_custmor.png" />
            //                             <div className="">
            //                                 <h5 className="fw_bolder text-dark">24/7 Customer Support</h5>
            //                                 <p className="mb-0">
            //                                     If you need help while travelling or
            //                                     hosting, contact us at our toll free
            //                                     number: 000 800 4405 103
            //                                 </p>
            //                             </div>
            //                         </div>
            //                         <div className="d_grid_footer mt-5 pt-3">
            //                             <img className="img-fluid" srcSet="../../../assets/img/footer_custmor.png" />
            //                             <div className="">
            //                                 <h5 className="fw_bolder text-dark">24/7 Customer Support</h5>
            //                                 <p className="mb-0">
            //                                     If you need help while travelling or
            //                                     hosting, contact us at our toll free
            //                                     number: 000 800 4405 103
            //                                 </p>
            //                             </div>
            //                         </div>
            //                     </div>
            //                     <div className="col-md-3  mb-4 footer_img_right">
            //                         <h1 className="text-dark fw_bolder">
            //                             Get Your App Nowss
            //                         </h1>
            //                         <div className="mt-5">
            //                             <img srcSet="../../../assets/img/footer_apple.png" />
            //                         </div>
            //                         <div className="mt-4">
            //                             <img srcSet="../../../assets/img/footer_play_store.png" />
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </section>
            //         <div className="text-center bg_success text-white p-2">
            //             {t("footer_text_a")}
            //         </div>

            //     </footer>
            // </div>
 <div className="footer_Booking site-content">
  <footer className="bg_light_green bg_light_green_new_footer">
    <div className="container">            
    <div className="row">
        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <div className="">
            <span className="mb-4"><img className="img-fluid" srcSet="../../../assets/new_imgs/footer_logo.png" /></span>
            </div>
            <div className="footer_list mt-4">
            <p >
              <a className="text-white text-dark">MDBootstrap</a>
            </p>
            <p>
              <a className="text-white text-dark">MDWordPress</a>
            </p>
            <p>
              <a className="text-white text-dark">BrandFlow</a>
            </p>
            <p>
              <a className="text-white text-dark">Bootstrap Angular</a>
            </p>
            </div>
          </div>
          <hr className="w-100 clearfix d-md-none" />
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold new_footer_headings">Help</h6>
            <div className="lists mt-4">
            <p >
              <a className="text-white text-dark">MDBootstrap</a>
            </p>
            <p>
              <a className="text-white text-dark">MDWordPress</a>
            </p>
            <p>
              <a className="text-white text-dark">BrandFlow</a>
            </p>
            <p>
              <a className="text-white text-dark">Bootstrap Angular</a>
            </p>
            </div>
          </div>
          <hr className="w-100 clearfix d-md-none" />

          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold new_footer_headings">
            Resources
            </h6>
            <div className="lists mt-4">
            <p >
              <a className="text-white text-dark">MDBootstrap</a>
            </p>
            <p>
              <a className="text-white text-dark">MDWordPress</a>
            </p>
            <p>
              <a className="text-white text-dark">BrandFlow</a>
            </p>
            <p>
              <a className="text-white text-dark">Bootstrap Angular</a>
            </p>
            </div>
          </div>
          <hr className="w-100 clearfix d-md-none" />
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold new_footer_headings">Extra Links</h6>
            <div className="lists mt-4">
            <p >
              <a className="text-white text-dark">MDBootstrap</a>
            </p>
            <p>
              <a className="text-white text-dark">MDWordPress</a>
            </p>
            <p>
              <a className="text-white text-dark">BrandFlow</a>
            </p>
            <p>
              <a className="text-white text-dark">Bootstrap Angular</a>
            </p>
            </div>
          </div>
        

    </div>


                

  <div className=" row text-center text-white footer_copy ">
                      <div className="col-md-6">
                      <div className="icons_link_sec">
                      <div className="footers_icons">
                        <a href=""> <i className="fab fa-twitter text-dark"></i ></a>
                        <a href="" ><i className="fab fa-facebook-f text-dark"></i></a>             
                       <a href="">  <i className="fab fa-instagram text-dark"></i></a> 
                       <a href=""><i class="fab fa-github text-dark"></i></a>   
                       </div>

                    <div className="new_footer_link">
                      <div className="link1">
                        <a href="" className="text-dark">Privacy</a>
                      </div>
                      <div className="link1">
                        <a href="" className="text-dark">Terms & Condition</a>
                      </div>
                      <div className="link1">
                        <a href="" className="text-dark">Support</a>
                      </div>
                    </div>
                    </div>
                      </div>
                      <div className="col-md-6">
                      <div className="copyright text-dark">
                        {t("footer_text_a")}
                    </div>
                      </div>
                    
                    
            
  </div>
  </div>         
                </footer>
            </div>
        );
    }
}

export default translate(HostFooter);
