import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class Policy extends Component {
    state = {};
    render() {
        return (
            <div>
               <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="policy_content">
                        <h1>Privacy Policy </h1>
                    <p>Effective Date: May 11, 2023</p>
                   <p>TrueOne Tech, Inc. <b> (“Spaceazy,” “Company,” “we,” “our,” or “us”) </b> are committed to protecting and respecting your privacy. </p>
                   <p>We welcome you and hope you find our websites, mobile and web applications, products, and our other subscription services and tools (collectively, the <b> “Services”</b>) helpful and useful. We have adopted this privacy policy (“Privacy Policy”) to help our website visitors, mobile application users, current and potential customers, clients, their employees, our employees, and other business partners <b>(“you” or “your,”)</b> understand what Data we collect, store, share, and use, how and why we do so, and what your rights are regarding that Data.
                        </p>
                        <p>If a capitalized term is not specifically defined in this Privacy Policy, it has the meaning given to it in our Terms of Service.</p>

                        <p>We always seek to improve our Services to you, and that requires that we collect, store, share, and use information about you and your usage preferences. As we do so, we are absolutely committed to protecting your privacy and the security of your personal information.</p>
                        <p>In this Privacy Policy, we use the word “Data” to describe all the information we collect that relates to you and your use of our Services. “Data” is broken into different categories, which are defined in the “Data We Collect and How We Use It” section of this Privacy Policy. We may refer to the different categories separately, but when we use the word “Data,” we mean all the different categories described in this Privacy Policy. The term “Data” does not apply to de-identified, anonymized, and aggregated data that may be derived from Data, such as traffic patterns, search activity, and other information that cannot be reasonably connected with any individual (“De-identified Data”). We may use De-identified Data for our own purposes in any manner and without attribution or compensation to any person</p>
                        <p>With that exception, this Privacy Policy applies to everybody who interacts with us online or otherwise. Since different portions of the Privacy Policy will apply differently to the various groups who interact with us, we have tried to clearly categorize the types of Data we process and how we do so. If you have any questions about this Privacy Policy or how we handle your Data, please email us at <a href=""> privacy@spaceazy.com.</a>
                                </p>
                        <h2>SCOPE OF POLICY</h2>
                        <p>This policy (together with the Terms) applies to your use of:</p>
                        <ul>
                        <li>the Spaceazy Storage website from which you can access your Spaceazy Services.</li>

                        </ul>
                        <p>Please note that this policy does not apply to third party websites which you may access in connection with your use of the InControl Package or to services or features which you sign up to receive directly with a third party – please refer to the relevant third party’s privacy policy.</p>
                        <h2>DESCRIPTION OF SERVICES</h2>
                        <p>We provide a platform or marketplace with related technology for Renters and Hosts to meet online and arrange for Bookings of storage including, but not limited to, basements, garages, attics, closets, sheds, storage units, driveways, parking spots, or other Space. In this Privacy Policy, all the tools and services made available in connection with the Services, including our mobile and web applications, tools, and any other services that we provide directly to you, whether now known or developed later, are included in the term <b> “Services.”</b>
                        <h2>LAWFUL BASIS FOR PROCESSING</h2>
                        <p>Many jurisdictions require that we disclose to you the lawful basis for our processing of your Data. We do that in the “Data We Collect and How We Use It” section and otherwise throughout this Privacy Policy.
In general, our lawful basis for processing your Data is based on your specific consent or your contract with us.
By accessing or using any of the Services or by otherwise interacting with us online, you consent to our use of your Data as described in this Privacy Policy. If our processing of your Data is based on your consent, you may withdraw your consent at any time, and we will cease collecting your Data. However, in some cases, this may result in your inability to receive partial or full access to the Services, and your withdrawal of consent does not limit our ability to use the De-Identified Data for use by us in connection with our legitimate business efforts in the future. In addition, your withdrawal of consent may not prevent us from retaining and processing Data if we have gathered such Data pursuant to a different lawful basis or to preserve legal claims. For example, if you give your consent for us to process your Data, but we are also required by law to keep your Data, that separate “lawful basis” will still apply, even if you withdraw your consent.
When you enter into an agreement with us, either by accessing the Services, by executing an agreement in hard copy or by clicking “I Accept” or similar language online, or through an app store, we will process your Data for the purposes of fulfilling the terms of our contract with you. In that case, our processing of your Data is based on the contract, so your withdrawal of consent will only be effective after the purposes for processing that Data have been fulfilled and after we no longer have a legal obligation to keep that Data.
In all cases, we will comply with applicable law and we will cease processing your Data after the legal right, obligation, or other lawful basis expires.</p>
<h2>INTENDED USERS</h2>
<p>The Services are directed solely to persons 18 years of age or older or of children under 18 who are supervised by a parent, guardian, or other caregiver. Other than for Data collected for the specific purpose of providing the Services to users, we do not knowingly collect Data from users who are under 13. If we become aware that we have gathered Data from a person under 13, except to provide the Services to such person, and except to the extent allowed or required by law, then we will attempt to delete such Data as soon as possible, subject to our obligations under applicable law. If you believe that we have gathered Data from a person under 13 in contravention of this policy, please contact us at privacy@spaceazy.com</p>

</p>
<h2>DATA WE COLLECT AND HOW WE USE IT</h2>
<p>Listed below are the categories of Data we collect when you use our Services. We never sell your Data, and we always have a lawful basis for gathering the Data, but that lawful basis might be different for different categories, and we describe those uses below. Regardless, we never use the Data for any purpose other than the purpose for which we gathered the Data in the first place, unless we get your prior explicit consent.
</p>

<h4>A. Registration Data</h4>
<ul>
    <li>Data Description: You may give us information about you by filling in forms on our site spaceazy.com (our site) or by corresponding with us by phone, e-mail or otherwise. This includes information you provide when you register to use our site, subscribe to our service, search for a host, place a booking on our site, participate in social media functions on our site, enter a competition, promotion or survey, and when you report a problem with our site. The information you give us may include your name, address, e-mail address and phone number, financial and credit card information, personal description, vehicle registration (VRM), and photograph.
</li>
<li>Lawful Basis for Processing: Our lawful basis for processing Registration Data is our contract with you and your consent. We can only provide certain of the Services to you if we have the Registration Data, so we need to store and access that Registration Data during the term of our contract. Even when the Registration Data is not critically necessary to the provision of the Services, we may still process that Registration Data to facilitate our contractual interactions with you.
</li>
<li>How We Use It and Who We Share It With: Registration Data is accessible generally only to us and to you. In limited circumstances, we may share your Registration Data with our service providers, which parties help us provide the Services and are under obligations to protect the confidentiality of your Registration Data. We use it to provide the Services to you. At times, we will share the Registration Data with other third parties at your request or to fulfill requests that you make of us. We may also use your Registration Data to offer our own goods or services to you, either directly through emails or through third party platforms, but you may opt out of those communications at any time. We will never share your username or password with any third party.</li>
</ul>
<h4>B. Engagement Data</h4>
<ul>
    <li>Data Description: Engagement Data consists of all the information you input or record using the Services, except as otherwise stated in this Privacy Policy. It also includes all information that is proprietary to you regarding your use of the Services (other than the data that qualifies as “Usage Data” below) that is collected or processed by the Services. For example, Engagement Data includes payroll information, personal information about employees, and internal company communications, among other things.
</li>
<li>Lawful Basis for Processing: Our lawful basis for processing Engagement Data is (1) our contract with you, (2) our obligation to provide you with the Services and (3) our legitimate interest in improving our Services based on the Engagement Data we receive from you.
</li>
<li>How We Use It and Who We Share It With: Your Engagement Data is accessible to us, to you, and to limited third parties that we use to provide and improve the Services. If we share your Engagement Data with a third Party, that party will be obligated to protect the confidentiality of your Engagement Data. We may also share Engagement Data with other third parties. We also do not de-identify or aggregate Engagement Data for use for any purpose other than to provide the Services to you and to improve our knowledge of how our systems are used.
</li>
<h4>C. Usage Data</h4>
<li>Data Description: Usage Data consists of the following and similar information:</li>
<li>Information about your interactions with the Services, most commonly our website and mobile application, which includes the date and time of any requests you make. This also may include details of your use of Third-Party Applications and any advertising you receive via the Services.
</li>
<li>Adjustments you make to the default state of the Services, such as custom categories or settings.
</li>
<li>The timing of the information you post to the Services including messages you send or receive via the Services and your interactions with our customer service team, but not including the content of those interactions and messages, which would be included as Engagement Data.
</li>
<li>Technical data which may include URL information, cookie data, your IP address, the types of devices you are using to access or connect to the Services, unique device IDs, device attributes, network connection type (e.g. WiFi, 4G, LTE, Bluetooth) and provider, network and device performance, browser type, language, information enabling digital, mouse movements (including scrolling data), and GPS (only upon your express permission).
</li>
<li>	Rights management, operating system, and application version.
</li>
<li>App features, pages viewed, and app crash information.
</li>
<li>	Motion-generated or orientation-generated mobile sensor data (e.g. accelerometer or gyroscope), if any, required for the purposes of providing specific features of the Services to you.
</li>
<li>	Lawful Basis for Processing: Our lawful basis for processing Usage Data is (1) our contract with you and (2) our legitimate interest in improving our Services based on the Usage Data we receive from you.
</li>
<li>
    	How We Use It and Who We Share It With: Usage Data is accessible to us, to you, and to limited third parties that we use to provide and improve the Services. If we share your Usage Data with a third Party, that party will be obligated to protect the confidentiality of your Usage Data. We may also share Usage Data with other third parties at your specific request. We may also use Usage Data to make improvements to the Services. Both during the term of our agreement with you and thereafter, we may also use Usage Data as De-Identified Data, and that De-Identified Data belongs solely to us to use in our sole discretion (including to sell De-Identified Data, which is not Data). To the extent we are required to delete any Usage Data about you, we may still retain aggregated and anonymized information that may have originated as your Usage Data.
</li>
<h4>D. Payment Data</h4>
<li>	Data Description: Payment Data is only collected when your use of the Services is subject to the payment of a fee or other charge. Payment Data is the information necessary for us to process your payments for the Services. Payment Data will vary depending on the payment method you use (e.g. direct via your mobile phone carrier or by invoice) but will include information such as:
</li>
<li>	Name;
</li>
<li>	Date of birth</li>
<li>	Certain credit card information used to reference a credit card; (Please note that we use a third-party provider to collect credit card information. The third party’s collection tool is layered over our shopping cart, so your credit card information never hits our system at all. Further, we only use payment processors that are PCI compliant.)
</li>
<li>	Address and postal code; and
</li>
<li>	Mobile phone number</li>
<li>	Lawful Basis for Processing: Our lawful basis for processing Payment Data is (1) our contract with you and (2) our legitimate interest in improving our Services based on the Payment Data we receive from you.
</li>
<li>	How We Use It and Who We Share It With: We only use Payment Data to facilitate payment, and we only communicate it to those parties who are strictly necessary for that purpose.
</li>

</ul>
<h4>E. Supplemental Mobile Data</h4>
<ul>
    <li>	Data Description: Where applicable to the Services, Supplemental Mobile Data consists of the following and similar information:
</li>
<li>	Your precise mobile device location - If you give us permission to access your precise location, this enables us to access your GPS or Bluetooth to provide location-aware functionality in the Services. Please note that this does not include your IP address. We use your IP address to determine non-precise location, for example, what country you are in to comply with our licensing and other agreements;
</li>
<li>	Your voice data - If you give us permission, this enables us to access the voice commands captured via your device microphone to enable you to interact with the Services with your voice. Please note you will always have the ability to turn off the microphone feature;
</li>
<li>	The Company user profile includes age, gender, and other personal characteristics used for the purpose of delivering certain features; and
</li>
<li>	Your photos - If you give us permission to access your photos or camera, we will only access images that you specifically choose to share with us and metadata related to those images, such as the type of file and the size of the image. We will never scan or import your photo library or camera roll.
</li>
<li>	Lawful Basis for Processing: Our lawful basis for processing Supplemental Mobile Data is (1) our contract with you and (2) our legitimate interest in improving our Services based on the Supplemental Mobile Data we receive from you.
</li>
<li>	How We Use It and Who We Share It With: We only use Supplemental Mobile Data when you specifically authorize it, and we only share it with those parties who are strictly necessary for the purpose you have authorized</li>
</ul>
<h2>SHARING YOUR INFORMATION</h2>
<p>Except where a specific limitation is noted above, we may share your Data as follows:
</p>
<ul>
    <li>	<b>At Your Instruction. </b>If you request us to make your Data available to a third party, and such request furthers the purposes of our Services, we will do so.
</li>
<li>	Sharing with Vendors. In certain cases, we use the services of third-party vendors, to assist us in providing the Services. We may share your Data with such vendors solely for that purpose, and we will require those parties to abide by our privacy policies or privacy policies substantially in consonance with ours.
</li>
<li>
	Hosts and Renters. We may share information between Hosts and Renters to facilitate the booking process and give you access and control during your renting or hosting experience. Such information as your full name, telephone number, email address, and the address of the Space to be rented (if you are a Host) will be shared between the two parties upon a confirmed booking of a Space.

</li>
<li>	Public Listings and Profiles. To advertise your Listings and assure customer safety, we make certain information available for public access via the services. The information on public Listings and Profiles may include information such as your first name, your description of the Space, your general description of yourself, the general area in which your Space is located, a verified ID status (if we have verified your identity via documentation you have provided us), a verified Host status (if you have previously hosted with Spaceazy), and any other information you choose to provide in your public Profile or Listing.
</li>
<li>	Third-Party Offers. We may allow other companies to offer you their products and services, including offers through our Services, co-branded pages hosted by the third parties, or via email. Whether or not you decide to participate in any such offers is up to you. If you purchase a product or service on a co-branded page, or via a third-party offer on our Services that requires you to submit financial and personal information, you are also consenting to our delivery of this information to that party. The offer will notify you if any financial or personally identifiable information will be shared. Such third party will be authorized to use this information in keeping with our contractual relationship with them and in accordance with their own privacy policy and information practices. We do not control these third parties and you agree that we are not liable for their acts, or any failure to act on their part.
</li>
<li>	Third-Party Advertising. We may use aggregated, statistical information to describe our membership and to establish advertising and other business relationships with third parties. We may serve you with targeted advertisements based on your personal or profile information, but we do not provide any of this personal or profile information to an advertiser or any third party with the exception of those uses expressly disclosed in this policy. However, if you click or view an ad on our Services then you consent to the likelihood that the advertiser will assume that you meet the targeting criteria, if any, used to display such ad, and as described above, you will be subject to the advertiser's privacy policy and information collection practices (if any).
</li>
<li>	Service Providers. We may sometimes use a third party to provide specific Services on our behalf, including sending emails to you, conducting surveys, processing transactions or performing statistical analysis of our Services. In these cases, we may provide certain personal information, such as your name and email address and other financial information necessary for the service to be provided. However, these third parties are required to maintain the confidentiality of this information and are prohibited from retaining, sharing, storing or using this information for any other purposes.
</li>
<li>
	Internet Service Providers. We may provide certain portions of your Data, such as your email address or name, back to your internet service provider if we have an existing advertising relationship with them. This is done to allow them to target or discontinue your exposure to our advertisements, once you have become a participating member of our Services. As part of our agreement with your internet service provider, they will be required to maintain this information in a confidential manner and use it solely for the purpose described in this Privacy Policy
</li>
<li>	Business Transitions. In the event that we go through a business transition, such as a merger, acquisition, liquidation, or sale of all or a portion of our assets, the information we have about you will, in most instances, be part of the assets transferred. We reserve the right to transfer that information in connection with such transactions without notice to you. We will not be required to obtain your consent for such a transfer.
</li>
<li>	Legal Disclosure. We may disclose your Information if required to do so by law or in the good faith belief that such action is necessary to conform to applicable law, comply with a judicial proceeding, court order or legal process served on us, protect and defend our rights or property, or investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or violations of our terms of service</li>
</ul>
<p><b>If we ever plan to use any Data in the future for any other purposes not identified above and we do not have a separate lawful basis for that new purpose for processing, we will only do so after obtaining your specific consent.
</b></p>
<p><b>Further, we do not sell your Data for any purpose.
</b></p>
<h2>TECHNOLOGIES WE USE
</h2>
<p>The technologies we use for automatic Data collection may include the following</p>
<ul><li>
	Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting, you may be unable to access certain parts of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services.
</li></ul>
<li>	Flash Cookies. Certain features of our Services may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from and on our Services. Flash cookies are not managed by the same browser settings as are used for browser cookies. For more information on disabling flash cookies, you can visit <a href="#"> https://helpx.adobe.com/flash-player/kb/dsiable-local-shared-object-flash.html.</a>
</li>
<li>	Web Beacons. Pages of the Services and our emails may contain small electronic files known as web beacons (also referred to as clear gifs. pixel tags and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
</li>
<li>	Geolocation. We may use GPS (or other similar) technology when you use our Services, and more specifically any mobile application, to determine your current location. If you do not want us to use your location to provide you the Services, you can turn off your location services on your mobile device in your device's account settings.
</li>
<li>	Widgets. We may use technologies such as widgets or APIs to display parts of our Services (e.g.,your Listing page) on sites that are operated by our business partners. Listings displayed on a business partners’ site only include the information you provide in your public Spaceazy Listings.
</li>
<li>	Other Technologies. We may also use device identifiers, local storage, html modifiers, and different types of caching to help us understand the devices and users who access the Services. Those methods include device identifiers that are either hardware-based or software-based, persistent or non-persistent, and which may identify either a device or a software module within a device (such as a web browser).
</li>
<h2>"Do Not Track" Options</h2>
<p>Your web browser(s) may offer a "Do Not Track" option, which allows the individual to signal to operators of websites and web applications and services (including behavioral advertising services) that he or she does not wish such operators to track certain of his or her online activities over time and across different websites. Our Services do not support Do Not Track requests at this time, which means that we may collect information about your online activity both while you are using the Services and after your use of the Services.
</p>
<h2>YOUR RIGHTS REGARDING YOUR DATA
</h2>
<p>Under applicable data protection, privacy and other laws, you have certain rights related to your access and control of your Data. Such rights may include the following:
</p>
<ul>
    <li>The right to access, correct, update, or request deletion of your Data.
</li>
<li>	The right to object to processing or restrict the processing of your Data. Please note that if you exercise this right, it may limit or eliminate our ability to provide you the Services.
</li>
<li>	The right to request portability of your Data.
</li>
<li>	The right to opt-out of marketing communications we send you. You can exercise this right by clicking the "Unsubscribe" or "Opt-Out" link found in these communications.
</li>
<li>	The right to not be subject to a decision based solely on automated processing, including profiling, known as Automatic Decision Making.
</li>
<li>	The right to submit a complaint to any applicable regulatory authority about our processing activities.
</li>
<li>	The right to opt-out of us sharing (as defined in the California Privacy Rights Act) your Data, including for direct marketing purposes, subject to certain legal exceptions.
</li>
</ul>
<p>We may use additional processes to verify your identity before we reveal or delete any of your Data, including two-factor or two-step authentication measures to ensure we can identify you.</p>
<p>This list may not include all of your rights under applicable laws. If you believe you have additional rights, please contact us using the methods in this Privacy Policy.
</p>
<p>Further, although we currently do not process Data without consent, if we at any time in the future process Data without your express consent, you may opt-out or withdraw consent at any time.
</p>
<p>Please note that exercising any of the above rights may limit or eliminate our ability to </p>
<p>We will try to comply with your request(s) as soon as reasonably practicable. Upon receipt of your written request, we will provide you with a copy of your information, although in certain limited circumstances we may not be able to make all relevant information available to you, such as where that information also pertains to another user. In such circumstances we will provide reasons for the denial to you upon request.
</p>
<p>Please also note that if you do opt-out of receiving marketing-related emails from us, we may still send you messages for administrative or other purposes directly relating to your use of the Services, and you cannot opt-out from receiving those messages while continuing to use the Services.
</p>
<p>To exercise any of these rights, or if you have any questions about our processing of your Data, please contact us at privacy@spaceazy.com or at 1-866-964-1398.
</p>
<h3><b>A. Privacy for EU/UK Residents
</b></h3>
<p>The Regulation (EU) 2016/679 (General Data Protection Regulation) made effective in Europe on May 25, 2018 (“GDPR”) requires that we clearly describe to data subjects the data we collect and how we use that data. This Privacy Policy does that and if you have any questions for us regarding our data collection, please contact us at <a href=""> privacy@spaceazy.com.</a>
</p>
<p><b>Pursuant to the GDPR, residents of Europe have the right to obtain our confirmation of whether we maintain personal information relating to them in the United States. If you are a resident of Europe, upon request from you, we will provide you with access to the Data that we hold about you.Please contact us if you have any questions.
</b></p>
<p><b>Further, if you are a resident of the United Kingdom ("UK"), to the extent the GDPR as incorporated into UK law pursuant to s.3 of the European Union (Withdrawal Act) 2018 (as amended, the "UK GDPR") is different than the GDPR, we will follow all supplemental requirements under the UK GDPR and you have all rights as a UK citizen under the UK GDPR.</b></p>
<h2>SECURITY
</h2>
<p>The security of your Data is important to us. We use commercially reasonable efforts to store and maintain your Data in a secure environment. We take technical, contractual, administrative, and physical security steps designed to protect Data that you provide to us. We have implemented procedures designed to limit the dissemination of your Data to only such designated staff as are reasonably necessary to carry out the stated purposes we have communicated to you.
</p>
<h2>THIRD-PARTY POLICIES</h2>
<p>You may be able to access third-party websites and other tools and services or products via a link, or via our other tools. The privacy policies of these third parties are not under our control and may differ from ours. The use of any Data that you may provide to any third parties will be governed by the privacy policy of such third party or by your independent agreement with such third party, as the case may be. If you have any doubts about the privacy of the information you are providing to a third party, we recommend that you contact that third party directly for more information or to review its privacy policy.
</p>
<p>This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating any offering, site, or other products and Services used in connection with the Services. The inclusion of a link does not imply endorsement of the linked site or service by us or by our affiliates.
</p>
<h2>DATA RETENTION</h2>
<p>We will keep your Data for as long as it remains necessary for the identified purpose or as required by law, which may extend beyond the termination of our relationship with you. We may retain certain Data as necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of De-Identified Data, account recovery, or if required by law. All retained information will remain subject to the terms of this Privacy Policy.
</p>
<h2>AMENDMENT OF THIS PRIVACY POLICY
</h2>
<p>We reserve the right to change this Privacy Policy at any time. If we decide to change this Privacy Policy in the future, we will post or provide appropriate notice. Any change to this Privacy Policy will become effective on the date that is 30 days from their posting on the Services or sent via email to your listed email address. Unless stated otherwise, our current Privacy Policy applies to all Data that we have about you and your account. The date on which the latest update was made is indicated at the top of this document. We recommend that you print a copy of this Privacy Policy for your reference and revisit this policy from time to time to ensure you are aware of any changes. Your continued use of the Services signifies your acceptance of any changes.</p>
<h2>CONTACT US
</h2>
<p>You can help by keeping us informed of any changes such as a change of your personal contact information. If you would like to access your information, if you have any questions, comments, or suggestions of if you find any errors in our information about you, please contact us at <a href="#"> privacy@spaceazy.com.</a> If you have a complaint concerning our compliance with applicable privacy laws, we will investigate your complaint and if it is justified, we will take appropriate measures.
</p>



                    </div>
                    </div>
                </div>
               </div>
            





               
            </div>
        );
    }
}

export default translate(Policy);
