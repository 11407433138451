import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class FindRightSpace extends Component {
    state = {};
    render() {
        return (
            <div>
                <div className="">
                    <div className="section-title">
                    </div>

                </div>
                 <div className="">
                    <div className="display-inlinesssss find_right_space container">
                        <h1 className=" Heading_section how_work_heading text_success pb-2">
                            {t("Find_the_right_space_for_you ")}
                        </h1>
                       
                        <div className="row m-0 justify-content-between">
                            <div className=" col-sm-6 col-md-4 p-0">
                                <div className="home-footer-spacing px-2 py-3 position-relative ">
                                    <h5 className="bg_success text-white position-absolute  py-1 rounded">{t("Garage")}</h5>
                                    <img className="img-fluid find_right_space_imgs_shadow" srcSet="../../../assets/img/right_1.png" />
                                </div>
                            </div>
                            <div className=" col-sm-6 col-md-4  p-0">
                                <div className="home-footer-spacing px-2 py-3 position-relative">
                                    <h5 className="bg_success text-white position-absolute  py-1 rounded">{t("A_Part_Of_Room")}</h5>
                                    <img className="img-fluid find_right_space_imgs_shadow" srcSet="../../../assets/img/right_1.png" />
                                </div>
                            </div>
                            <div className=" col-sm-6 col-md-4 p-0">
                                <div className="home-footer-spacing px-2 py-3 position-relative ">
                                    <h5 className="bg_success text-white position-absolute  py-1 rounded">{t("Room")}</h5>
                                    <img className="img-fluid find_right_space_imgs_shadow" srcSet="../../../assets/img/right_1.png" />
                                </div>
                            </div>

                        </div>
                        <div className="row m-0 justify-content-between hh">
                            <div className=" col-sm-6 col-md-4 p-0">
                                <div className="home-footer-spacing px-2 py-3 position-relative ">
                                    <h5 className="bg_success text-white position-absolute  py-1 rounded">{t("Garage")}</h5>
                                    <img className="img-fluid find_right_space_imgs_shadow" srcSet="../../../assets/img/right_1.png" />
                                </div>
                            </div>
                            <div className=" col-sm-6 col-md-4 p-0">
                                <div className="home-footer-spacing px-2 py-3 position-relative ">
                                    <h5 className="bg_success text-white position-absolute  py-1 rounded">{t("A_Part_Of_Room")}</h5>
                                    <img className="img-fluid find_right_space_imgs_shadow" srcSet="../../../assets/img/right_1.png" />
                                </div>
                            </div>
                            <div className=" col-sm-6 col-md-4 p-0">
                                <div className="home-footer-spacing px-2 py-3 position-relative ">
                                    <h5 className="bg_success text-white position-absolute  py-1 rounded">{t("Room")}</h5>
                                    <img className="img-fluid find_right_space_imgs_shadow" srcSet="../../../assets/img/right_1.png" />
                                </div>
                            </div>

                        </div>

                        <button className="bg_success text-white rounded border-0 px-4 py-2 ml-2 ">{t("see_more")}</button>



                    </div>
                </div>
            </div>
        );
    }
}

export default translate(FindRightSpace);
