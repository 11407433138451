import React, { Component } from "react";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import api from "../../Environment";
import MapContainer from "../Helper/mapHelper";
import Helper from "../Helper/Helper";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";

class HomeRoomContent2 extends Helper {
  state = {
    wishlistData: {
      wishlist_status: "",
      host_id: "",
    },
    loading: true,
    host_id: null,
  };

  handleClick = (event, data) => {
    event.preventDefault();
    let el = event.target;

    const addWishlist = {
      host_id: data.host_id,
      clear_all_status: 0,
    };
    api.postMethod("wishlist_operations", addWishlist).then((response) => {
      if (response.data.success) {
        this.setState({
          wishlistData: response.data.data,
          loading: false,
          host_id: data.host_id,
        });
        if (response.data.data.wishlist_status) {
          el.classList.add("pink-clr");
        } else {
          el.classList.remove("pink-clr");
        }
        ToastDemo(this.props.toastManager, response.data.message, "success");
      } else {
        const check = this.checkLoginUser(response.data);
        if (check) {
          ToastDemo(
            this.props.toastManager,
            "Please login to save the details",
            "error"
          );
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      }
    });
  };

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
  }

  render() {
    const { length: count } = this.props.contentDetails;
    const { wishlistData, loading, host_id } = this.state;

    if (count === 0) {
      return <p />;
    }

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="subcategory-leftsec subcategory_leftsec">
        <div className="section-title">
          {/* <div className="sec_head_btn d-flex justify-content-between">
          <h1 className="section-head Heading_section  how_work_heading text_success pb-2 listing_we_think_font find_right_spc new_padd">{t("Listing_We_Think_You_Will_Love")}</h1>
          <div className="see_more_btn">
          
          <button className="bg_success text-white  rounded border-0 px-4 py-2 ml-2 mt-2 ">Explore More</button>
          </div>
          </div> */}
          {/* <h4 className="captalize section-subhead head_paras">
            {t("explore_some_of_the_best_reviewed_homes_in_the_world")}
          </h4> */}
        </div>

        <div className="row">

          {this.props.contentDetails.map((data) => (
            <div className="col-lg-3" key={data.host_id}>
                <div className="landing_pl_cards listen_we_think_card 
                ">

                      
                    {data.gallery.map((image,i) => {
                      if (i === 0) {
                        return <div key={image.picture} className=" homes-img-sec2 new_homes_img_sec2">
                        <img style={{borderRadius:'10px 10px 0px 0px'}} className
                          srcSet={image.picture}
                          src={image.picture}
                          alt="Loading..."
                         
                        />
                      </div>;
                      }  else {
                        return <div></div>;
                      }
                    })}
                  
               
              
             
              
                  
                  <div className="listen_we_contents">
                    <div className="d-flex">
                      <Link to={`/space/${data.host_id}`}>
                        <p className="display-5 list_heading text_success mt-2 lis_card listingthings_card_heading">
                          {data.sub_category_name}</p>
                      <h3 className="fw-bold  list_no_basement mb-0 lis_card listingthings_card_basement ">{data.dimension} {data.host_name}</h3>
                      </Link>
                      <Link
                          className="ml-auto"
                          to="#"
                          onClick={(e) => this.handleClick(e, data)}
                        >
                           {loading ? (
                            ""
                            ) : wishlistData.host_id != "" ? (
                            wishlistData.host_id == data.host_id ? (
                              <img className="img-fluid" srcSet="../../../assets/img/greenheart.png " style={{width: '40px' ,maxWidth:'unset'}} />
                              // <i className="fas fa-heart pink-clr"  />
                            ) : (
                              // <i className="far fa-heart" />
                              <img className="img-fluid" srcSet="../../../assets/img/heart.png" style={{width: '40px',maxWidth:'unset'}} />
                              
                            )
                          ) : (
                            <i className="far fa-heart" />
                          )} 
                          {data.wishlist_status ? (
                            // <i className="fa fa-heart pink-clr" />
                            <img className="img-fluid" srcSet="../../../assets/img/greenheart.png" style={{width: '40px',maxWidth:'unset'}} />
                          ) : (
                            // <i className="fa fa-heart" />
                            <img className="img-fluid" srcSet="../../../assets/img/heart.png" style={{width: '40px',maxWidth:'unset'}} />
                          )}
                      </Link>
                    </div>
                    <div className="">
                      <div className="wishlist-icon">
                        
                      </div>
                    </div>

                    <span className="basement_list_price mb-0 listingthings_card_monthly_price">{data.per_month_formatted}</span><span></span><span className="per_month"> / {data.per_month_symbol}</span>
                    {/* <p className="mb-0 mt-2 listingthings_card_rating rating-star d-flex">
                     
                        {this.starRatingHost(data.overall_ratings)}
                     
                     <span className="ml-2 ">&nbsp;({data.total_ratings})</span>
                    </p> */}
                  </div>
              
              </div>
            </div>
          ))}
        </div>

      </div>
    );
  }
}

export default withToastManager(HomeRoomContent2);
