import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
    hostsUploadFilesSuccess,
    hostsUploadFilesFailure,
    hostsRemoveFilesSuccess,
    hostsRemoveFilesFailure,
    hostsSaveSuccess,
    hostsSaveFailure,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    fetchSubCategoriesSuccess,
    fetchSubCategoriesFailure,
    hostsAmenitiesSuccess,
    hostsAmenitiesFailure,
    hostsAmenitiesSaveSuccess,
    hostsAmenitiesSaveFailure,
    fetchServiceLocationsSuccess,
    fetchServiceLocationsFailure,
    fetchHostDetailsSuccess,
    fetchHostDetailsFailure,
    fetchHostDetailsStart,
    hostsSaveStatus,
} from "../actions/HostAction";
import api from "../../../HostEnvironment";
import {
    HOSTS_UPLOAD_FILES_START,
    HOSTS_REMOVE_FILES_START,
    HOSTS_SAVE_START,
    FETCH_CATEGORIES_START,
    FETCH_SUB_CATEGORIES_START,
    HOSTS_AMENITIES_START,
    HOSTS_AMENITIES_SAVE_START,
    FETCH_SERVICE_LOCATIONS_START,
    FETCH_HOST_DETAILS_START,
} from "../actions/ActionConstant";

import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "../../Helper/ToastNotification";

function* hostsUploadFilesAPI(action) {
    try {
        const response = yield api.postMethod("hosts_upload_files", action.data);
        if (response.data.success) {
            yield put(hostsUploadFilesSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(hostsUploadFilesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(hostsUploadFilesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* hostsRemoveFilesAPI(action) {
    try {
        const response = yield api.postMethod("hosts_remove_files", action.data);
        if (response.data.success) {
            yield put(hostsRemoveFilesSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            yield put(fetchHostDetailsStart({host_id:response.data.data.host_id}));
        } else {
            yield put(hostsRemoveFilesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(hostsRemoveFilesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}


function* hostsSaveAPI(action) {
    try {        
        const hostData = yield select((state) => state.host.hostsSave.data);
        const response = yield api.postMethod("hosts_save", hostData);
        if (response.data.success) {
            yield put(hostsSaveSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(hostsSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(hostsSaveFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchCategoriesAPI(action) {
    try {
        const response = yield api.postMethod("host_categories", action.data);
        if (response.data.success) {
            yield put(fetchCategoriesSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchSubCategoriesAPI(action) {
    try {
        const response = yield api.postMethod("sub_categories", action.data);
        if (response.data.success) {
            yield put(fetchSubCategoriesSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchSubCategoriesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchSubCategoriesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* fetchServiceLocationAPI(action) {
    try {
        const response = yield api.postMethod("host_service_locations", action.data);
        if (response.data.success) {
            yield put(fetchServiceLocationsSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(fetchServiceLocationsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchServiceLocationsFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* hostsAmenitiesAPI(action) {
    try {
        const response = yield api.postMethod("hosts_amenities", action.data);
        if (response.data.success) {
            yield put(hostsAmenitiesSuccess(response.data.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(hostsAmenitiesFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(hostsAmenitiesFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

function* hostsAmenitiesSaveAPI(action) {
    try {
        const response = yield api.postMethod("hosts_amenities_save", action.data);
        if (response.data.success) {
            yield put(hostsAmenitiesSaveSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
        } else {
            yield put(hostsAmenitiesSaveFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(hostsAmenitiesSaveFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}


function* fetchHostDetailsAPI(action) {
    try {
        const response = yield api.postMethod("hosts_edit", action.data);
        if (response.data.success) {
            yield put(fetchHostDetailsSuccess(response.data));
            yield call(getSuccessNotificationMessage, response.data.message);
            // yield put(hostsSaveStatus());
        } else {
            yield put(fetchHostDetailsFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchHostDetailsFailure(error));
        yield call(getErrorNotificationMessage, error);
    }
}

export default function* pageSaga() {
    yield all([
        yield takeLatest(HOSTS_UPLOAD_FILES_START, hostsUploadFilesAPI),
        yield takeLatest(HOSTS_REMOVE_FILES_START, hostsRemoveFilesAPI),
        yield takeLatest(HOSTS_SAVE_START, hostsSaveAPI),
        yield takeLatest(FETCH_CATEGORIES_START, fetchCategoriesAPI),
        yield takeLatest(FETCH_SUB_CATEGORIES_START, fetchSubCategoriesAPI),
        yield takeLatest(HOSTS_AMENITIES_START, hostsAmenitiesAPI),
        yield takeLatest(HOSTS_AMENITIES_SAVE_START, hostsAmenitiesSaveAPI),
        yield takeLatest(FETCH_SERVICE_LOCATIONS_START, fetchServiceLocationAPI),
        yield takeLatest(FETCH_HOST_DETAILS_START, fetchHostDetailsAPI),
    ]);
}