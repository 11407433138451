import React, { useState, useEffect } from "react";
import "./NewListing.css";
import NumericInput from "react-numeric-input";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {draftHostDetails } from "../../store/actions/HostAction";

const NewListingStep3 = (props) => {

    const onRoomChange = (event) => {
        props.dispatch(
            draftHostDetails(
                'room',
                event
            )
        );
    }
    console.log(props.host);
    return (
        <>
            <div>
                <div className="new-listing-body-wrapper">
                    <div className="step-3">
                       <form>
                            <div className="new-listing-property-form-box">
                                <div className="new-listing-property-form-card">
                                    <label>No of Room:</label>
                                    <NumericInput 
                                        mobile 
                                        className="form-control form-control-custom" 
                                        name="room" 
                                        id="room" 
                                        value={props.host.data.room}
                                        onChange={(event) => onRoomChange(event)}
                                    />
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>No of Floor:</label>
                                    <input 
                                        type="number" 
                                        class="form-control" 
                                        placeholder="Enter the floor"
                                        name="floor" 
                                        id="floor" 
                                        value={props.host.data.floor}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>Enter Area Size:</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        placeholder="Enter the Area"
                                        name="area" 
                                        id="area" 
                                        value={props.host.data.area}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>Dimension:(Length)</label>
                                    <div>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            placeholder="Enter Height"
                                            name="height" 
                                            id="height" 
                                            value={props.host.data.height}
                                            onChange={(event) => {
                                                props.dispatch(
                                                    draftHostDetails(
                                                        event.currentTarget.name,
                                                        event.currentTarget.value
                                                    )
                                                );
                                            }}
                                        ></input>
                                        <div className="new-listing-property-dimensions-box">
                                            {/* <div className="new-listing-property-dimensions-item">
                                                <input 
                                                    type="radio" 
                                                    id="new-listing-property-dimensions-1" 
                                                    name="dimension" 
                                                    onChange={(event) => {
                                                        props.dispatch(
                                                            draftHostDetails(
                                                                event.currentTarget.name,
                                                                '3*3'
                                                            )
                                                        );
                                                    }}
                                                />
                                                <label for="new-listing-property-dimensions-1" className="new-listing-property-dimensions-card">
                                                    <div className="new-listing-property-dimensions-info">
                                                        3*3
                                                    </div>
                                                </label>
                                            </div> */}
                                            {/* <div className="new-listing-property-dimensions-item">
                                                <input 
                                                    type="radio" 
                                                    name="dimension" 
                                                    onChange={(event) => {
                                                        props.dispatch(
                                                            draftHostDetails(
                                                                event.currentTarget.name,
                                                                '4*4'
                                                            )
                                                        );
                                                    }} 
                                                    id="new-listing-property-dimensions-2" 
                                                />
                                                <label for="new-listing-property-dimensions-2" className="new-listing-property-dimensions-card">
                                                    <div className="new-listing-property-dimensions-info">
                                                        4*4
                                                    </div>
                                                </label>
                                            </div> */}
                                            {/* <div className="new-listing-property-dimensions-item">
                                                <input 
                                                    type="radio" 
                                                    name="dimension" 
                                                    onChange={(event) => {
                                                        props.dispatch(
                                                            draftHostDetails(
                                                                event.currentTarget.name,
                                                                '12*12'
                                                            )
                                                        );
                                                    }} 
                                                    id="new-listing-property-dimensions-3" 
                                                />
                                                <label for="new-listing-property-dimensions-3" className="new-listing-property-dimensions-card">
                                                    <div className="new-listing-property-dimensions-info">
                                                        12*12
                                                    </div>
                                                </label>
                                            </div> */}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>Dimension:(Width)</label>
                                    <div>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            placeholder="Enter Width"
                                            name="width" 
                                            id="width"
                                            value={props.host.data.width} 
                                            onChange={(event) => {
                                                props.dispatch(
                                                    draftHostDetails(
                                                        event.currentTarget.name,
                                                        event.currentTarget.value
                                                    )
                                                );
                                            }}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                       </form>
                    </div>
                    <div className="new-listing-footer-btn-sec">
                        <button className="back-btn" onClick={() => props.setStep(2)}>
                            Back
                        </button>
                        <button onClick={() => props.setStep(4)} className="next-btn">
                            Next
                        </button>
                    </div>
                </div>
                
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    host: state.host.host,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingStep3));