import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
//import { url } from "inspector";

class Categories extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      // adaptiveHeight: true,
      draggable: true,
    };
    const { length: count } = this.props.categoryDetails;
    if (count === 0) {
      return <p />;
    }

    return (
      <>
        {this.props.categoryDetails.data.length > 0 ? (
          <div>
                <div className="">
                    <div className="section-title">
                    </div>

                </div>
                <div className="">

                    <div className="display-inline find_right_space mt-md-5">
                      <div className="row find_head_btn d-flex">
                        <div className="col-md-9"> 
                        <h1 className=" Heading_section how_work_heading text_success pb-2 find_right_spc p-3">
                        Find the Perfect Space in the Ideal Place!
                        </h1>
                      <p className="text-dark pl-3">
                      Check out our impressive selection of listings available for your convenience. With plenty of 
                       options to choose from, you're sure to find the perfect storage space for rent in an area you will 
                      love.
                      </p>
                        </div>
                       
                   <div className="col-md-3">
                   <button className="find_loc_see_more px-4 py-4 ml-2 mt-2 w-100 ">See More <img src="../../../assets/img/vector.png" className="img-fluid" /></button> 
                   </div>
                       
                       
                        </div>
                        <div className="row m-0 find_loc_childs find_r_space_cards">
                        {this.props.categoryDetails.data.map((category) => (
                            <Link  className="find_loc_child_item"
                              to={`/category/${category.name}/${category.api_page_type_id}/${this.props.categoryDetails.api_page_type}`}
                            >  
                            <div className=" col-sm-6 col-md-4 mb-2 p-0">
                                <div className="home-footer-spacing find_loc_card_set home-footer-spacing2 px-2 py-3 position-relative "
                                 style={{
                                backgroundImage: `url(${category.picture})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: " cover",
                                // border: '5px solid white',
                                boxShadow: '8px 10px 30px rgb(0 95 34 / 20%)',
                                borderRadius: '10px',
                            }}


                                >
                                    <h5 className="bg_success text-white position-absolute  py-1 rounded">{category.name}</h5>
                                </div>
                            </div>
                            </Link>
                        ))}    
                            
                        </div>
                       
                        {/* <button className="bg_success text-white  rounded border-0 px-4 py-2 ml-2 mt-2 ">See More</button> */}



                    </div>
                </div>
          
            
           
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default Categories;
