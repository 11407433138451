import React, { Component } from "react";
import Helper from "../../Helper/Helper";
import api from "../../../HostEnvironment";
import hostHelper from "../../Helper/hostHelper";
import ProfileInput from "../../Helper/profileInput";
import HostProfileSideBar from "./hostProfileSideBar";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";
import ToastDemo from "../../Helper/toaster";
import { ProSidebarProvider } from 'react-pro-sidebar';
import LeftSideBar from "../HostLayout/sideBar";


class HostChangePassword extends hostHelper {
  state = {
    data: {},
    loadingContent: null,
    buttonDisable: false
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true
    });
    api.postMethod("change_password", this.state.data).then(response => {
      if (response.data.success === true) {
        setTimeout(function(){ window.location.assign('/host/logout'); }, 3000);
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.setState({ loadingContent: null, buttonDisable: false });
      } else {
        ToastDemo(this.props.toastManager, response.data.error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      }
    });
  };

  render() {
    let { data } = this.state;
    return (
      <div className="main host-main">
        <div className="container-fluid">

                <div className="row">
                    <div><ProSidebarProvider>  <LeftSideBar /></ProSidebarProvider></div>
                    <div className="ml-3 mr-3  inner-container" >
                      <div className="top-bottom-spacing">

                        <nav className="navbar bg-body-tertiary" style={{borderBottom:'1.5px solid rgba(18, 20, 29, 0.1)'}}>
                              <div className="container-fluid">
                                <a className="navbar-brand" style={{fontWeight:'700',fontSize:'20px'}}>Account Settings</a>
                                <span className="d-flex" role="search" style={{width:'20%'}}>
                                    <img style={{height: 'auto',margin: 'auto'}} src="../assets/img/Notification.png" alt="menu" />

                                    <select style={{marginLeft:'10%',borderRadius: '10px'}} className="form-control mw-300" name="service_location_id">
                                    <option>English</option>
                                    <option value="2">Francis</option>
                                    </select>    
                                </span>
                              </div>
                        </nav>
                        
                        <div className="row pt-3">
                          <HostProfileSideBar />
                          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-9">
                            <form onSubmit={this.handleSubmit}>
                              <div class="panel">
                                <div class="panel-heading">{t("change_password")}</div>
                                <div class="panel-body account">
                                  <ProfileInput
                                    label="old password"
                                    type="password"
                                    placeholder=""
                                    id="old-pass"
                                    name="old_password"
                                    value={data.old_password}
                                    description=""
                                    onChange={this.handleChange}
                                  />

                                  <ProfileInput
                                    label="new password"
                                    type="password"
                                    placeholder=""
                                    id="new-pass"
                                    name="password"
                                    value={data.password}
                                    description=""
                                    onChange={this.handleChange}
                                  />
                                  <ProfileInput
                                    label="confirm password"
                                    type="password"
                                    placeholder=""
                                    id="confirm-pass"
                                    name="password_confirmation"
                                    value={data.password_confirmation}
                                    description=""
                                    onChange={this.handleChange}
                                  />

                                  <div class="row">
                                    <div class="col-9 offset-3 text-center">
                                      <button
                                        class="pink-btn btn-block"
                                        disabled={this.state.buttonDisable}
                                      >
                                        {this.state.loadingContent != null
                                          ? this.state.loadingContent
                                          : t("reset_password")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(HostChangePassword));
