import React, { useState, useMemo, useEffect } from "react";
import "./NewListing.css";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { hostsAmenitiesStart, draftHostDetails } from "../../store/actions/HostAction";

const NewListingStep6 = (props) => {

    const [amenitiesId, setAmenitiesId] = useState([]);

    useEffect(() => {
        if (props.hostId) {
            props.dispatch(hostsAmenitiesStart({ host_id: props.hostId }));
        } else {
            props.dispatch(hostsAmenitiesStart());
        }
    }, []);


    useEffect(() => {
        if (!props.host.loading && props.host.data.amenities) {

            var amenities = props.host.data.amenities.split(',');

            var amenities_id = amenities.map(function (item) {
                return parseInt(item);
            })
            setAmenitiesId(amenities_id);

        }
    }, [!props.host.loading]);

    const handleAmentiesChange = ({ currentTarget: input }) => {
        if (input.type === "checkbox") {
            if (input.checked) {
                if (amenitiesId === undefined) {
                    let array = [];
                    const value = parseInt(input.value);
                    array.push(value);
                    amenitiesId = array;
                    setAmenitiesId(amenitiesId);
                } else {
                    const value = parseInt(input.value);
                    amenitiesId.push(value);
                    setAmenitiesId(amenitiesId);
                }
            } else {
                const value = parseInt(input.value);
                let index = amenitiesId.indexOf(value);
                if (index !== -1) {
                    amenitiesId.splice(index, 1);
                    setAmenitiesId(amenitiesId);
                }
            }
        }
        props.dispatch(draftHostDetails("amenities", amenitiesId.join()));
    };

    return (
        <>
            <div>
                {!props.hostsAmenities.loading && (
                    <div className="new-listing-body-wrapper">
                        <div className="step-7">
                            <form>
                                <div className="new-listing-amenities-box">
                                    {props.hostsAmenities
                                        ? props.hostsAmenities.data.amenities.length > 0
                                            ? props.hostsAmenities.data.amenities.map((amenity, index) =>

                                                <div className="new-listing-amenities-item">
                                                    <input
                                                        type="checkbox"
                                                        name="amenities_id"
                                                        id={`checkbox-amenities-${index}`}
                                                        value={amenity.id}
                                                        defaultChecked={amenity.is_selected == 1 ? true : false}
                                                        onChange={handleAmentiesChange}
                                                    />
                                                    <label for={`checkbox-amenities-${index}`} className="new-listing-amenities-card">
                                                        <div className="new-listing-amenities-info">
                                                            <img
                                                                src={amenity.picture}
                                                                className="amenities-host-icon"
                                                            />
                                                            <h4>{amenity.name}</h4>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                            : null
                                        : null}

                                </div>
                            </form>
                        </div>
                    </div>
                )}
                <div className="new-listing-footer-btn-sec">
                    <button className="back-btn" onClick={() => props.setStep(5)}>
                        Back
                    </button>
                    <button onClick={() => props.setStep(7)} className="next-btn">
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};
const mapStateToPros = (state) => ({
    hostsAmenities: state.host.hostsAmenities,
    host: state.host.host,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingStep6));