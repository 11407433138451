import React, { Component } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { DatePicker } from "@y0c/react-datepicker";
import "@y0c/react-datepicker/assets/styles/calendar.scss";
import api from "../../Environment";
import { t, translate } from "react-multi-lang";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import moment from 'moment';

var yesterday = moment().subtract(1, 'day');
var valid = function (current) {
  return current.isAfter(yesterday);
};

class Filters extends Component {
  state = {
    value: { min: 0, max: 10000 },
    adults: 0,
    children: 0,
    infants: 0,
    from_date: null,
    to_date: null,
    host_id: null,
    loading: true,
    mapView: true,
    hostTypeData: {},
    priceChange: false,
    total_guest: 0,
    total_home_type: 0,
    showCalendar: false,
  };
  componentDidMount() { }

  searchResultApiCall = () => {
    const {
      adults,
      children,
      infants,
      from_date,
      to_date,
      hostTypeData,
      priceChange
    } = this.state;

    let formData;

    if (adults != 0) {
      formData = {
        adults: adults
      };
    }
    if (children != 0) {
      formData = {
        ...formData,
        children: children
      };
    }
    if (infants != 0) {
      formData = {
        ...formData,
        infants: infants
      };
    }

    if (from_date != null) {
      formData = {
        ...formData,
        from_date: from_date
      };
    }

    if (to_date != null) {
      formData = {
        ...formData,
        to_date: to_date
      };
    }
    if (hostTypeData != {}) {
      formData = {
        ...formData,
        ...hostTypeData
      };
    }

    if (priceChange) {
      const price = {
        min_input: this.state.value.min,
        max_input: this.state.value.max
      };
      const inputData = {
        price: JSON.stringify(price)
      };
      formData = {
        ...formData,
        ...inputData
      };
    }

    api
      .postMethod("search_result", formData)
      .then(response => {
        if (response.data.success === true) {
          this.props.props.history.push("/search", response.data.data);
        } else {
          // ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch(function (error) { });
  };
  convert(str) {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return "Date", [date.getFullYear(), mnth, day].join("-");
  }

  onChangeDate = (date) => {
    const from_date = this.convert(date);
    console.log(from_date);

    this.setState({
      from_date: from_date,
      showCalendar: false,
    });
    setTimeout(() => {
      if (this.state.from_date != null) {
        this.searchResultApiCall();
      }
    }, 2000);
  };
  increament = value => {

    if (value == "adults") {
      this.setState({
        adults: this.state.adults + 1,
        total_guest: this.state.total_guest + 1
      });
    }
    if (value == "children") {
      this.setState({
        children: this.state.children + 1,
        total_guest: this.state.total_guest + 1
      });
    }
    if (value == "infants") {
      this.setState({
        infants: this.state.infants + 1,
        total_guest: this.state.total_guest + 1
      });
    }


  };

  decreament = value => {
    if (value == "adults") {
      this.setState({
        adults: this.state.adults > 0 ? this.state.adults - 1 : 0,
        total_guest: this.state.total_guest > 0 ? this.state.total_guest - 1 : 0
      });
    }
    if (value == "children") {
      this.setState({
        children: this.state.children > 0 ? this.state.children - 1 : 0,
        total_guest: this.state.total_guest > 0 ? this.state.total_guest - 1 : 0
      });
    }
    if (value == "infants") {
      this.setState({
        infants: this.state.infants > 0 ? this.state.infants - 1 : 0,
        total_guest: this.state.total_guest > 0 ? this.state.total_guest - 1 : 0
      });
    }
  };

  handleMap = event => {
    event.preventDefault();
    this.setState({ mapView: !this.state.mapView });
    if (this.props.toggleMap) {
      this.props.toggleMap(!this.state.mapView);
    }
  };
  displayMoreFilter = event => {
    event.preventDefault();
    $("#more-filters-content").toggleClass("dis-block");
  };

  closeFilterView = event => {
    event.preventDefault();
    $("#mobile-guest-content").slideUp();
  };

  handlChange = ({ currentTarget: input }) => {
    const hostTypeData = { ...this.state.hostTypeData };
    if (input.checked) {
      if (hostTypeData[input.name] === undefined) {
        let array = [];
        array.push(input.value);
        hostTypeData[input.name] = array;
        this.setState({ hostTypeData });
        this.setState({ total_home_type: this.state.total_home_type + 1 });
      } else {
        hostTypeData[input.name].push(input.value);
        this.setState({ hostTypeData });
        this.setState({ total_home_type: this.state.total_home_type + 1 });
      }
    } else {
      let index = hostTypeData[input.name].indexOf(input.value);
      if (index !== -1) {
        hostTypeData[input.name].splice(index, 1);
        this.setState({ hostTypeData });
        this.setState({ total_home_type: this.state.total_home_type - 1 });
      }
    }
  };

  handleGuest = event => {
    event.preventDefault();
    this.searchResultApiCall();
  };

  handleCancelButton = event => {
    event.preventDefault();
    this.setState({
      adults: 0,
      children: 0,
      infants: 0,
      formData: {}
    });
  };

  handleHomeType = event => {
    event.preventDefault();
    this.searchResultApiCall();
  };

  handlePrice = () => {
    this.setState({ priceChange: true });
    this.searchResultApiCall();
  };

  render() {

    const DEFAULT_CLASS_NAMES = {
      activeTrack: 'input-range__track input-range__track--active',
      disabledInputRange: 'input-range input-range--disabled',
      inputRange: 'input-range',
      labelContainer: 'input-range__label-container',
      maxLabel: 'input-range__label input-range__label--max',
      minLabel: 'input-range__label input-range__label--min',
      slider: 'input-range__slider',
      sliderContainer: 'input-range__slider-container',
      track: 'input-range__track input-range__track--background',
      valueLabel: 'input-range__label input-range__label--value',
    };
    return (
      <div>
        <div className="section-spacing sub-nav pl-0">
          <ul className="filters">
            <li className="dropdown">
              {/* <button className="dropdown-toggle" onClick={() => {
                this.setState({
                  showCalendar: !this.state.showCalendar
                });
              }}>
                {this.state.from_date != null
                  ? this.state.from_date
                  : "CheckIn"}
                {this.state.to_date != null ? this.state.to_date : ""}
              </button> */}
              <ReactDatePicker
                placeholderText="Date"
                minDate={moment().toDate()}
                className="form-control form-control-md"
                selected={this.state.from_date ? new Date(this.state.from_date) : null}
                onChange={this.onChangeDate}
              />
              <div className={`dropdown-menu ${this.state.showCalendar ? "show" : ""}`}>
                {/* <RangeDatePicker
                  startPlaceholder={t("check_in")} 
                  endPlaceholder={t("check_out")}
                  onChange={this.onChangeDate("Range DatePicker")}
                  className="form-control form-control-lg"
                /> */}
                <ReactDatePicker
                  placeholderText={t("check_in")}
                  minDate={moment().toDate()}
                  className="form-control form-control-lg"
                  selected={this.state.from_date ? new Date(this.state.from_date) : null}
                  onChange={this.onChangeDate}
                />
                {/* <DatePicker
                  placeholder={t("check_in")}
                  // initialDate={dayjs(new Date())}
                  onChange={this.onChangeDate}
                  className="form-control form-control-lg"
                // dateFormat="YYYY/MM/DD"
                /> */}
              </div>
            </li>
            {/* <li className="dropdown">
              <button
                className={this.state.total_guest != 0 ? "dropdown-toggle filter-active" : "dropdown-toggle"}
                id="guest-mobile-btn"
                data-toggle="dropdown"
              >
                {this.state.total_guest != 0 ? this.state.total_guest : ""} {" "}
                Guests
              </button>
              <div className="dropdown-menu guest">
                <form className="inc-dec-count">
                  <div className="row guest-options">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">adults</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div
                          className="decrement-btn"
                          onClick={() => this.decreament("adults")}
                        >
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input
                          type="text"
                          value={this.state.adults}
                          name="adults"
                        />
                        <div
                          className="increment-btn"
                          onClick={() => this.increament("adults")}
                        >
                          <div className="inc button">
                            <i className="fa fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row guest-options">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">{t("children")}
                      <small className="text-muted"><br />{t("ages")} 2–12</small></label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div
                          className="decrement-btn"
                          onClick={() => this.decreament("children")}
                        >
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input
                          type="text"
                          value={this.state.children}
                          name="children"
                        />
                        <div
                          className="increment-btn"
                          onClick={() => this.increament("children")}
                        >
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row guest-options">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">{t("infants")}
                      <small className="text-muted"><br />{t("under")} 2</small></label>

                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div
                          className="decrement-btn"
                          onClick={() => this.decreament("infants")}
                        >
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input
                          type="text"
                          value={this.state.infants}
                          name="infants"
                        />
                        <div
                          className="increment-btn"
                          onClick={() => this.increament("infants")}
                        >
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <Link
                        to="#"
                        className="cancel-link"
                        onClick={this.handleCancelButton}
                      >
                        {t("clear")}
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="#"
                        className="submit-link"
                        onClick={this.handleGuest}
                      >
                        {t("apply")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </li> */}
            <li className="dis-xs-none dropdown">

              {this.state.total_home_type != 0
                ?
                <button className="dropdown-toggle filter-active" data-toggle="dropdown"> {t("home_type")} - {this.state.total_home_type}</button>
                :
                <button className="dropdown-toggle" data-toggle="dropdown">{t("type_of_place")}</button>
              }

              <div className="dropdown-menu guest account">
                <form>
                  <div>
                    <label className="form-checkbox mb-0">
                      {t("entire_place")}
                      <input
                        type="checkbox"
                        name="host_type"
                        onChange={this.handlChange}
                        value="Entire Room"
                      />
                      <span className="checkmark" />
                    </label>
                    <p className="profile-note ml-35 mt-0">
                      {t("have_a_place_to_yourself")}
                    </p>
                  </div>

                  <label className="form-checkbox mb-0">
                    {t("private_place")}
                    <input
                      type="checkbox"
                      name="host_type"
                      onChange={this.handlChange}
                      value="Private Room"
                    />
                    <span className="checkmark" />
                  </label>
                  <p className="profile-note ml-35 mt-0">
                    {t("have_your_own_room_and_share_some_common_spaces")}
                  </p>

                  <label className="form-checkbox mb-0">
                    {t("shared_room")}
                    <input
                      type="checkbox"
                      name="host_type"
                      onChange={this.handlChange}
                      value="Shared Room"
                    />
                    <span className="checkmark" />
                  </label>
                  <p className="profile-note ml-35 mt-0">
                    {t("stay_in_link_shared_space_like_link_common_room")}
                  </p>

                  <div className="row">
                    <div className="col-6 mt-3">
                      <button
                        className="cancel-link"
                        onClick={this.handleCancelButton}
                      >
                        {t("clear")}
                      </button>
                    </div>
                    <div className="col-6 mt-3">
                      <button
                        className="submit-link"
                        onClick={this.handleHomeType}
                      >
                        {t("apply")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>
            <li className="dis-xs-none dropdown">
              <button className="dropdown-toggle" data-toggle="dropdown">
                {this.state.value.min != 0
                  ? this.state.value.min + " - " + this.state.value.max
                  : "price"}
              </button>
              <div className="dropdown-menu guest">
                <div className="row guest-options">
                  <div className="col-10 offset-1">
                    <p className="mt-0 bottom" />
                    <p className="bottom" />

                    <InputRange
                      maxValue={10000}
                      // minValue={100}
                      minValue={0}
                      formatLabel={value => `${value} $`}
                      value={this.state.value}
                      onChange={value => this.setState({ value: value })}
                      onChangeComplete={this.handlePrice}
                      InputRangeClassNames={DEFAULT_CLASS_NAMES}
                    />
                  </div>
                </div>
              </div>
            </li>
            {this.props.match.path == "/see_all/:name/:id/:url_type" ||
              this.props.match.path == "/search" ? (
              <li
                className="toggle-btn dis-xs-none dis-sm-none dis-md-none"
                id="map-toggle-btn"
                onClick={this.handleMap}
              >
                {/* <h5 className="float-left">Map</h5>
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.mapView}
                    className="ios-switch"
                  />
                  <div>
                    <div />
                  </div>
                </label> */}
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>

        <div className="mobile-guest-dropdown" id="mobile-guest-content">
          <div className="mobile-guest-dropdown-head">
            <h4>
              <span>
                <i
                  className="material-icons"
                  id="guest-mobile-closebtn"
                  onClick={this.closeFilterView}
                >
                  {t("close")}
                </i>
              </span>
              <span className="submit-link mt-1">{t("clear")}</span>
            </h4>
          </div>
          <div className="mobile-guest-dropdown-content">
            <li className="dropdown">
              <button
                className="dropdown-toggle"
                id="guest-mobile-btn"
                data-toggle="dropdown"
              >
                {t("guests")}
              </button>
              <div className="dropdown-menu guest">
                <form className="inc-dec-count">
                  <div className="row">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">{t("adults")}</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">{t("children")}</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-sm-6">
                      <label htmlFor="name">{t("infants")}</label>
                    </div>
                    <div className="col-6 col-sm-6 text-right">
                      <div className="float-right">
                        <div className="decrement-btn">
                          <div className="dec button">
                            <i className="fas fa-minus" />
                          </div>
                        </div>
                        <input type="text" defaultValue="3" />
                        <div className="increment-btn">
                          <div className="inc button">
                            <i className="fas fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <Link to="#" className="cancel-link">
                        {t("clear")}
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link to="#" className="submit-link">
                        {t("apply")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </li>

            <form className="inc-dec-count">
              <div className="row">
                <div className="col-6 col-sm-6">
                  <label htmlFor="name">{t("adults")}</label>
                </div>
                <div className="col-6 col-sm-6 text-right">
                  <div className="float-right">
                    <div className="decrement-btn">
                      <div className="dec button">
                        <i className="fas fa-minus" />
                      </div>
                    </div>
                    <input type="text" defaultValue="3" />
                    <div className="increment-btn">
                      <div className="inc button">
                        <i className="fas fa-plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 col-sm-6">
                  <label htmlFor="name">{t("children")}</label>
                </div>
                <div className="col-6 col-sm-6 text-right">
                  <div className="float-right">
                    <div className="decrement-btn">
                      <div className="dec button">
                        <i className="fas fa-minus" />
                      </div>
                    </div>
                    <input type="text" defaultValue="3" />
                    <div className="increment-btn">
                      <div className="inc button">
                        <i className="fas fa-plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 col-sm-6">
                  <label htmlFor="name">{t("infants")}</label>
                </div>
                <div className="col-6 col-sm-6 text-right">
                  <div className="float-right">
                    <div className="decrement-btn">
                      <div className="dec button">
                        <i className="fas fa-minus" />
                      </div>
                    </div>
                    <input type="text" defaultValue="3" />
                    <div className="increment-btn">
                      <div className="inc button">
                        <i className="fas fa-plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="mobile-guest-dropdown-footer">
            <button className="green-btn btn-block btn-lg">{t("show_results")}</button>
          </div>
        </div>

        <div className="more-filters" id="more-filters-content">
          <form className="inc-dec-count">
            <div>
              <h4 className="more-filters-head">{t("rooms_and_beds")}</h4>
              <div className="row">
                <div className="col-6 col-sm-6">
                  <label htmlFor="name">{t("beds")}</label>
                </div>
                <div className="col-6 col-sm-6 text-right">
                  <div className="float-right">
                    <div className="decrement-btn">
                      <div className="dec button">
                        <i className="fas fa-minus" />
                      </div>
                    </div>
                    <input type="text" defaultValue="3" />
                    <div className="increment-btn">
                      <div className="inc button">
                        <i className="fas fa-plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 col-sm-6">
                  <label htmlFor="name">{t("bedrooms")}</label>
                </div>
                <div className="col-6 col-sm-6 text-right">
                  <div className="float-right">
                    <div className="decrement-btn">
                      <div className="dec button">
                        <i className="fas fa-minus" />
                      </div>
                    </div>
                    <input type="text" defaultValue="3" />
                    <div className="increment-btn">
                      <div className="inc button">
                        <i className="fas fa-plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 col-sm-6">
                  <label htmlFor="name">{t("bathrooms")}</label>
                </div>
                <div className="col-6 col-sm-6 text-right">
                  <div className="float-right">
                    <div className="decrement-btn">
                      <div className="dec button">
                        <i className="fas fa-minus" />
                      </div>
                    </div>
                    <input type="text" defaultValue="3" />
                    <div className="increment-btn">
                      <div className="inc button">
                        <i className="fas fa-plus" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="overview-line" />

            <div className="show-mob">
              <h4 className="more-filters-head">{t("home_type")}</h4>
              <div className="account">
                <label className="form-checkbox mb-0 mt-3">
                  entrire place
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
                <h5 className="profile-note ml-35 mt-0">
                  {t("have_link_place_to_yourself")}
                </h5>

                <label className="form-checkbox mb-0 mt-3">
                  {t("private_place")}
                  <input type="checkbox" defaultChecked="checked" />
                  <span className="checkmark" />
                </label>
                <h5 className="profile-note ml-35 mt-0">
                  {t("have_your_own_room_and_share_some_common_spaces")}
                </h5>

                <label className="form-checkbox mb-0 mt-3">
                  {t("shared_room")}
                  <input type="checkbox" defaultChecked="checked" />
                  <span className="checkmark" />
                </label>
                <h5 className="profile-note ml-35 mt-0">
                  {t("stay_in_link_shared_space_like_link_common_room")}
                </h5>
              </div>
              <p className="overview-line" />
            </div>

            <div className="show-mob">
              <h4 className="more-filters-head">{t("price_details")}</h4>
              <div className="guest">
                <p className="mt-0 bottom">₹680 - ₹50000+</p>
                <p className="bottom">{t("the_average_nightly_price_is")}₹5465.</p>
                <input
                  data-addui="slider"
                  data-min="-5"
                  data-max="5"
                  data-range="true"
                  defaultValue="-2,2"
                />
              </div>
              <p className="overview-line" />
            </div>

            <div>
              <h4 className="more-filters-head">{t("amenities")}</h4>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <label className="form-checkbox mb-0">
                      {t("kitchen")}
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="form-checkbox mb-0">
                      {t("heating")}
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <label className="form-checkbox mb-0">
                      {t("shampoo")}
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="form-checkbox mb-0">
                      {t("air_conditioning")}
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="collapse-content">
                <h4
                  className="collapse-head captalize show"
                  data-toggle="collapse"
                  data-target="#rules"
                >
                  {t("show_all_aminities")} <i className="fas fa-chevron-down" />{" "}
                </h4>
                <div id="rules" className="collapse">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          {t("kitchen")}
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          {t("heating")}
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          {t("shampoo")}
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="form-checkbox mb-0">
                          {t("air_conditioning")}
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <h4
                    className="collapse-foot captalize hide"
                    data-toggle="collapse"
                    data-target="#rules"
                  >
                    {t("hide")} <i className="fas fa-chevron-up" />
                  </h4>
                </div>
              </div>
            </div>
            <p className="overview-line" />

            <div className="more-filters-footer">
              <div className="text-right">
                <button className="cancel-link mr-3">{t("cancel")}</button>
                <button className="green-btn">{t("show_300_homes")}</button>
              </div>
            </div>
          </form>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

export default translate(Filters);
