import React, { Component } from "react";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import api from "../../Environment";
import MapContainer from "../Helper/mapHelper";
import Helper from "../Helper/Helper";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { translate, t } from "react-multi-lang";

class HomeRoomContentAll extends Helper {
  
  state = {
    wishlistData: {
      wishlist_status: "",
      host_id: "",
    },
    loading: true,
    host_id: null,
  };

  handleClick = (event, data) => {
    event.preventDefault();
    let el = event.target;

    const addWishlist = {
      host_id: data.host_id,
      clear_all_status: 0,
    };
    api.postMethod("wishlist_operations", addWishlist).then((response) => {
      if (response.data.success) {
        this.setState({
          wishlistData: response.data.data,
          loading: false,
          host_id: data.host_id,
        });
        if (response.data.data.wishlist_status) {
          el.classList.add("pink-clr");
        } else {
          el.classList.remove("pink-clr");
        }
        ToastDemo(this.props.toastManager, response.data.message, "success");
      } else {
        const check = this.checkLoginUser(response.data);
        if (check) {
          ToastDemo(
            this.props.toastManager,
            "Please login to save the details",
            "error"
          );
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      }
    });
  };

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
  }

  render() {
    const { length: count } = this.props.contentDetails;
    const { wishlistData, loading, host_id } = this.state;

    if (count === 0) {
      return <p />;
    }

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      
      <div className="subcategory-leftsec search_b_heading">
        <div className="section-title">
          <h1 className="section-head Heading_section ">Search properties</h1>
          <h4 className="captalize section-subhead">
            {/* {t("explore_some_of_the_best_reviewed_homes_in_the_world")} */}
            10,325 properties available to rent
          </h4>
          
        </div>

        {/*        <div className="row space_card_row">
        */}
        <div className="row">

          {this.props.contentDetails.map((data) => (
            // <div className="col-md-4 " key={data.host_id}>
            // <div className="col-md-6" key={data.host_id}>
            // <div className="col-lg-12 col-md-6" key={data.host_id}>
            
            <div className="space_card_pos" key={data.host_id}>
                <div className="space_cards">
                    

                    {data.gallery.map((image,i) => {
                      if (i === 0) {
                        return <div key={image.picture} className="col-md-6 pl-0 homes-img-sec1 homes-img-all  homes_img_sec1">
                        <img
                          srcSet={image.picture}
                          src={image.picture}
                          alt="Loading..."
                          className="homes-img img-fluid m-auto d-flex listing_thing_img_styling"
                        />
                      </div>;
                      }  else {
                        return ;
                      }
                    })}
                  
                <div className="col-md-6 ">
                <div className="">
                  <div className="wishlist-icon wishlist-icon-sec wishlist_icon_sec">
                    <Link
                      className=""
                      to="#"
                      onClick={(e) => this.handleClick(e, data)}
                    >
                      {/* {loading ? (
                        ""
                      ) : wishlistData.host_id != "" ? (
                        wishlistData.host_id == data.host_id ? (
                          <i className="fas fa-heart pink-clr" />
                        ) : (
                          <i className="far fa-heart" />
                        )
                      ) : (
                        <i className="far fa-heart" />
                      )} */}
                      {data.wishlist_status ? (
                        // <i className="fa fa-heart pink-clr"/>
                        <img className="img-fluid" srcSet="../../../assets/img/heart.png" />
                       
                      ) : (
                        // <i className="fa fa-heart"  style={{color:"#1dd05d"}} />
                        <img className="img-fluid" srcSet="../../../assets/img/heart.png" />
                        
                      )}
                    </Link>
                  </div>
                </div>
              
                <Link to={`/space/${data.host_id}`}>
                
                  {/* <div className="pl-2 card_pd"> */}
                  <div className=" space_c_content">
                  <p className="basement_list_price mb-0 listingthings_card_monthly_price spacehome_pri pt-4"> <span style={{color:"#1dd05d"}}> {data.per_month_formatted}</span>/<span span style={{color:"#12141D",fontSize:"14px"}}>{data.per_month_symbol}</span> </p>
                    <p className="display-5 list_heading text_success mt-2 search_C listingthings_card_heading listingthings_card_heading_small " style={{
                      marginBottom:"7px"
                    }}>{data.sub_category_name}</p>
                    <h3 className="fw-bold  list_no_basement mb-0 listingthings_card_basement "> <span style={{color:"gray"}}>{data.dimension} {data.host_name} </span></h3>
                    <hr  style={{margin:"7px"}}/>
                    <p className="mb-0 mt-2 listingthings_card_rating rating-star d-flex">
                     
                        {this.starRatingHost(data.overall_ratings)}
                     
                     <span className="ml-2 ">&nbsp;({data.total_ratings})</span>
                    </p>
                  </div>
                </Link>
                </div>
            </div>
            </div>
            
          ))}
        </div>
      </div>
    );
  }
}

export default withToastManager(HomeRoomContentAll);
