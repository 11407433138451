import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import "./NewListing.css";
import { fetchSubCategoriesStart, draftHostDetails } from "../../store/actions/HostAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";

const NewListingStep2 = (props) => {

    useEffect(() => {
        if(props.hostId) {
            props.dispatch(fetchSubCategoriesStart({category_id: props.host.data.category_id,host_id: props.hostId}));
        } else {
            props.dispatch(fetchSubCategoriesStart({category_id: props.host.data.category_id}));
        }
    }, []);

    return (
        <>
            <div>
                {!props.subCategories.loading && (
                    <div className="new-listing-body-wrapper">
                        <div className="step-2">
                            <div className="new-listing-sub-category-box">
                                {props.subCategories
                                    ? props.subCategories.data.length > 0
                                        ? props.subCategories.data.map((subCategory, index) =>
                                            <div className="new-listing-sub-category-item">
                                                <input 
                                                    type="radio" 
                                                    name="sub_category_id" 
                                                    value={subCategory.sub_category_id}
                                                    id={`radio-sub-category-${index}`}
                                                    defaultChecked={subCategory.is_checked == 1 ? true : false} 
                                                    onClick={(event) => {
                                                        props.dispatch(
                                                            draftHostDetails(
                                                                event.currentTarget.name,
                                                                event.currentTarget.value
                                                            )
                                                        );
                                                    }}
                                                />
                                                <label for={`radio-sub-category-${index}`}  className="new-listing-sub-category-card">
                                                    <div className="new-listing-sub-category-info">
                                                        <h4>{subCategory.sub_category_provider_display_name}</h4>
                                                        <p>{subCategory.description}</p>
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                        : null
                                    : null}

                            </div>
                        </div>
                        <div className="new-listing-footer-btn-sec">
                            <button className="back-btn" onClick={() => props.setStep(1)}>
                                Back
                            </button>
                            <button onClick={() => props.setStep(3)} className="next-btn">
                                Next
                            </button>
                        </div>
                    </div>
                )}
                
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    subCategories: state.host.subCategories,
    host: state.host.host,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingStep2));