import React, { useState, useMemo, useEffect } from "react";
import "./NewListing.css";
import { useDropzone } from "react-dropzone";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { 
    hostsUploadFilesStart, 
    draftHostDetails, 
    hostsSaveStart,
    hostsRemoveFilesStart 
} from "../../store/actions/HostAction";

const NewListingStep7 = (props) => {

    // Dropzone
    const baseStyle = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    };

    const activeStyle = {
        borderColor: "#2196f3",
    };

    const acceptStyle = {
        borderColor: "#00e676",
    };

    const rejectStyle = {
        borderColor: "#ff1744",
    };

    const [files, setFiles] = useState([]);
    const [inputData, setInputData] = useState({});

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept: "image/*",
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            let array = [];
            let data_array = {};
            acceptedFiles.forEach((file, key) => {
                let name = 'file[' + key + ']';
                data_array[name] = file;
                array.push(
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                );
            })
            setFiles(array);
            setInputData(data_array);
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    useEffect(() => {
        if (!props.hostsUploadFiles.loading) {
            props.dispatch(
                draftHostDetails(
                    'host_gallery_ids',
                    props.hostsUploadFiles.data.gallery_ids
                )
            );
            props.dispatch(hostsSaveStart());
        }
    }, [!props.hostsUploadFiles.loading]);


    const onSelectSave = (event) => {
        event.preventDefault();
        props.dispatch(hostsUploadFilesStart(inputData));
    }

    useEffect(() => {
        if (!props.hostsSave.loading) {
            console.log(props.hostsSave);
            props.setStep(8);
        }
    }, [!props.hostsSave.loading]);

    const onClickDelete = (event, host_gallery_id) => {
        event.preventDefault();
        props.dispatch(hostsRemoveFilesStart({host_gallery_id:host_gallery_id,host_id:props.hostId}));
    }

    return (
        <>
            <div>
                <div className="new-listing-body-wrapper">
                    <div className="step-6">
                        <form>
                            <div className="new-listing-upload-img-box">
                                <div>
                                    <div {...getRootProps({ style })}>
                                        <input {...getInputProps()} />
                                        <span style={{ fontSize: ".9rem" }}>Drop image here, or click to select file
                                            <em>(1 file you can drop here)</em></span>
                                    </div>
                                </div>
                                <div className="new-listing-upload-img-preview">
                                    {files.map((file, index) =>
                                        <div className="new-listing-upload-img-preview-card" key={index}>
                                            <img
                                                className="new-listing-upload-preview-img"
                                                src={file.preview ? file.preview : "https://via.placeholder.com/200x200"}
                                                alt="Hero Image"
                                            />

                                        </div>

                                    )}
                                </div>
                                {!props.host.loading &&

                                    <div className="new-listing-upload-img-preview">
                                        {props.host && props.host.data.host_galleries
                                            ? props.host.data.host_galleries.length > 0
                                                ? props.host.data.host_galleries.map((gallery, index) =>


                                                    <div className="new-listing-upload-img-preview-card" key={index}>
                                                        <img
                                                            className="new-listing-upload-preview-img"
                                                            src={gallery.picture ? gallery.picture : "https://via.placeholder.com/200x200"}
                                                            alt="Hero Image"
                                                        />
                                                        <button
                                                            className="remove-new-listing-upload-preview-icon-sec"
                                                            onClick={(event) => onClickDelete(event, gallery.host_gallery_id)}
                                                        >
                                                            <img
                                                                src={window.location.origin + "/assets/img/new-listing/delete-preview-icon.svg"}
                                                                className="remove-new-listing-upload-preview-icon"
                                                            />
                                                        </button>
                                                    </div>
                                                )
                                                : null
                                            : null}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                    <div className="new-listing-footer-btn-sec">
                        <button className="back-btn" onClick={() => props.setStep(6)}>
                            Back
                        </button>
                        <button onClick={(event) => onSelectSave(event)} className="next-btn">
                            Save
                        </button>
                    </div>
                </div>
               
            </div>
        </>
    );
};
const mapStateToPros = (state) => ({
    hostsUploadFiles: state.host.hostsUploadFiles,
    hostsSave: state.host.hostsSave,
    host:state.host.host
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingStep7));