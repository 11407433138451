import React, { useState, useEffect } from "react";
import "./NewListing.css";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import {draftHostDetails } from "../../store/actions/HostAction";

const NewListingStep4 = (props) => {

    console.log(props.host);

    return (
        <>
            <div>
                <div className="new-listing-body-wrapper">
                    <div className="step-4">
                       <form>
                            <div className="new-listing-property-form-box">
                                <div className="new-listing-property-form-card">
                                    <label>Title:</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        placeholder="Enter the title"
                                        name="host_name" 
                                        id="host_name" 
                                        value={props.host.data.host_name}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    />
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>Description:</label>
                                    <textarea 
                                        class="form-control" 
                                        rows="4" 
                                        placeholder="Enter the description"
                                        name="description" 
                                        id="description" 
                                        value={props.host.data.description}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></textarea>
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>Per Month:</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        placeholder="Enter Cost Per Month"
                                        name="per_month" 
                                        id="per_month" 
                                        value={props.host.data.per_month}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div>
                            </div>
                       </form>
                    </div>
                    <div className="new-listing-footer-btn-sec">
                        <button className="back-btn" onClick={() => props.setStep(3)}>
                            Back
                        </button>
                        <button onClick={() => props.setStep(5)} className="next-btn">
                            Next
                        </button>
                    </div>
                </div>
                
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    host: state.host.host,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(NewListingStep4));