import React, { Component } from "react";
import Categories from "./homeCategories";
import HomeRoomContent2 from "./homeRoomContent2";
import HomeMainBanner from "./homeMainBanner";
import HomeFrontStaticContent from "./homeFrontStaticContent";
import HomeExtraContent from "./homeExtraContent";
import HomeLastContent from "./homeLastcontent";
import Helper from "../Helper/Helper";
import Loader from "../Helper/Loader";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";
import { Link } from "react-router-dom";
import HomeOtherContent from "./homeOtherContent";
import LocationPage from "./locationPage";
import { translate, t } from "react-multi-lang";
import HowItWorkn from "./HowItWorkn";
import AboutUs from "./AboutUs";
import ListingThings from "./ListingThings";
import BecomeHost from "./BecomeHost";
import Footera from "./Footera";
import FindLocation from "./FindLocation";


import FindRightSpace from "./FindRightSpace";

class LandingPage extends Helper {
  state = {
    first_block: null,
    mainData: null,
    loading: true,
    second_block: null
  };
  constructor(props) {
    super(props);

    
    document.body.style.background = "unset";

    // States and props usage
  }

  componentDidMount() {
    // Call api function
    let inputData = {
      api_page_type: "HOME"
    };
    this.homePageFirstSection(inputData);
    if (this.props.location.state != null) {
      ToastDemo(
        this.props.toastManager,
        this.props.location.state.error,
        "error"
      );
    }
  }

  render() {
    let load = new Loader();
    const { loading, mainData, second_block } = this.state;
    return (
      <div className="site_content_bg">
      <div className="">
            
            {loading ? (
              load.bannerLoader()
            ) : (
              <HomeMainBanner {...this.state.first_block} props={this.props} />
            )}
        
            <div className="site-content">
              <HowItWorkn />
                <FindLocation />
                {loading ? (
                  load.categoryLoader()
                ) : (
                  <Categories
                    categoryDetails={this.state.first_block}
                    loading={loading}
                  />
                )}
            
                <AboutUs />
                {loading
                ? load.propertyLoader()
                : mainData.map((eachData, index) => (
                  <div key={index}>
                    <div className="row about_pad">
                    <div className="col-md-10">
                    <h1 className="section-head Heading_section  how_work_heading text_success pb-2 listing_we_think_font find_right_spc new_padd">{t("Listing_We_Think_You_Will_Love")}</h1>
                    <h4 className="captalize section-subhead head_paras lis_tnk pt-3">
                      {t("explore_some_of_the_best_reviewed_homes_in_the_world")}
                     </h4>
                  </div>
                  <div className="col-md-2">
                    <div className="explore_more_landing_btn">
                  {eachData.data.length > 0 ? (
                      <Link
                        to={`/see_all/${eachData.title}/${eachData.api_page_type_id}/${eachData.api_page_type}`}
                        className="bg_success bg_success_2 text-white rounded border-0 px-3 py-2 d-flex justify-content-center"
                      >
                        Explore More
                      </Link>

                    ) : (
                      ""
                    )}
                    </div>
                    </div>
                    </div>
                    {/* {eachData.data.length > 0 ? (
                      <Link
                        to={`/see_all/${eachData.title}/${eachData.api_page_type_id}/${eachData.api_page_type}`}
                        className="bg_success text-white rounded border-0 px-3 py-2 ml-4 mt-4 "
                      >
                        See More<i className="fas fa-chevron-right" />
                      </Link>
                    ) : (
                      ""
                    )} */}
                    <HomeRoomContent2
                      contentDetails={eachData.data}
                      title={eachData.title}
                      history={this.props.history}
                    />
                    





                    {index == 0 ? (
                      <LocationPage locations={second_block} />
                    ) : (
                      ""
                    )}
                    {/* {index == 1 ? <HomeExtraContent /> : ""} */}
                  </div>
                ))}
              <BecomeHost />
             
            </div>
            </div>
            <Footera /> 
              
               {/*<ListingThings />
              
             
              
             <HomeFrontStaticContent />*/}

              

              
              
              

              {/* <HomeOtherContent /> */}
              {/*  <HomeLastContent /> */}
              
         
       
        
      </div>
    );
  }
}

export default withToastManager(translate(LandingPage));
