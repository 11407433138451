import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class HomeOtherContent extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="">
          <div className="section-title">
            <h1 className="section-head">{t("experiences_in_the_spotlight")}</h1>
          </div>
          <div className="row">
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <Link to="#">
                <div className="homes-img-sec">
                  <img
                    srcSet="../../../assets/img/1.jpg,
                                                    ../../../assets/img/1.jpg 1.5x,
                                                    ../../../assets/img/1.jpg 2x"
                    src="../../../assets/img/1.jpg"
                    alt="Experiences-1"
                    className="homes-img"
                  />
                </div>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">
                    100+ {t("surfing_experinces")}
                  </p>
                  <h4 className="homes-title txt-overflow">{t("catch_link_wave")}</h4>
                  <h5 className="homes-price txt-overflow-2line">
                    {t("we_ve_teamed_up_with_the_world_surf_league_to_offer_surfing_experiences_around_the_world")}
                  </h5>
                </div>
              </Link>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <Link to="#">
                <div className="homes-img-sec">
                  <img
                    srcSet="../../../assets/img/2.jpg,
                                                    ../../../assets/img/2.jpg 1.5x,
                                                    ../../../assets/img/2.jpg 2x"
                    src="../../../assets/img/2.jpg"
                    alt="Experiences-2"
                    className="homes-img"
                  />
                </div>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">6+ {t("concerts")} </p>
                  <h4 className="homes-title txt-overflow">
                    {t("introducing_rentroom_concerts")}
                  </h4>
                  <h5 className="homes-price txt-overflow-2line">
                    {t("discover_link_new_way_to_experience_live_music_with_intimate_shows_in_unique_spaces")}
                  </h5>
                </div>
              </Link>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <Link to="#">
                <div className="homes-img-sec">
                  <img
                    srcSet="../../../assets/img/3.jpg,
                                                    ../../../assets/img/3.jpg 1.5x,
                                                    ../../../assets/img/3.jpg 2x"
                    src="../../../assets/img/3.jpg"
                    alt="Experiences-3"
                    className="homes-img"
                  />
                </div>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">200+ {t("experinces")}</p>
                  <h4 className="homes-title txt-overflow">
                    {t("social_impact_experiences")}
                  </h4>
                  <h5 className="homes-price txt-overflow-2line">
                    {t("try_something_new_while_supporting_link_cause_100_of_what_you_pay_goes_to_the_nonprofit")}
                  </h5>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="section-title">
            <h1 className="section-head">
              {t("rentroom_plus_homes_around_the_world")}
            </h1>
            <h4 className="captalize section-subhead">
              {t("browse_link_new_selection_of_homes_verified_for_quality_comfort")}
            </h4>
          </div>
          <section className="regular slider">
            <div>
              <Link to="#">
                <div className="slider-img-sec">
                  <img
                    srcSet="../../../assets/img/slider1.jpg,
                                                    ../../../assets/img/slider1.jpg 1.5x,
                                                    ../../../assets/img/slider1.jpg 2x"
                    src="../../../assets/img/slider1.jpg"
                    alt="Experiences-4"
                    className="homes-img"
                  />
                  <div className="slider-sec-text">
                    <h3 className="slider-img-text">{t("homes_in_los_angeles")}</h3>
                  </div>
                </div>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">100+ {t("verified_homes")}</p>
                  <h5 className="homes-price txt-overflow-2line">
                    {t("movie_stars_and_starlit_beaches_yours_to_find_from_homes_verified_for_quality_and_comfort")}
                  </h5>
                </div>
              </Link>
            </div>
            <div>
              <Link to="#">
                <div className="slider-img-sec">
                  <img
                    srcSet="../../../assets/img/slider2.jpg,
                                                    ../../../assets/img/slider2.jpg 1.5x,
                                                    ../../../assets/img/slider2.jpg 2x"
                    src="../../../assets/img/slider2.jpg"
                    alt="Experiences-4"
                    className="homes-img"
                  />
                  <div className="slider-sec-text">
                    <h3 className="slider-img-text">{t("homes_in_london")}</h3>
                  </div>
                </div>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">40+ {t("verified_homes")}</p>
                  <h5 className="homes-price txt-overflow-2line">
                    {t("steep_streets_and_stellar_views_yours_to_find_from_homes_verified_for_quality_and_comfort")}
                  </h5>
                </div>
              </Link>
            </div>
            <div>
              <Link to="#">
                <div className="slider-img-sec">
                  <img
                    srcSet="../../../assets/img/slider3.jpg,
                                                    ../../../assets/img/slider3.jpg 1.5x,
                                                    ../../../assets/img/slider3.jpg 2x"
                    src="../../../assets/img/slider3.jpg"
                    alt="Experiences-4"
                    className="homes-img"
                  />
                  <div className="slider-sec-text">
                    <h3 className="slider-img-text">{t("homes_in_toronto")}</h3>
                  </div>
                </div>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">80+ {t("verified_homes")}</p>
                  <h5 className="homes-price txt-overflow-2line">
                    {t("lake_views_and_iconic_towers_yours_to_behold_from_homes_verified_for_quality_and_comfort")}
                  </h5>
                </div>
              </Link>
            </div>
            <div>
              <Link to="#">
                <div className="slider-img-sec">
                  <img
                    srcSet="../../../assets/img/slider4.jpg,
                                                    ../../../assets/img/slider4.jpg 1.5x,
                                                    ../../../assets/img/slider4.jpg 2x"
                    src="../../../assets/img/slider4.jpg"
                    alt="Experiences-4"
                    className="homes-img"
                  />
                  <div className="slider-sec-text">
                    <h3 className="slider-img-text">{t("homes_in_sydney")}</h3>
                  </div>
                </div>
                <div className="homes-text-sec">
                  <p className="red-text txt-overflow">100+ {t("verified_homes")}</p>
                  <h5 className="homes-price txt-overflow-2line">
                    {t("beaches_and_bushland_yours_to_explore_from_homes_verified_for_quality_and_comfort")}
                  </h5>
                </div>
              </Link>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default translate(HomeOtherContent);
