import React, { Component } from "react";
import { t , translate} from "react-multi-lang";

class FaqSection extends Component {
  state = {};
  render() {
    return (
      <div className="site-content">
        <div className="host-top-bottom-spacing host_top_bottom">
        {/* <div className="host-section-head">
              <h1>{t("frequently_asked_questions")}</h1>
               </div> */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0">
              <h4 className="faq-text d-none">{t("getting_started")}</h4>
              {/* <div className="host-section-head"> */}
              <div className="host_section_head">
              <h1>{t("frequently_asked_questions")}</h1>
               </div>
              {/* <div id="accordion-faq" className="faq accordion-faq">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse1"
                    >
                      <span>
                    
                        <img className="host_baner" src="../../../assets/img/arrow-down-removebg-preview.png" />
                      </span>
                     {t("can i share my home on rentroom")}
                    </a>
                  </div>
                  <div id="collapse1" className="collapse">
                    <div className="card-body">
                      {t("you can learn about local laws and rules and get advice on hosting responsibly in rentrooms online help center")}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse2"
                    >
                      <span>
                      
                        <img className="host_baner" src="../../../assets/img/arrow-down-removebg-preview.png" />
                      </span>
                     {t("who_can_be_an_rentroom_host")}
                    </a>
                  </div>
                  <div id="collapse2" className="collapse">
                    <div className="card-body">
                     {t("faq_section_q1_note")}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse3"
                    >
                      <span>
                    
                        <img className="host_baner" src="../../../assets/img/arrow-down-removebg-preview.png" />
                      </span>
                      {t("does_rentroom_screen_guests")}
                    </a>
                  </div>
                  <div id="collapse3" className="collapse">
                    <div className="card-body">
                      {t("faq_section_q2_note")}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0">
              <h4 className="faq-text d-none">{t("earnings")}</h4>
              {/* <div id="accordion-faq" className="faq accordion-faq">
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse4"
                    >
                      <span>
                      
                        <img className="host_baner" src="../../../assets/img/arrow-down-removebg-preview.png" />
                      </span>
                      {t("how_should_i_my_listing_on_rentroom")}
                    </a>
                  </div>
                  <div id="collapse4" className="collapse">
                    <div className="card-body">
                      {t("faq_section_q3_note")}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse5"
                    >
                      <span>
                  
                        <img className="host_baner" src="../../../assets/img/arrow-down-removebg-preview.png" />
                      </span>
                      {t("how_do_rentroom_payments_work")}
                    </a>
                  </div>
                  <div id="collapse5" className="collapse">
                    <div className="card-body">
                     {t("faq_section_q4_note")}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="card-link"
                      data-toggle="collapse"
                      href="#collapse6"
                    >
                      <span>
                     
                        <img className="host_baner" src="../../../assets/img/arrow-down-removebg-preview.png" />
                      </span>
                      {t("does_rentroom_provide_any_insurance_for_hosts")}
                    </a>
                  </div>
                  <div id="collapse6" className="collapse">
                    <div className="card-body">
                      {t("faq_section_q5_note")}
                    </div>
                  </div>
                </div>
              </div> */}
{/* <div className="accordion" id="accordionExample">
  <div className="card">
    <div className="card-header" id="headingOne">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Collapsible Group Item #1
        </button>
    
      </h2>
    </div>

    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div className="card-body">
        Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the <code>.show</code> className.
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingTwo">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          Collapsible Group Item #2
        </button>
      </h2>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div className="card-body">
        Some placeholder content for the second accordion panel. This panel is hidden by default.
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingThree">
      <h2 className="mb-0">
        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Collapsible Group Item #3
        </button>
      </h2>
    </div>
    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div className="card-body">
        And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
      </div>
    </div>
  </div>
</div> */}

<div id="main">
  <div className="container">
<div className="accordion" id="faq">
                    <div className="cards faq_card">
                        <div className="card-header card_header faq_head_1" id="faqhead1">
                            <a href="#" className=" btn_header_links" data-toggle="collapse" data-target="#faq1"
                            aria-expanded="true" aria-controls="faq1"><span>A digital agency is a business</span></a>
                        </div>

                        <div id="faq1" className="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                            <div className="card-body faq_card_body">
                           <p> If your guests get hurt or cause property damage, our Host Protection Insurance protects you from liability claims up to a million dollars, included free for every Spaceazy host.</p>
                            </div>
                        </div>
                    </div>

                    <div className="cards faq_card">
                        <div className="card-header card_header faq_head_1" id="faqhead2">
                            <a href="#" className=" btn_header_links collapsed" data-toggle="collapse" data-target="#faq2"
                            aria-expanded="true" aria-controls="faq2"><span>Hire to outsource your digital</span></a>
                        </div>

                        <div id="faq2" className="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                           
                            <div className="card-body faq_card_body">
                           <p> If your guests get hurt or cause property damage, our Host Protection Insurance protects you from liability claims up to a million dollars, included free for every Spaceazy host.</p>
                            </div>
                           
                        </div>
                    </div>
                    

                    <div className="cards faq_card">
                        <div className="card-header card_header faq_head_1" id="faqhead3">
                            <a href="#" className=" btn_header_links collapsed" data-toggle="collapse" data-target="#faq3"
                            aria-expanded="true" aria-controls="faq3"><span>Marketing effortss</span></a>
                        </div>

                        <div id="faq3" className="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                         
                            <div className="card-body faq_card_body">
                           <p> If your guests get hurt or cause property damage, our Host Protection Insurance protects you from liability claims up to a million dollars, included free for every Spaceazy host.</p>
                            </div>
                            
                        </div>
                    </div>
                    
                      <div className="cards faq_card">
                        <div className="card-header card_header faq_head_1" id="faqhead3">
                            <a href="#" className=" btn_header_links collapsed" data-toggle="collapse" data-target="#faq3"
                            aria-expanded="true" aria-controls="faq3"><span>Can provide your business</span></a>
                        </div>

                        <div id="faq3" className="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                         
                            <div className="card-body faq_card_body">
                           <p> If your guests get hurt or cause property damage, our Host Protection Insurance protects you from liability claims up to a million dollars, included free for every Spaceazy host.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
    </div>
  </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(FaqSection);
