import React, { useState, useEffect } from "react";
import "./NewListing.css";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchServiceLocationsStart, draftHostDetails } from "../../store/actions/HostAction";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../../Constant/constants";

const NewListingStep5 = (props) => {

    const [mapPosition, setMapPosition] = useState('');

    useEffect(() => {
        if (props.hostId) {
            props.dispatch(fetchServiceLocationsStart({ host_id: props.hostId }));
        } else {
            props.dispatch(fetchServiceLocationsStart());
        }

    }, []);

    let autocomplete;

    const renderAutoComplete = () => {
        console.log('hii');
        const { google } = props;
        if (!google) {
            console.log("asdfsadfasdfno");
            return;
        }

        autocomplete = new google.maps.places.Autocomplete(autocomplete, {
            types: ["geocode"]
        });

        autocomplete.setFields(["address_component", "geometry", "name"]);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            console.log("Place", place);
            if (!place || !place.geometry) return;
            console.log(place.geometry.location);
            setMapPosition(place.geometry.location);


            let full_address = "";
            place.address_components.map(
                (address) =>
                (full_address =
                    full_address == ""
                        ? address.long_name
                        : full_address + "," + address.long_name)
            );

            props.dispatch(draftHostDetails("full_address", full_address));
            props.dispatch(draftHostDetails("latitude", place.geometry.location.lat()));
            props.dispatch(draftHostDetails("longitude", place.geometry.location.lng()));
        });
    };
    return (
        <>
            <div>
                <div className="new-listing-body-wrapper">
                    <div className="step-5">
                        <form>
                            <div className="new-listing-property-form-box">
                                <div className="new-listing-property-form-card">
                                    <label>Service Location:</label>
                                    <select
                                        className="form-control mw-300"
                                        name="service_location_id"
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    >
                                        <option>Select Location</option>
                                        {!props.serviceLocations.loading
                                            && props.serviceLocations.data.service_locations.map((service_location) => (
                                                <option
                                                    key={service_location.id}
                                                    value={service_location.id}
                                                    selected={service_location.is_selected == 1}
                                                >
                                                    {
                                                        service_location.name
                                                    }
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>{t("full_address")}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("house_name_number_street_road")}
                                        onFocus={renderAutoComplete}
                                        ref={ref => (autocomplete = ref)}
                                        name='full_address'
                                        defaultValue={props.host.data.full_address}
                                    // onChange={this.handleAmentiesChange}
                                    />

                                </div>
                                <div className="new-listing-property-form-card-1">
                                    <Map
                                        className="new-map"
                                        google={props.google}
                                        visible={true}
                                        center={mapPosition}
                                        initialCenter={{
                                            lat: props.host.data.latitude,
                                            lng: props.host.data.longitude
                                        }}
                                    >
                                        <Marker position={mapPosition} />
                                    </Map>
                                </div>
                                {/* <div className="new-listing-property-form-card">
                                    <label>Street Details</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Street Details"
                                        id="street_details"
                                        name="street_details"
                                        value={props.host.data.street_details}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>City:</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter City"
                                        id="city"
                                        name="city"
                                        value={props.host.data.city}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div> */}
                                {/* <div className="new-listing-property-form-card">
                                    <label>State:</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter State"
                                        id="state"
                                        name="state"
                                        value={props.host.data.state}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div> */}
                                {/* <div className="new-listing-property-form-card">
                                    <label>Country:</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Country"
                                        id="country"
                                        name="country"
                                        value={props.host.data.country}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div>
                                <div className="new-listing-property-form-card">
                                    <label>Zipcode:</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Zipcode"
                                        id="zipcode"
                                        name="zipcode"
                                        value={props.host.data.zipcode}
                                        onChange={(event) => {
                                            props.dispatch(
                                                draftHostDetails(
                                                    event.currentTarget.name,
                                                    event.currentTarget.value
                                                )
                                            );
                                        }}
                                    ></input>
                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="new-listing-footer-btn-sec">
                    <button className="back-btn" onClick={() => props.setStep(4)}>
                        Back
                    </button>
                    <button onClick={() => props.setStep(6)} className="next-btn">
                        Next
                    </button>
                </div>
            </div >
        </>
    );
};
const mapStateToPros = (state) => ({
    serviceLocations: state.host.serviceLocations,
    host: state.host.host,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const connector = connect(mapStateToPros, mapDispatchToProps)(translate(NewListingStep5));

export default GoogleApiWrapper({
    apiKey: apiConstants.google_api_key
})(connector);
