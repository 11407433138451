import React, { Component } from "react";
import { t , translate} from "react-multi-lang";
import { Link, NavLink } from "react-router-dom";

class ProfileSideBar extends Component {
  constructor(props) {
    super(props);

    // States and props usage
  }
  state = {};

  render() {
    return (
      <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3">
        <ul className="account-list">
          <li>
            <NavLink activeClassName="active" to={"/edit-profile"}>
              {t("edit_profile")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/photo"}>
              {t("photos")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/notification"}>
              {t("notifications")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/user-payment"}>
              {t("payment_method")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/change-password"}>
              {t("change_password")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/user-review"}>
              {t("reviews")}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to={"/delete-account"}>
              {t("delete_account")}
            </NavLink>
          </li>
        </ul>
        <Link
          to={"/user-profile"}
          className="grey-outline-btn w-100 bottom btn-small"
        >
          {t("view_profile")}
        </Link>
      </div>
    );
  }
}

export default translate(ProfileSideBar);
