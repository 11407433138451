import React, { Component } from "react";
import { t , translate} from "react-multi-lang";

class HostHome extends Component {
  state = {};
  render() {
    return (
      <div>
        <h1>{t("hello_home")}</h1>
      </div>
    );
  }
}

export default translate(HostHome);
