import React, { Component } from "react";
import Slider from "react-slick";
import api from "../../../Environment";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";
import { translate, t } from "react-multi-lang";

class TripSimilarListing extends Helper {
  state = {
    suggestions: {},
    loading: true,
    wishlistData: {
      wishlist_status: "",
      host_id: "",
    },
  };
  componentDidMount() {
    api
      .postMethod("suggestions", { host_id: this.props.host_id })
      .then((response) => {
        this.setState({
          suggestions: response.data.data,
          wishlistData: response.data.data,

          loading: false,
        });
      });
  }
  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };
        const { wishlistData, loading, suggestions, host_id } = this.state;

    return (
      <div className="similarly_trip">
      <div className="subcategory-leftsec">
     
        <div className="section-title mb-md-5">
          {/* <h1 className="section-head ">{t("similar_listing")}</h1> */}
          <h1 className="similar_listing_heading ">{t("similar_listing")}</h1>
        </div>

        <div className="row">
          {loading
            ? ""
            : suggestions.length > 0
            ? suggestions.map((data) => (
              
              

            <div className="col-lg-3" key={data.host_id}>
                <div className=" listen_we_think_card ">

                      
                    {data.gallery.map((image,i) => {
                      if (i === 0) {
                        return <div key={image.picture} className=" homes-img-sec2 new_homes_img_sec2">
                        <img
                          srcSet={image.picture}
                          src={image.picture}
                          alt="Loading..."
                          className="homes-img  m-auto d-flex listing_thing_img_styling"
                        />
                      </div>;
                      }  else {
                        return <div></div>;
                      }
                    })}
                  
               
              
             
              
                  
                  <div className=" trip listen_we_contents">
                    <div className="d-flex">
                      <Link to={`/space/${data.host_id}`}><p className="display-5 list_heading text_success mt-2 listingthings_card_heading">{data.sub_category_name}</p>
                      <h3 className="fw-bold  list_no_basement mb-0 listingthings_card_basement ">{data.dimension} {data.host_name}</h3>
                      </Link>
                      <Link
                          className="ml-auto"
                          to="#"
                          onClick={(e) => this.handleClick(e, data)}
                        >

                           {loading ? (
                            ""
                          ) : wishlistData.host_id != "" ? (
                            wishlistData.host_id == data.host_id ? (
                             <div></div>
                            ) : (
                              // <i className="far fa-heart" />
                              <div></div>                              
                            )
                          ) : (
                            <img className="img-fluid" srcSet="../../../assets/img/heart.png" style={{width: '40px',maxWidth:'unset'}} />

                          )} 
                          {data.wishlist_status ? (
                            // <i className="fa fa-heart pink-clr" />
                            <img className="img-fluid" srcSet="../../../assets/img/greenheart.png" style={{width: '40px',maxWidth:'unset'}} />
                          ) : (
                            // <i className="fa fa-heart" />
                            <img className="img-fluid" srcSet="../../../assets/img/heart.png" style={{width: '40px',maxWidth:'unset'}} />
                          )}
                      </Link>
                      <div className="">
                      <div className="wishlist-icon">
                        
                      </div>
                    </div>

                  
                    </div> 
                    <span className="basement_list_price mb-0 listingthings_card_monthly_price">{data.per_month_formatted}</span><span></span><span className="per_month"> / {data.per_month_symbol}</span>
                    {/* <p className="mb-0 mt-2 listingthings_card_rating rating-star d-flex">
                     
                        {this.starRatingHost(data.overall_ratings)}
                     
                     <span className="ml-2 ">&nbsp;({data.total_ratings})</span>
                    </p> */}
                  </div>
              
              </div>
            </div>



              ))
            : t("no_suggesstion")}
        </div>
      </div>
      </div>
    );
  }
}

export default translate(TripSimilarListing);
