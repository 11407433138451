import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class ListingThings extends Component {
    state = {};
    render() {
        return (
            <div>
                
                <div>

                    <div className="display-inline find_right_space pt-4">
                        <h1 className="Heading_section  how_work_heading text_success pb-2 listing_we_think_font">
                            {t("Listing_We_Think_You_Will_Love")}
                        </h1>
                        <div className="row justify-content-between align-items-center ">
                            <div className="col-md-4 ">
                                <div>
                                    <img className="img-fluid listing_thing_img_styling" srcset="../../../assets/img/right_2.png" />
                                    <div className="pl-2">
                                        <p className="display-5 list_heading text_success mt-2 listingthings_card_heading">{t("MILAN_BASEMENT")}</p>
                                        <h3 className="fw-bold  list_no_basement mb-0 listingthings_card_basement ">{t("5×4_Basement")} 1</h3>
                                        <p className="basement_list_price mb-0 listingthings_card_monthly_price">{t("list_Per_Month_price")}</p>
                                        <p className="mb-0 mt-2 listingthings_card_rating">{t("rating_start")}
                                         <span className="ml-2 listingthings_card_rating_text">{t("4.8 Ratings")}</span> </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img className="img-fluid listing_thing_img_styling" srcset="../../../assets/img/right_1.png" />
                                    <div className="pl-2">
                                        <p className="display-5 list_heading text_success mt-2 listingthings_card_heading">{t("MILAN_BASEMENT")}</p>
                                        <h3 className="fw-bold  list_no_basement mb-0 listingthings_card_basement">{t("5×4_Basement")} 1</h3>
                                        <p className="basement_list_price  mb-0 listingthings_card_monthly_price">{t("list_Per_Month_price")}</p>
                                        <p className="mb-0 mt-2 listingthings_card_rating">{t("rating_start")}
                                        <span className="ml-2 listingthings_card_rating_text">{t("4.8 Ratings")}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img className="img-fluid listing_thing_img_styling" srcset="../../../assets/img/right_3.png" />
                                    <div className="pl-2">
                                        <p className="display-5 list_heading text_success mt-2 listingthings_card_heading">{t("MILAN_BASEMENT")}</p>
                                        <h3 className="fw-bold  list_no_basement mb-0 listingthings_card_basement">{t("5×4_Basement")} 1</h3>
                                        <p className="basement_list_price  mb-0 listingthings_card_monthly_price">{t("list_Per_Month_price")}</p>
                                        <span className="mb-0 mt-2 listingthings_card_rating">{t("rating_start")}
                                        <span className="ml-2 listingthings_card_rating_text">{t("4.8 Ratings")}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button className="bg_success text-white gggg rounded border-0 px-3 py-2 ml-2 mt-3 ">{t("see_more")}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate(ListingThings);
