import React, { Component } from "react";
import InputField from "../Helper/inputfield";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { apiConstants } from "../Constant/constants";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";


import { gapi } from "gapi-script";
import SocialButton from "../Helper/SocialButton";
const $ = window.$;
var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class NewRegister extends Helper {
  state = {
    data: {
      name: "",
      email: "",
      password: "",
      device_type: "web",
      device_token: "123466",
      login_by: "manual",
      timezone: const_time_zone,
    },
    loadingContent: null,
    buttonDisable: false,
    type: 'password',

  };
  componentDidMount() {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get(
          "configData.social_logins.GOOGLE_CLIENT_ID"
        ),
        plugin_name: "chat",
      });
    });
  }
  handleClickShowPassword = () => this.setState(({type}) => ({
    type: type === 'password' ? 'text' : 'password'
  }))

  responseFacebook = (response) => {
    const { path } = this.props.location;
    if (response) {
      if (response.status == "unknown") {
        ToastDemo(this.props.toastManager, "Cancelled", "error");
        return false;
      }
      const emailAddress =
        response.email === undefined || response.email === null
          ? response.id + "@facebook.com"
          : response.email;

      const facebookLoginInput = {
        social_unique_id: response.id,
        login_by: "facebook",
        email: emailAddress,
        name: response.name,
        device_type: "web",
        device_token: "123466",
        timezone: const_time_zone,
      };

      api
        .postMethod("register", facebookLoginInput)
        .then((response) => {
          if (response.data.success === true) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("userLoginStatus", true);
            localStorage.setItem("user_picture", response.data.data.picture);
            localStorage.setItem("username", response.data.data.username);

            // window.location = path ? path.from.pathname : "/home";
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.setState({ loadingContent: null, buttonDisable: false });
            $("#login").modal("hide");
            if (path) {
              this.props.history.push(path.pathname);
            } else {
              this.props.history.push("/home");
            }
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingContent: null, buttonDisable: false });
        });
    }
  };

  responseGoogle = (response) => {
    const path = this.props.location;
    let googleData = response._profile;
    if (googleData == undefined || googleData == null || !googleData) {
      return ToastDemo(
        this.props.toastManager,
        "Google login failed.",
        "error"
      );
    }
    const googleLoginInput = {
      social_unique_id: googleData.id,
      login_by: "google",
      email: googleData.email,
      name: googleData.name,
      picture: googleData.profilePicURL,
      device_type: "web",
      device_token: "123466",
      timezone: const_time_zone,
    };
    api
      .postMethod("register", googleLoginInput)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);
          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#signup").modal("hide");
          if (path) {
            this.props.history.push(path.pathname);
          } else {
            this.props.history.push("/home");
          }
        } else {
          this.setState({ loadingContent: null, buttonDisable: false });
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("register", this.state.data)
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#signup").modal("hide");
          $("#login").modal("show");

          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);
          // window.location = path ? path.from.pathname : "/home";

          // if (path) {
          //   this.props.history.push(path.pathname);
          // } else {
          //   this.props.history.push("/home");
          // }
          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          // $("#signup").modal("hide");
          this.setState({ loadingContent: null, buttonDisable: false });
        }
      })
      .catch(function(error) {});
  };

  render() {
    const { data } = this.state;
    return (
       <div className="site-content">
       <div className="row pt-5">
       <div className="col-md-6">
         <div className="heading_form">
         <div className="login_logo">
          <span class="mb-4"><img class="img-fluid" srcset="../../../assets/new_imgs/footer_logo.png" /></span>
          </div>
         <div className="newlogin_heading">
          <h1 className="section-head log_text Login_text">Welcome back </h1>
          <p className="newlogin_para">Welcome back! Please enter your details.</p>
          </div>
           
          <div className="prov-login prov-login-new "> 
          
                <div className="modal-body" onSubmit={this.handleSubmit}>

                <form className="top1">
                <label htmlFor="" className="email_label">Name</label>
                <div className="form-group input-group new_reg_inputs host_input" >
                  <input
                  
                    className="form-control form_con"
                    type="text"
                    name="name"
                    onChange={this.handleChange}
                    placeholder="First Name"
                    iconClassName="fas fa-lock"
                    value={data.name}
                  />
                  </div>



                  <label htmlFor="" className="email_label">Email</label>
                  <div className="form-group input-group new_reg_inputs host_input">
                  <input
                  
                    className="form-control form_con"
                    type="text"
                    name="email"
                    onChange={this.handleChange}
                    placeholder="Email or Phone"
                    iconClassName="fas fa-envelope"
                    value={data.email}
                  />
                  </div>
                  <label htmlFor="" className="email_label">Password</label>
                  <div className="form-group input-group pass_input new_reg_inputs host_input">

                  <input
                  
                    className="form-control form_con"
                    type={this.state.type}
                    name="password"
                    onChange={this.handleChange}
                    placeholder="Your password"
                    iconClassName="fas fa-lock"
                    value={data.password}
                  />
                     <div class="input-group-append">
              <img src="../../../assets/img/eye.png" className="img-fluid eye_icons" onClick={this.handleClickShowPassword}
               /></div>
                  </div>

                  <div className="align-left">
                    <button
                      className="pink-btn cmn_btn mt-4"
                      disabled={this.state.buttonDisable}
                    >
                      {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : 
                        t("Sign up")}
                    </button>

                    <button
                       className="pink-btn pink_btn bottom1 block cmn-btn cmn_btn mt-4">
                       <img class="img-fluid" srcset="../../../assets/img/Frame.png" />
                      &nbsp; Sign up with Google
            
                  </button>

                  </div>
                  <h5 className="m-0 text-left text_left captalize already_account">
                  {t("Already have an account?", {
                    siteName: configuration.get("configData.site_name"),
                  })}{" "}
                   <Link
                    to="/login"
                    className="bold-cls bold_cls close-signup"
                   
                  >
                    {" "}
                    {t("login")}
                  </Link>
                </h5>
                </form>
                </div>

                {/* <div className="login-separator">{t("or_continue_with")}</div> */}
                <div className="row">
                  {configuration.get(
                    "configData.social_logins.GOOGLE_CLIENT_ID"
                  ) ? (
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 bottom1 margin-lr-auto">
                      <SocialButton
                        provider="google"
                        key={"google"}
                        appId={configuration.get(
                          "configData.social_logins.GOOGLE_CLIENT_ID"
                        )}
                        onLoginSuccess={this.responseGoogle}
                        onLoginFailure={this.responseGoogle}
                        className="social-btn"
                        id="google-connect"
                      >
                        <i className="fab fa-google" /> {t("login_with_google")}
                      </SocialButton>
                      {/* <GoogleLogin
                        clientId={configuration.get(
                          "configData.social_logins.GOOGLE_CLIENT_ID"
                        )}
                        render={(renderProps) => (
                          <button
                            className="social-btn"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <i className="fab fa-google" />{" "}
                            {t("login_with_google")}
                          </button>
                        )}
                        buttonText="Login"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {configuration.get(
                    "configData.social_logins.FB_CLIENT_ID"
                  ) ? (
                    <div className="col-sm-6 col-md-8 col-lg-8 col-xl-8 bottom1 margin-lr-auto">
                      <FacebookLogin
                        appId={configuration.get(
                          "configData.social_logins.FB_CLIENT_ID"
                        )}
                        fields="name,email,picture"
                        scope="public_profile"
                        callback={this.responseFacebook}
                        render={(renderProps) => (
                          <button
                            className="social-btn"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <i className="fab fa-facebook fb social-icons" />{" "}
                            {t("login_with_facebook")}
                          </button>
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <p className="line" /> */}
             
             
             </div>
          </div>
          </div>

          <div className="col-lg-6 col-md-6 logincontainer login_centered ">
          <img src="../../../assets/img/login44.png" className="img-fluid" />
          <div className="logincentered login_centered">
            <h1 id="heading"  style={{color:'white',textAlign:'left'}}>Discover your dream space in prime location</h1>
            </div>

        
        </div>
      
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(NewRegister));
