import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class HomeLastContent extends Component {
    state = {};
    render() {
        return (
            <div>
                <div className="find-out-more">
                    <div className="row">
                        <div className="col-sm-5 col-md-5 col-lg-4 col-xl-4">
                            <div className="find-img-sec">
                                <img
                                    srcSet="../../../assets/img/find.jpg,
                                                ../../../assets/img/find.jpg 1.5x,
                                                ../../../assets/img/find.jpg 2x"
                                    src="../../../assets/img/find.jpg"
                                    alt="Other Find"
                                    className="homes-img"
                                />
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-8 col-xl-8 find-text-sec">
                            <div className="">
                                <h1 className="find-head captalize">
                                    {t("find_out_how_much_you_could_earn_hosting")}  
                                </h1>
                                <h4 className="captalize find-subhead">
                                    {t("turn_your_extra_space_into_extra_income")}
                                </h4>
                                <Link to="/host" className="green-btn btn-small">
                                    {t("find_out_more")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate(HomeLastContent);
